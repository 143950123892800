import WixLogo from '../images/StoreLogos/WixStores.png.webp'
import MercadoLibre from '../images/StoreLogos/MercadoLibre.png.webp'
import TiendaNube from '../images/StoreLogos/TiendaNube.png.webp'
import Ebay from '../images/StoreLogos/ebayLogo.png'
import Amazon from '../images/StoreLogos/amazonLogo.webp'

export const StoreLogo = (type) => {
    switch (type) {
        case 'WIX':
            return WixLogo
        case 'MERCADOLIBRE':
            return MercadoLibre
        case 'TIENDANUBE':
            return TiendaNube
        case 'EBAY':
            return Ebay
        case 'AMAZON':
            return Amazon
        default:
            return WixLogo
    }
}