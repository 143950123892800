import React from 'react';
import Mercadolibre from "./Mercadolibre";

function SelectIntegration({ type }) {
    switch (type){
        case 'MERCADOLIBRE':
            return (
                <Mercadolibre />
            )
        case 'AMAZON':
            return (
                <p>Amazon</p>
            )
        default:
            return (
                <p>Na</p>
            )
    }
}

export default SelectIntegration;