import React, {Fragment, useState, useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from "react-images-uploading";

import {CreateProduct, getCustomFields} from "../../api/ProductAPI";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import { useAlert } from "react-alert";
import { DataContext } from "../../context/dataContext";
import {Card, Divider, Input} from "antd";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
}));

const numberValues = ["PesoKG","Largo", "Ancho", "Altura", "ValorMercancia", "VentaAmazon", "VentaEcommerce", "VentaMeli"];

const ProductCreate = (props) => {

  const [apiCustomFields, setApiCustomFields] = useState([]);

  const classes = useStyles();
  const [images, setImages] = useState([]);
  const { getTokenSilently, user } = useAuth0();
  const History = useHistory();
  const alert = useAlert();
  const { data, setData } = useContext(DataContext);

  const initialState = {
    SKUCliente: "",
    Nombre: "",
    PesoKG: "",
    Largo: "",
    Ancho: "",
    Altura: "",
    Descripcion: "",
    Fotografia: "",
    Fotografia64: "",
    ValorMercancia: "",
    VentaAmazon: "",
    VentaEcommerce: "",
    VentaMeli: "",
    CustomFields: {}
  };
  const [product, setProduct] = useState(initialState);

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setProduct({
      ...product,
      Fotografia64: imageList.length > 0 ? imageList[0].data_url : "",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      product.SKUCliente !== '' &&
      product.Nombre !== '' &&
      product.PesoKG !== '' &&
      product.Largo !== '' &&
      product.Ancho !== '' &&
      product.Altura !== '' &&
      product.Descripcion !== '' &&
      product.Fotografia64 &&
      product.ValorMercancia !== 0 &&
      product.ValorMercancia !== null &&
      product.ValorMercancia !== ''
    ) {      
      numberValues.forEach(item => product[item] = Number(product[item]))
      const t = await getTokenSilently();
      await CreateProduct(t, product);

      alert.success("Tu producto fue registrado con éxito");
      setData({ ...data, updateData: true });
      History.push("/products");
    } else {
      alert.info(
        "Debes seleccionar todos los campos"
      );
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    if (numberValues.includes(name)){
      if (/^\d*\.?\d*$/.test(value)) {
        setProduct({ ...product, [name]: value });
      }
    } else {
      if(name === 'Descripcion'){
        if(value.length < 80){
          setProduct({ ...product, [name]: value });
        } else return
      }
      setProduct({ ...product, [name]: value })
    }
  };

  const onChangeCustomInput = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, CustomFields: { ...product.CustomFields, [name]: value } });
    console.log('Custom File Data Input =====>', name, value)
  }

  const getAllCustomFields = async () => {
    const token = await getTokenSilently();
    const response = await getCustomFields(token, user.sub);
    if (response.status === 200) {
      setApiCustomFields(response.data.CustomFields);
    }
  };

  useEffect(() => {
    console.log('Custom FIelds en los productos ----------->', product)
  }, [product]);

  useEffect(() => {
    getAllCustomFields()
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Box>
        <Card>
          <CardContent>
            <Button type="submit" variant="contained" color="primary" onClick={() => History.push('/products/create/masive')}>Agregar masivamente desde excel</Button>
            <p>Descarga nuestra plantilla y sube tus productos de manera más rápida</p>
            <form autoComplete="off" onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="SKUCliente"
                    label="SKU"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.SKUCliente}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Nombre"
                    label="Nombre"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.Nombre}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Descripcion"
                    label="Descripción corta"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.Descripcion}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Largo"
                    label="Largo"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.Largo}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Ancho"
                    label="Ancho"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.Ancho}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Altura"
                    label="Altura"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.Altura}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="PesoKG"
                    label="Peso"
                    variant="outlined"
                    type="number"
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">KG</InputAdornment>
                      ),
                    }}
                    required={true}
                    value={product.PesoKG}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="VentaAmazon"
                    label="Venta Amazon (opcional)"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.VentaAmazon}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="VentaMeli"
                    label="Venta MercadoLibre (opcional)"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.VentaMeli}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="VentaEcommerce"
                    label="Venta Ecommerce (opcional)"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    value={product.VentaEcommerce}
                    onChange={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    name="ValorMercancia"
                    label="Costo"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.ValorMercancia}
                    onChange={onChangeInput}
                  />
                  <label>
                    El monto máximo a cubrir por el seguro es de $ 2,000
                  </label>
                </Grid>
                <Divider style={{ marginTop:'40px' }}>Campos personalizados ( opcionales )</Divider>
                {
                  apiCustomFields.length > 0 && apiCustomFields.map(item => (
                        <Grid item xs={12}>
                          <TextField
                              type={item.type === "number" ? "number" : "text"}
                              name={item?.fieldName}
                              label={item?.fieldName}
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                              onChange={onChangeCustomInput}
                          />
                        </Grid>
                    )) || apiCustomFields.length === 0 && (<p>Puedes crear campos personalizados en la sección de configuración</p>)
                }
                <Grid item xs={12}>
                  <InputLabel className={classes.textField}>
                    Fotografía del producto
                  </InputLabel>
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <div className="upload__image-wrapper">
                        <Box mt={1}>
                          {imageList.length > 0 ? (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={onImageRemoveAll}
                            >
                              Quitar imagen
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Selecciona una imagen
                            </Button>
                          )}
                        </Box>
                        <Box mt={2}>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image.data_url}
                                alt=""
                                width="200"
                                height="auto"
                              />
                            </div>
                          ))}
                        </Box>
                      </div>
                    )}
                  </ImageUploading>
                </Grid>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => History.push("/products")}
                  >
                    Cancelar
                  </Button>
                  &nbsp;
                  <Button type="submit" variant="contained" color="primary">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ProductCreate;
