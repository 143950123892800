import React, { useEffect } from "react";
import { CSSProperties } from "react";
import { LottieOptions, useLottie } from "lottie-react";

const LottieContainer = ({
  lottieSelected,
  lottieSpeed,
  lottieState,
  lottieOptions,
  lottieStyle,
  lottieTrigger,
}) => {
  const options = {
    animationData: lottieSelected,
    loop: lottieOptions.loop,
    autoplay: lottieOptions.autoplay,
    initialSegment: lottieOptions.initialSegment
      ? lottieOptions.initialSegment
      : undefined,
    style: { width: "100%", height: "100%", ...lottieStyle },
  };
  const Lottie = useLottie(options);

  useEffect(() => {
    lottieSpeed && Lottie.setSpeed(lottieSpeed);

    if (lottieState) {
      if (lottieState[0] === "play") {
        Lottie.play();
        return;
      } else if (lottieState[0] === "pause") {
        Lottie.pause();
        return;
      } else if (lottieState[0] === "playSegment") {
        if (lottieState[1]) {
          Lottie.playSegments(lottieState[1], true);
          return;
        }
      }
    }
  }, [lottieTrigger]); //eslint-disable-line

  return Lottie.View;
};

export default LottieContainer;
