import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const SetRoutes = (routes) => {
    const location = useLocation();

    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');


    useEffect(() => {

        const route = Object.values(routes);

        if(location.pathname === '/'){
            route.push({
                route: "/",
                title: "Inicio",
                description: "Visualiza las estadisticas",
            })
        }

        if(location.pathname.includes('/warehouseoutput/scan')){
            setTitle("Salidas programadas")
            setDescription("Procesa las salidas programadas por los clientes")
        }
        if(location.pathname.includes('/products/create')){
            setDescription("Agrega tus productos, verifica bien los datos antes de crearlo")
        }
        if(location.pathname.includes('/outboundshipments/detail')){
            setTitle("Ordenes de envío")
            setDescription("Revisa los detalles de tu orden")
        }

        for(let _route of route) {
            if(_route.route === location.pathname) {
                setTitle(_route.title)
                setDescription(_route.description)
            }
            if(_route.type === 'subitem') {
                const __route__ = _route.children.find(r => r.route === location.pathname)
                if(__route__) {
                    setTitle(__route__.title)
                    setDescription(__route__.description)
                }
            }
        }

    }, [location, routes])

    return [title, description]
}

export default SetRoutes