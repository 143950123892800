import { useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { GetAllUsers } from '../../api/userBalanceData'
import { manualDispatch } from '../../components/tables/schemaColumn'
import { manualDispatchDownloads } from '../../components/tables/schemaDownload'
import {Card} from "antd";

const ITEMS_PER_PAGES = 150

const ManualDispatch = () => {
  const { getTokenSilently } = useAuth0();
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('') // string
  const [dataColumns, setDataColumns] = useState(null) // any 
  const [activateReload, setActivateReload] = useState(false)
  const [downloadColumns] = useState({
    range: {
      from: 0,
      to: topLimit
    },
    searched: searchText,
    isAdmin: false,
    columns: [
      {
        id: 'date',
        find: false
      },
      {
        id: 'name',
        find: false
      },
      {
        id: 'email',
        find: false
      },
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState({ ...downloadColumns })

  const handleReload = async() => {
    setActivateReload(!activateReload)
  }

  const handleFecthing = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const data = await GetAllUsers(userToken, filterByColumns)
    const filteredData = data.filter(item => item.ApiClientsV2.length > 0)

    if (saveState){
      setDataColumns(filteredData)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit]) 

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecthing(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns, activateReload]) 

  return (
      <Card>
        <Table
          // title={"Despachos manuales"}
          tableType="manualDispatch"
          data={dataColumns ?? []}
          isLoading={refetching}
          onChangePage={(page) => setCurrentPage(page)}
          onChangeRowsPerPage={(row) => setRowPerPages(row)}
          schemaDownload={manualDispatchDownloads}
          columns={manualDispatch(handleReload) ?? []}
          config={{ disableFilters: true }}
          stateValues={{
            currentPage,
            rowsPerPages,
            topLimit,
            searchText,
            filterByColumns,
            downloadColumns,
            newProspectModal: false,
          }}
          filters={{
            filters: ['date'],
            filterBySearchText(text) { // string //
              setSearchText(text)
            },
            filterByColumns(filter) {
              setFilterByColumns(filter)
            },
            filterDownloads: async (filter) => {
              return await handleFecthing(filter, false)
            },
          }}
        />
      </Card>
  )
}

export default ManualDispatch