import React, { Fragment } from "react";
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
  } from "@material-ui/core";

const WarehouseShippingInfo = (props) => {
  return (
    <Fragment>
      <Dialog open={props.showDialog} onClose={props.displayInfoDialog}>
        <DialogTitle>{"Dirección del almacén"}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                Para hacernos llegar tu mercancía, por favor enviar tus
                productos a esta dirección
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Calle:</b> San Telmo
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Número ext:</b> 1117
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Colonia:</b> Fresnos del lago
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Municipio:</b> San Nicolas de los Garza
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Estado:</b> Nuevo León
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Referencia:</b> A un costado de los locales
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>País:</b> México
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              <Typography variant="body1" gutterBottom>
                <b>Código Postal:</b> 66478
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.displayInfoDialog} color="primary">
            De acuerdo
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default WarehouseShippingInfo;
