import React from 'react'
import {
  Input as AntInput,
  InputNumber,
  Radio,
  Button,
  Tooltip,
  Form,
  Checkbox,
  Select
} from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined, CloseCircleFilled } from '@ant-design/icons'
// import s from '../Common/toolsbar.module.css';


const InputContainer = (inputProps) => {
  const t = (text) => text
  const { Option } = Select
  const { ...restProps } = { 
    showCount: true 
  } 

  const getComponent = () => {
    switch (inputProps.type) {
      case 'text':
      case 'email':
      case 'number':
      case 'password':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            hasFeedback={
              inputProps.type === 'number' ? false : inputProps.noFeedback ? false : true
            }
            rules={
              inputProps.type === 'number'
                ? [
                    {
                      required: inputProps.required,
                      type: 'number',
                      message: inputProps.errorMessage
                        ? inputProps.errorMessage
                        : 'campo incompleto'
                    },
                    {
                      pattern: inputProps.regexpPattern,
                      message: inputProps.errorMessage
                        ? inputProps.errorMessage
                        : 'campo incompleto',
                      validator: async (_, inputName) => {
                        if (
                          inputName &&
                          inputName.toString().length <
                            (inputProps.counters?.min ? inputProps.counters?.min : 1)
                        ) {
                          return Promise.reject(
                            new Error('Los campos deben tener al menos 2 carácteres')
                          )
                        }
                      }
                    }
                  ]
                : [
                    {
                      required: inputProps.required,
                      type:
                        inputProps.type === 'text' || inputProps.type === 'password'
                          ? 'string'
                          : inputProps.type,
                      message: inputProps.errorMessage
                        ? inputProps.errorMessage
                        : 'campo incompleto'
                    },
                    {
                      pattern: inputProps.regexpPattern,
                      message: inputProps.errorMessage
                        ? inputProps.errorMessage
                        : 'campo incompleto',
                      validator: async (_, inputName) => {
                        if (
                          inputName &&
                          inputName.length <
                            (inputProps.counters?.min ? inputProps.counters?.min : 2)
                        ) {
                          return Promise.reject(
                            new Error('Los campos deben tener al menos 2 carácteres')
                          )
                        }
                      }
                    }
                  ]
            }
          >
            {inputProps.type === 'password' ? (
              <AntInput.Password
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                suffix={inputProps.suffix}
                prefix={inputProps.prefix}
                disabled={inputProps.disabled}
                placeholder={inputProps.placeholder}
                onClick={inputProps.onClick}
                onChange={inputProps.onChange}
                style={{
                  width: '100%',
                  margin: '10px 0px',
                  ...inputProps.style
                }}
              />
            ) : inputProps.type === 'number' ? (
              <InputNumber
                style={{
                  width: '100%',
                  margin: '10px 0px',
                  ...inputProps.style
                }}
                defaultValue={inputProps.defaultValue}
                addonAfter={inputProps.suffix}
                prefix={inputProps.prefix}
                controls={inputProps.suffix ? false : true}
                disabled={inputProps.disabled}
                placeholder={inputProps.placeholder}
                onClick={inputProps.onClick}
                onChange={inputProps.onChange}
                parser={inputProps.parser}
                formatter={inputProps.formatter}
                maxLength={inputProps?.counters?.max || 25}
              />
            ) : (
              <AntInput
                suffix={inputProps.suffix}
                prefix={inputProps.prefix}
                disabled={inputProps.disabled}
                placeholder={inputProps.placeholder}
                onClick={inputProps.onClick}
                onChange={inputProps.onChange}
                maxLength={inputProps?.counters?.max || 50}
                defaultValue={inputProps.defaultValue}
                style={{
                  width: '100%',
                  margin: '10px 0px',
                  ...inputProps.style
                }}
                {...restProps} 
              />
            )}
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item name={inputProps.valueContainerName} valuePropName="checked" noStyle>
            <Checkbox
              style={{ margin: '20px 5px', fontWeight: 'bolder', ...inputProps.style }}
              onClick={inputProps.onClick}
              onChange={inputProps.onChange}
              checked={inputProps.checked}
            >
              {inputProps.placeholder}
            </Checkbox>
          </Form.Item>
        )
      case 'multipleRadio':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            rules={[
              {
                required: inputProps.required,
                message: inputProps.errorMessage
                  ? inputProps.errorMessage
                  : 'campo incompleto'
              }
            ]}
            style={{
              height: '100%',
              width: '100%',
              display: 'grid',
              placeItems: 'center'
            }}
          >
            <Radio.Group
              name="RadioGroup"
              onChange={inputProps.onChange}
              defaultValue={inputProps.defaultValue}
            >
              {typeof inputProps.optionsList !== 'string'
                ? inputProps.optionsList.map((value, index) => {
                    return (
                      <Radio
                        key={index}
                        onClick={inputProps.onClick}
                        value={index}
                        style={{
                          fontWeight: 'bolder'
                        }}
                      >
                        {value}
                      </Radio>
                    )
                  })
                : null}
            </Radio.Group>
          </Form.Item>
        )
      case 'button':
      case 'submitButton':
        return (
          <Button
            disabled={inputProps.disabled}
            onClick={inputProps.onClick}
            style={{ borderRadius: '10px', ...inputProps.style }}
            type={inputProps.buttonType ? inputProps.buttonType : 'primary'}
            block={true}
            danger={inputProps.danger}
            ghost={inputProps.ghost}
            htmlType={inputProps.type === 'submitButton' ? 'submit' : undefined}
            loading={inputProps.isButtonLoading}
          >
            {inputProps.title}
          </Button>
        )
      case 'iconTitleHoverButton':
        return (
          <Tooltip title={inputProps.title}>
            <Button
              type="default"
              shape={inputProps.shape}
              icon={inputProps.iconOrReactElement}
              size="large"
              // STYLE BUTTON MONERY AND PARTNERS
              className={inputProps.className}
              // STYLE BUTTON CIRCLE
              style={inputProps.style}
              onClick={inputProps.onClick}
              loading={inputProps.isButtonLoading}
            />
          </Tooltip>
        )
      case 'select':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            rules={[
              {
                required: inputProps.required,
                message: inputProps.errorMessage
                  ? inputProps.errorMessage
                  : 'campo incompleto'
              }
            ]}
          >
            <Select
              mode={inputProps.multiple ? 'multiple' : undefined}
              size={inputProps.size}
              showSearch={inputProps.canSearch}
              onSearch={inputProps.onSearch}
              loading={inputProps.isButtonLoading}
              style={{
                width: '100%',
                margin: '10px 0px',
                fontWeight: 'bolder',
                ...inputProps.style
              }}
              placeholder={inputProps.placeholder}
              onChange={inputProps.onChange}
              disabled={inputProps.disabled}
              allowClear
              onClear={inputProps.onClear}
              defaultValue={inputProps.defaultValue}
              clearIcon={
                <CloseCircleFilled
                  style={{
                    fontSize: '18px',
                    color: 'red',
                    position: 'absolute',
                    right: '3px',
                    bottom: '-4px'
                  }}
                />
              }
            >
              {inputProps.optionsList !== undefined
                ? inputProps.optionsList.map((value, index) => {
                    if (value.label && value.value) {
                      return (
                        <Option key={index} value={value.value}>
                          {value?.icon && (
                            <span role="img">
                              <img
                                src={value.icon}
                                alt={'d'}
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginRight: '5px',
                                  marginBottom: '2.5px'
                                }}
                              />
                              {value.secondIcon && (
                                <img
                                  src={value.secondIcon}
                                  alt={'d'}
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    marginRight: '5px',
                                    marginBottom: '2.5px'
                                  }}
                                />
                              )}
                            </span>
                          )}
                          {value.label}
                        </Option>
                      )
                    }
                    return (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
        )
      case 'prePostTab':
        return (
          <Form.Item
            name={inputProps.valueContainerName}
            rules={[
              {
                required: inputProps.required,
                message: inputProps.errorMessage
                  ? inputProps.errorMessage
                  : 'campo incompleto'
              }
            ]}
          >
            <AntInput
              addonBefore={inputProps.addonBefore}
              addonAfter={inputProps.addonAfter}
              suffix={inputProps.suffix}
              prefix={inputProps.prefix}
              disabled={inputProps.disabled}
              placeholder={inputProps.placeholder}
              onClick={inputProps.onClick}
              onChange={inputProps.onChange}
              style={{
                width: '100%',
                margin: '10px 0px',
                ...inputProps.style
              }}
            />
          </Form.Item>
        )
      default:
        return null
    }
  }

  return <>{getComponent()}</>
}

export default InputContainer
