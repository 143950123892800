import { useEffect, useState } from "react"
import { useAuth0 } from "../../hooks/react-auth0-spa"
import { axiosBase as axios } from '../../api/AxiosConfig'
import Loader from "../../components/generals/Loader"
import {  Avatar, List, Tooltip  } from 'antd'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Button } from "@material-ui/core"

const placeholderImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF9ftKCp4wA5IyCFrY-vdMvZ9D1Lkx1am2QseOlaSqnWLpIZ5ao2t5VBuADaKHEtccT_Q&usqp=CAU'

const styles = {
    container: {
        minHeight: '300px',
        maxHeight: '100px',
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
    },
    description: { 
        fontWeight: 'bold', 
        color: 'black'
    },
    validations: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0 20px'
    },
    validationsItem: { 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    validationsItemTitle: {
        margin: '5px 0'
    },
    icons: {
        fontSize: '24px'
    },
    button: { 
        display: 'flex', 
        justifyContent: 'flex-end', 
        marginTop: '20px' 
    }
}

const StrictSelectedProductsByIntegration = ({ userId, handleShipment, handleStep, integrationOrder }) => {
    const { getTokenSilently } = useAuth0()
    const [currentProducts, setCurrentProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [validation, setValidation] = useState(true)

    const getProducts = async ()=> {
        try {
            setLoading(true)
            const integrationProducts = integrationOrder?.product_items
            const productsIntegrationIds = integrationProducts?.map(integrationProduct => integrationProduct?.id)
            
            const userToken = await getTokenSilently()
            const { data } = await axios.post(
                `/allProductsOnSkuArray`, 
                productsIntegrationIds, 
                { headers: { Authorization: `Bearer ${userToken}` }}
            )

            const current = []
            const fetchedProducts = data?.res
            const productsFetchIds = data?.res?.map(item => item?.SKUCliente)

            fetchedProducts.forEach((item) => {
                if (productsIntegrationIds.includes(item?.SKUCliente)){
                    const currentProductIntegration = integrationProducts.find(ip => ip.id === item?.SKUCliente)
                    current.push({
                        drevfill: item,
                        integration: currentProductIntegration,
                        quantityMatch: item?.CantidadEnInventario >= currentProductIntegration?.quantity
                    })
                }
            })
            
            integrationProducts.forEach((item) => {
                if (!productsFetchIds.includes(item?.id)){
                    current.push({
                        drevfill: null,
                        integration: item,
                        quantityMatch: false
                    })
                } 
            })

            const hasInvalidItem = current.some(item => item.drevfill === null || item.quantityMatch === false)
            setValidation(hasInvalidItem)
            setCurrentProducts(current)
            setLoading(false)
        } catch (e){
            console.log(e)
            setLoading(false)
        }
    }

    const handleSubmit = ()=> {
        const formatedProducts = currentProducts.map(item => ({
            Producto: item?.drevfill?.SKUFulfillment,
            CantidadEnviada: item?.integration?.quantity,
            Nombre: item?.drevfill?.Nombre,
        }))

        handleShipment({ InfoEnvioProductos: formatedProducts })
        handleStep()
    }

    useEffect(()=> {
        getProducts()
    }, [integrationOrder])

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div style={styles.container} >
                <List
                    dataSource={currentProducts}
                    renderItem={(item) => {
                        const id = item?.integration?.id
                        const avatar = item?.drevfill?.Fotografia ?? placeholderImg
                        const description = item?.drevfill?.Nombre ?? item?.integration?.name
                        const quantity = item?.integration?.quantity

                        return (
                            <List.Item key={id}>
                                <List.Item.Meta
                                    avatar={<Avatar src={avatar} />}
                                    title={<span>{id}</span>}
                                    description={<span><span style={styles.description}>{quantity}</span> {description}</span>}
                                />
                                <div style={styles.validations}>
                                    <div style={styles.validationsItem}>
                                        <p style={styles.validationsItemTitle}>Coincidencia</p>
                                        <div>
                                            {item?.drevfill 
                                                ? <CheckCircleFilled style={{ color: 'green', ...styles.icons }} />
                                                : <Tooltip title="El SKU del producto no coincide con el de la integración">
                                                    <CloseCircleFilled style={{ color: 'red', ...styles.icons }} />
                                                </Tooltip> 
                                            }
                                        </div>
                                    </div>
                                    <div style={styles.validationsItem}>
                                        <p style={styles.validationsItemTitle}>Cantidad</p>
                                        <div>
                                            {item?.quantityMatch 
                                                ? <CheckCircleFilled style={{ color: 'green', ...styles.icons }} />
                                                : <Tooltip title="La cantidad disponible en inventario es insuficiente para cubrir la cantidad solicitada en la orden">
                                                    <CloseCircleFilled style={{ color: 'red', ...styles.icons }} />
                                                </Tooltip> 
                                            }
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    }}
                />

            </div>
            <div style={styles.button}>
                <Button
                    onClick={handleSubmit}
                    disabled={validation}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Continuar
                </Button>
            </div>
        </>
    )
}

export default StrictSelectedProductsByIntegration