// Libraries
import axios from 'axios'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Row, Col, Typography, Tooltip, notification } from 'antd'
import {
  ArrowLeftOutlined,
  ArrowDownOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from '@ant-design/icons'
import LottieContainer from '../containers/LottieContainer'
import { allParcels } from '../countrySettings/allParcels'

// Contexts
import { QuoteDataContext } from '../../../context/QuoteDataContext'
import { CountriesContext } from '../../../context/CountriesContext'
import { DataContext } from '../../../context/dataContext'
import { Auth0Context } from '../../../hooks/react-auth0-spa'

// Containers
import CardContainer from '../containers/CardContainer'
import CardContraEntregaContainer from '../containers/CardContraEntregaContainer'
import InputContainer from '../containers/InputContainer'

import LoadingAnimation from '../components/LoadingAnimation'

// Assets
import AnalyticsLottie from '../lotties/analytics.json'
// import { getParcelsQuote } from '../../../services/quote_s'
import { tips } from '../components/Tips'
// import { usePost } from '../../../hooks/usePost'

//tour
// import { Step } from 'react-joyride'
// import useTour from '../../../utils/Tour/useTour'
import { userRoles } from '../utils/userRoles'
// import useDevice from '../../../utils/hooks/useDevice'
import { quoteErrorMessage } from '../utils/quoteMessages'
import { CountriesSettings } from '../countrySettings/countriesSettings'
// import { useStoreContext } from '../../../context/StoreContext'
// import { useStoreContext } from '../../../context/StoreContext'

// const STEPS: Step[] = [
//   {
//     target: '.tipodeEnvio',
//     content: <p>👆Escoje la paquetería y conoce el precio, tiempo y tipo de envío. </p>
//   },
//   {
//     target: '.filtro',
//     content: <p> 🤏 Filtra tus paqueterias favoritas.</p>
//   }
// ]

const SelectFavParcel = ({ token, handleStep, isIntegration, saveData }) => {
  const { getTokenSilently } = useContext(Auth0Context);
  const {
    setQuoteStepTransition,
    previousQuoteStep,
    quoteData,
    setQuoteData,
    shipmentTypeSelected
  } = useContext(QuoteDataContext)
  // const { store } = useStoreContext()
  const [store, setStore] = useState([])
  // const { currentParcelsData, currentCountrySelectedData, formatPrice } =
  //   useContext(CountriesContext)
  const { currentSelectedCountry, currentCountrySelectedData, formatPrice } =
    useContext(CountriesContext)
  const { data: { userInfo: userData } } = useContext(DataContext)
  // const { fetchData } = usePost(getParcelsQuote)
  // const { isMobile, isTablet } = useDevice()
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  const [dataFetchingStatus, setDataFetchingStatus] = useState('awaiting')
  const [apiCallResultString, setApiCallResultString] = useState({
    message: 'No se encontraron paqueterías disponibles',
    status: 'no parcels found'
  })

  // Obtener Random Tip de La lista de Tips cada que se renderea el componente
  const RandomTip = useMemo(() => {
    return tips[Math.floor(Math.random() * tips.length)]
  }, [])

  const t = (service)=> {
    switch (service){
      case "Quote.Service.Title_time": 
        return "Tiempo"
      case "Quote.Service.Title_price": 
        return "Precio"
      case "Quote.Service.Title_type": 
        return "Servicio"
      case "Quote.Service.Title_container": 
        return "Tipo"
      case "Quote.Service.terrestre": 
        return "Terrestre"
      case "Quote.Service.import": 
        return "Importacion"
      case "Quote.Service.ground": 
        return "Terrestre"
      case "Quote.Service.especial": 
        return "Especial"
      case "Quote.Service.g_especial": 
        return "Terrestre especial"
      case "Quote.Service.n_especial": 
        return "Día siguiente especial"
      case "Quote.Service.ground_without_pickup": 
        return "Sin recolección"
      case "Quote.Service.ground_small": 
        return "Terrestre"
      case "Quote.Service.premier_cod": 
        return "Premier ContraEntrega"
      case "Quote.Service.metropolitano": 
        return "Local"
      case "Quote.Service.next_day": 
        return "Express"
      case "Quote.Service.next_day_without_pickup": 
        return "Sin recolección"
      case "Quote.Service.same_day": 
        return "Mismo día"
      case "Quote.Service.2_days": 
        return "2 días"
      case "Quote.Service.express": 
        return "Express"
      case "Quote.Service.express_10-30": 
        return "Express 10:30"
      case "Quote.Service.saver": 
        return "Terrestre"
      case "Quote.Service.express_int": 
        return "Express"
      case "Quote.Service.Standard": 
        return "Terrestre"
      case "Quote.Service.Express": 
        return "Express"
      case "Quote.Service.int_ground": 
        return "Terrestre"
      case "Quote.Service.int_express": 
        return "Express"
      case "Quote.Service.economic": 
        return "Económico"
      case "Quote.Service.worldwide_express": 
        return "Express"
      case "Quote.Service.worldwide_express_plus": 
        return "Express Plus"
      case "Quote.Service.standard": 
        return "Terrestre"
      case "Quote.Service.priority": 
        return "Prioridad"
      case "Quote.Service.industrial": 
        return "Industrial"
      case "Quote.Service.premier": 
        return "Premier"
      case "Quote.Service.local_next_day": 
        return "Express"
      case "Quote.Service.local_same_day": 
        return "Mismo día"
      case "Quote.Service.noventa9Minutos local_next_day": 
        return "99Minutos Día siguiente"
      case "Quote.Service.noventa9Minutos local_same_day": 
        return "99Minutos Mismo día"
      case "Quote.Service.DHL Express Internacional Terrestre": 
        return "DHL Internacional Terrestre"
      case "Quote.Service.DHL Express Express": 
        return "DHL Express"
      case "Quote.Service.DHL Express terrestre": 
        return "DHL Terrestre"
      case "Quote.Service.Fedex": 
        return "Fedex Terrestre"
      case "Quote.Service.sendex ground": 
        return "Sendex Terrestre"
      case "Quote.Service.PAQUETE EXPRESS TERRESTRE": 
        return "Paquetexpress Terrestre"
      case "Quote.Service.Short.ground": 
        return "Terr"
      case "Quote.Service.Short.next_day": 
        return "Día sig"
      case "Quote.Service.rapi_charge": 
        return "Carga Rapida"
      case "Quote.Service.sobre": 
        return "Documento Unitario"

      case "Quote.Service.Carrier.fedex": 
        return "Fedex"
      case "Quote.Service.Carrier.estafeta": 
        return "Estafeta"
      case "Quote.Service.Carrier.dhl": 
        return "DHL"
      case "Quote.Service.Carrier.carssa": 
        return "CARSSA"
      case "Quote.Service.Carrier.noventa9Minutos": 
        return "99 Minutos"
      case "Quote.Service.Carrier.ups": 
        return "UPS"
      case "Quote.Service.Carrier.sendex": 
        return "Sendex"
      case "Quote.Service.Carrier.redpack": 
        return "Redpack"
      case "Quote.Service.Carrier.ampm": 
        return "AMPM"
      case "Quote.Service.Carrier.quiken": 
        return "Quiken"
      case "Quote.Service.Carrier.paquetexpress": 
        return "Paquetexpress"
      case "Quote.Service.Carrier.ivoy": 
        return "iVoy"
      case "Quote.Service.Carrier.scm": 
        return "SCM"
      case "Quote.Service.Carrier.borzo": 
        return "Borzo"
      case "Quote.Service.Carrier.jtexpress": 
        return "JTExpress"
      case "Quote.Service.Carrier.mensajerosUrbanos": 
        return "Mensajeros Urbanos"
      case "Quote.Service.Carrier.servientrega": 
        return "Servientrega"
      case "Quote.Service.Carrier.serviEntrega": 
        return "ServiEntrega"
      case "Quote.Service.Carrier.deprisa": 
        return "Deprisa"
      case "Quote.Service.Carrier.interrapidisimo": 
        return "Interrapidísimo"
      case "Quote.Service.Carrier.coordinadora": 
        return "Coordinadora"
      case "Quote.Service.Carrier.redservi": 
        return "RedServi"
      case "Quote.Service.Carrier.delivery_in_store": 
        return "Entrega en sucursal"
      case "Quote.Service.Carrier.free_shipping": 
        return "Envío Gratuito"
      case "Quote.Service.delivery": 
        return "Mensajería"
      case "Quote.Service.plus": 
        return "Plus"
      case "Quote.Service.estandar": 
        return "Estandar"
    }
  }

  // Variables y Funciones para el Filtro de Opciones y Paqueterías
  const [filterOption, setFilterOption] =
    useState('textFiltration')
  const [textToFilter, setTextToFilter] = useState(undefined)
  const filteredParcels = useMemo(() => {
    if (filterOption === 'lowestPrice') {
      return quoteData.FetchedParcelsArray.sort((a, b) => {
        if (a.price && b.price) {
          const priceABigger = a.price > b.price
          const priceBBigger = a.price < b.price
          if (priceABigger) return 1
          if (priceBBigger) return -1
          else return 0
        } else return 1
      })
    } else if (filterOption === 'highestPrice') {
      return quoteData.FetchedParcelsArray.sort((a, b) => {
        if (a.price && b.price) {
          const priceABigger = a.price > b.price
          const priceBBigger = a.price < b.price
          if (priceABigger) return -1
          if (priceBBigger) return 1
          else return 0
        } else return 1
      })
    } else if (filterOption === 'lowestTime') {
      return quoteData.FetchedParcelsArray.sort((a, b) => {
        const timeABigger = parseInt(a.days.split(' ')[0]) > parseInt(b.days.split(' ')[0])
        const timeBBigger = parseInt(a.days.split(' ')[0]) < parseInt(b.days.split(' ')[0])
        if (timeABigger) return 1
        if (timeBBigger) return -1
        else return 0
      })
    } else if (filterOption === 'highestTime') {
      return quoteData.FetchedParcelsArray.sort((a, b) => {
        const timeABigger = parseInt(a.days.split(' ')[0]) > parseInt(b.days.split(' ')[0])
        const timeBBigger = parseInt(a.days.split(' ')[0]) < parseInt(b.days.split(' ')[0])
        if (timeABigger) return -1
        if (timeBBigger) return 1
        else return 0
      })
    } else if (filterOption === 'abcAscending') {
      return quoteData.FetchedParcelsArray.sort((a, b) => {
        const carrierACloser = a.carrier < b.carrier
        const carrierBCloser = a.carrier > b.carrier
        if (carrierACloser) return -1
        if (carrierBCloser) return 1
        else return 0
      })
    } else if (filterOption === 'abcDescending') {
      return quoteData.FetchedParcelsArray.sort((a, b) => {
        const carrierACloser = a.carrier < b.carrier
        const carrierBCloser = a.carrier > b.carrier
        if (carrierACloser) return 1
        if (carrierBCloser) return -1
        else return 0
      })
    } else {
      if (quoteData.FetchedParcelsArray.length > 0 && filterOption === 'textFiltration') {
        return textToFilter
          ? quoteData.FetchedParcelsArray.sort((a, b) => {
            if (a.price && b.price) {
              // Comparisons
              const priceCompared =
                a.price.toString().includes(textToFilter) &&
                !b.price.toString().includes(textToFilter)
              const parcelCompared =
                a.carrier.includes(textToFilter) && !b.carrier.includes(textToFilter)
              const serviceCompared =
                t(`Quote.Service.${a.service}`).includes(textToFilter) &&
                !t(`Quote.Service.${b.service}`).includes(textToFilter)
              const timeCompared = a.days.includes(textToFilter) && !b.days.includes(textToFilter)

              if (priceCompared || parcelCompared || serviceCompared || timeCompared) return -1
              else return 1
            } else return 1
          })
          : quoteData.FetchedParcelsArray
      } else return []
    }
  }, [textToFilter, quoteData.FetchedParcelsArray, filterOption, t])

  // (Manera anterior en como se manda las cotizaciones al backend)Pedir cotización al Backend con la información llenada hasta el momento
  // useEffect(() => {
  //   const QuoteApiPost = async () => {
  //     const CarriersActivated = currentParcelsData?.Mapeable.filter(
  //       (data) => data[1].activated
  //     ).map(([key]) => key)

  //     // Si el Usuario es un Dev, entonces agregamos la paquetería de Prueba
  //     if (userData.roles.includes(UserRoles.dev)) {
  //       CarriersActivated.push('test')
  //     }

  //     console.log(shipmentTypeSelected, "shipments selected")
  //     const { data, error } = await fetchData({
  //       type: shipmentTypeSelected,
  //       origin: {
  //         country: quoteData.AddressData.OriginData.country,
  //         state: quoteData.AddressData.OriginData.state,
  //         postal_code: `${quoteData.AddressData.OriginData.postal_code}`,
  //         city: quoteData.AddressData.OriginData.city,
  //         district: quoteData.AddressData.OriginData.district
  //       },
  //       destination: {
  //         country: quoteData.AddressData.DestinyData.country,
  //         state: quoteData.AddressData.DestinyData.state,
  //         postal_code: `${quoteData.AddressData.DestinyData.postal_code}`,
  //         city: quoteData.AddressData.DestinyData.city,
  //         district: quoteData.AddressData.DestinyData.district
  //       },
  //       packages:
  //         quoteData.PackageArray.length > 1
  //           ? quoteData.PackageArray.map((pack) => {
  //               return {
  //                 weight: pack.Weight,
  //                 height: pack.Height,
  //                 width: pack.Width,
  //                 length: pack.Length,
  //                 declaredValue: pack.Price,
  //                 volumetric:
  //                   pack.Height && pack.Length && pack.Width
  //                     ? (pack.Height * pack.Width * pack.Length) / 5000
  //                     : 10,
  //                 type: pack.Container,
  //                 content: pack.Name
  //               }
  //             })
  //           : [
  //               {
  //                 weight: quoteData.PackageData.Weight,
  //                 height: quoteData.PackageData.Height,
  //                 width: quoteData.PackageData.Width,
  //                 length: quoteData.PackageData.Length,
  //                 declaredValue: quoteData.PackageData.Price,
  //                 volumetric:
  //                   quoteData.PackageData.Height &&
  //                   quoteData.PackageData.Length &&
  //                   quoteData.PackageData.Width
  //                     ? (quoteData.PackageData.Height *
  //                         quoteData.PackageData.Width *
  //                         quoteData.PackageData.Length) /
  //                       5000
  //                     : 10,
  //                 type: quoteData.PackageData.Container,
  //                 content: quoteData.PackageData.Name
  //               }
  //             ],
  //       carriers: CarriersActivated,
  //       insurance: quoteData.PackageData.Insurance ? quoteData.PackageData.Insurance : 0,
  //       metadata:{
  //         contraEntrega: quoteData.PackageData.ContraEntrega ? quoteData.PackageData.ContraEntrega : 0
  //       },
  //       integration: isIntegration && store ? {storeId: store._id} : undefined
  //     })

  //     if (!error) {
  //       const CarriersFetched: Array<ParcelFetchedData> = data as ParcelFetchedData[]

  //       setQuoteData({
  //         ...quoteData,
  //         FetchedParcelsArray: CarriersFetched
  //       })
  //     } else {
  //       setApiCallResultString({
  //         //@ts-ignore
  //         message: error?.msg
  //           ? //@ts-ignore
  //             quoteErrorMessage(error?.msg)
  //           : 'Error cotizando, por favor regrese e intente de nuevo. Si el error persiste, contáctenos por el chat',
  //         status: 'error quoting'
  //       })
  //       console.error(data)
  //     }
  //     setDataFetchingStatus('finished')
  //   }

  //   if (previousQuoteStep === '1) Quote based on Postal Codes') QuoteApiPost()
  //   else setDataFetchingStatus('finished')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [previousQuoteStep])

  // Forma de mandar cotizaciones, por servicio 
  const getPreferences = async ()=> {
    const { data } = await axios.get(
      `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/preferences`, 
      { headers: { Authorization: `Bearer ${token}` }}
    )
    
    const userPreferences = data.res
    if (userData) {
      const OptimizedParcelsData = new Map()
      const OptimizedMapeableParcelsData = []
      const AllowedParcels = CountriesSettings.get(currentSelectedCountry)?.AllowedParcels

      allParcels.forEach((value, key) => {
        if (AllowedParcels?.includes(key)) {
          const parcelData = userPreferences.carriers.filter((parcel) => parcel.name === key)[0]

          OptimizedParcelsData.set(key, {
            ...value,
            activated: parcelData ? parcelData.active : false
          })
          OptimizedMapeableParcelsData.push([
            key,
            { ...value, activated: parcelData ? parcelData.active : false }
          ])
        }
      })
      
      return {
        Dynamic: OptimizedParcelsData,
        Mapeable: OptimizedMapeableParcelsData
      }
    }
  }

  useEffect(() => {
    const cleanup = () => {
      setQuoteData(prevData => ({
        ...prevData,
        FetchedParcelsArray: []
      }));
    };

    const QuoteApiPost = async () => {
      const currentParcelsData = await getPreferences()
      const CarriersActivated = currentParcelsData?.Mapeable.filter(
        (data) => data[1].activated
      ).map(([key]) => key)

      // Si el Usuario es un Dev, entonces agregamos la paquetería de Prueba
      if (userData.roles.includes(userRoles.dev)) {
        CarriersActivated.push('test')
      }

      const promises = CarriersActivated.map(async (carrier) => {        
        try {
            const { data } = await axios.post(
              `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/shipments/rate`, 
              {
                type: shipmentTypeSelected,
                origin: {
                  country: quoteData.AddressData.OriginData.country,
                  state: quoteData.AddressData.OriginData.state,
                  postal_code: `${quoteData.AddressData.OriginData.postal_code}`,
                  city: quoteData.AddressData.OriginData.city,
                  district: quoteData.AddressData.OriginData.district
                },
                destination: {
                  country: quoteData.AddressData.DestinyData.country,
                  state: quoteData.AddressData.DestinyData.state,
                  postal_code: `${quoteData.AddressData.DestinyData.postal_code}`,
                  city: quoteData.AddressData.DestinyData.city,
                  district: quoteData.AddressData.DestinyData.district
                },
                packages:
                  quoteData.PackageArray.length > 1
                    ? quoteData.PackageArray.map((pack) => {
                      return {
                        weight: pack.Weight,
                        height: pack.Height,
                        width: pack.Width,
                        length: pack.Length,
                        declaredValue: pack.Price,
                        volumetric:
                          pack.Height && pack.Length && pack.Width
                            ? (pack.Height * pack.Width * pack.Length) / 5000
                            : 10,
                        type: pack.Container,
                        content: pack.Name
                      }
                    })
                    : [
                      {
                        weight: quoteData.PackageData.Weight,
                        height: quoteData.PackageData.Height,
                        width: quoteData.PackageData.Width,
                        length: quoteData.PackageData.Length,
                        declaredValue: quoteData.PackageData.Price,
                        volumetric:
                          quoteData.PackageData.Height &&
                            quoteData.PackageData.Length &&
                            quoteData.PackageData.Width
                            ? (quoteData.PackageData.Height *
                              quoteData.PackageData.Width *
                              quoteData.PackageData.Length) /
                            5000
                            : 10,
                        type: quoteData.PackageData.Container,
                        content: quoteData.PackageData.Name
                      }
                    ],
                carriers: [carrier], // Solo envía el transportista actual
                insurance: quoteData.PackageData.Insurance ? quoteData.PackageData.Insurance : 0,
                metadata: {
                  contraEntrega: quoteData.PackageData.ContraEntrega
                    ? quoteData.PackageData.ContraEntrega
                    : 0
                },
                // integration: isIntegration && store ? { storeId: store._id } : undefined,
                // notification: !isIntegration && userData ? { profileId: userData.user } : undefined
                integration: isIntegration && saveData ? saveData?.integrations[0]?._id : undefined,
                notification: !isIntegration && saveData ? { profileId: saveData.user } : undefined
              },
              { headers: { Authorization: `Bearer ${token}` }
            })
            
            const CarriersFetched = data
            // console.log("data:", data)
              setQuoteData((prevData) => ({
                ...prevData,
                FetchedParcelsArray: [...prevData.FetchedParcelsArray, ...CarriersFetched] // Agrega los datos de este transportista a la matriz existente
              }))
        } catch (err) {
          setApiCallResultString({
            //@ts-ignore
            message: err?.msg
              ? //@ts-ignore
              quoteErrorMessage(err?.msg)
              : 'Error cotizando, por favor regrese e intente de nuevo. Si el error persiste, contáctenos por el chat',
            status: 'error quoting'
          })
          setDataFetchingStatus('finished')
        }
      });

      await Promise.allSettled(promises);

      setDataFetchingStatus('finished');
    }

    if (previousQuoteStep === '1) Quote based on Postal Codes') QuoteApiPost()
    else setDataFetchingStatus('finished');

    return cleanup;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousQuoteStep]);

  // const filterMetadata = () => {
  //   filteredParcels.map((parcels) => {
  //     if (parcels.metadata) {
  //       console.log(parcels.metadata.extended_area?.extended_area)
  //     } else {
  //       console.log('Not value')
  //     }
  //   })
  // }
  // filterMetadata()
  // //tour
  // const tour = useTour(STEPS, 'LS_KEY30')
  return quoteData?.FetchedParcelsArray?.length !== 0 || dataFetchingStatus === 'finished' ? (
    <>
      {!userData?.security?.vip ? <h4 className="drfont text-white fw-normal" style={{ marginLeft: "16px;" }}>{'Para activar DHL favor de contactarse al chat'}</h4> : ''}
      <Row
        gutter={[0, 0]}
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Col
          sm={24}
          lg={13}
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <Row gutter={[0, 20]} style={{ width: '100%', height: '100%' }}>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className="tipodeEnvio">
                    {/* {tour} */}
                    {shipmentTypeSelected === "ContraEntrega" ? (
                      <CardContraEntregaContainer
                        direction="horizontal"
                        textList={[
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              setFilterOption((prevOption) =>
                                prevOption === 'abcAscending' ? 'abcDescending' : 'abcAscending'
                              )
                            }
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background:
                                  filterOption === 'abcAscending' || filterOption === 'abcDescending'
                                    ? '#D1D1FF'
                                    : 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'Paquetería'}
                            </p>

                            {filterOption === 'abcDescending' ? (
                              <SortDescendingOutlined style={{ marginLeft: '2px' }} />
                            ) : filterOption === 'abcAscending' ? (
                              <SortAscendingOutlined
                                style={{
                                  marginLeft: '2px',
                                  transform: 'rotate(0deg)',
                                  transition: 'transform 350ms'
                                }}
                              />
                            ) : (
                              <SortAscendingOutlined
                                style={{
                                  marginLeft: '5px',
                                  transform: 'rotate(90deg)',
                                  transition: 'transform 350ms'
                                }}
                              />
                            )}
                          </span>,
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              setFilterOption((prevOption) =>
                                prevOption === 'lowestPrice' ? 'highestPrice' : 'lowestPrice'
                              )
                            }
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background:
                                  filterOption === 'lowestPrice' || filterOption === 'highestPrice'
                                    ? '#D1D1FF'
                                    : 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'Precio'}
                            </p>
                            <ArrowDownOutlined
                              style={{
                                marginLeft: '5px',
                                transition: 'transform 350ms',
                                transform:
                                  filterOption === 'lowestPrice'
                                    ? 'rotate(0deg)'
                                    : filterOption === 'highestPrice'
                                      ? 'rotate(180deg)'
                                      : 'rotate(90deg)'
                              }}
                            />
                          </span>,
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              setFilterOption((prevOption) =>
                                prevOption === 'lowestTime' ? 'highestTime' : 'lowestTime'
                              )
                            }
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background:
                                  filterOption === 'highestTime' || filterOption === 'lowestTime'
                                    ? '#D1D1FF'
                                    : 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'Tiempo'}
                            </p>
                            <ArrowDownOutlined
                              style={{
                                marginLeft: '5px',
                                transition: 'transform 350ms',
                                transform:
                                  filterOption === 'lowestTime'
                                    ? 'rotate(0deg)'
                                    : filterOption === 'highestTime'
                                      ? 'rotate(180deg)'
                                      : 'rotate(90deg)'
                              }}
                            />
                          </span>,
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background: 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'% CE'}
                            </p>
                          </span>,
                          'Tipo'
                        ]}
                        responsiveText
                        textListStyle={{
                          fontWeight: 'bolder',
                          textDecoration: 'underline 2px solid #6d71f6'
                        }}
                        cardStyle={{
                          borderTop: '2px solid #ef921b',
                          borderLeft: '2px solid #ef921b',
                          borderRight: '2px solid #ef921b',
                          borderBottomLeftRadius: '0px',
                          borderBottomRightRadius: '0px'
                        }}
                        containerStyle={{
                          width: '98%',
                          marginRight: '2%'
                        }}
                      />
                    ) : (
                      <CardContainer
                        direction="horizontal"
                        textList={[
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              setFilterOption((prevOption) =>
                                prevOption === 'abcAscending' ? 'abcDescending' : 'abcAscending'
                              )
                            }
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background:
                                  filterOption === 'abcAscending' || filterOption === 'abcDescending'
                                    ? '#D1D1FF'
                                    : 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'Paquetería'}
                            </p>

                            {filterOption === 'abcDescending' ? (
                              <SortDescendingOutlined style={{ marginLeft: '5px' }} />
                            ) : filterOption === 'abcAscending' ? (
                              <SortAscendingOutlined
                                style={{
                                  marginLeft: '5px',
                                  transform: 'rotate(0deg)',
                                  transition: 'transform 350ms'
                                }}
                              />
                            ) : (
                              <SortAscendingOutlined
                                style={{
                                  marginLeft: '5px',
                                  transform: 'rotate(90deg)',
                                  transition: 'transform 350ms'
                                }}
                              />
                            )}
                          </span>,
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              setFilterOption((prevOption) =>
                                prevOption === 'lowestPrice' ? 'highestPrice' : 'lowestPrice'
                              )
                            }
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background:
                                  filterOption === 'lowestPrice' || filterOption === 'highestPrice'
                                    ? '#D1D1FF'
                                    : 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'Precio'}
                            </p>
                            <ArrowDownOutlined
                              style={{
                                marginLeft: '5px',
                                transition: 'transform 350ms',
                                transform:
                                  filterOption === 'lowestPrice'
                                    ? 'rotate(0deg)'
                                    : filterOption === 'highestPrice'
                                      ? 'rotate(180deg)'
                                      : 'rotate(90deg)'
                              }}
                            />
                          </span>,
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            onClick={() =>
                              setFilterOption((prevOption) =>
                                prevOption === 'lowestTime' ? 'highestTime' : 'lowestTime'
                              )
                            }
                          >
                            <p
                              style={{
                                padding: '2px 5px',
                                margin: 0,
                                background:
                                  filterOption === 'highestTime' || filterOption === 'lowestTime'
                                    ? '#D1D1FF'
                                    : 'transparent',
                                borderRadius: '5px'
                              }}
                            >
                              {'Tiempo'}
                            </p>
                            <ArrowDownOutlined
                              style={{
                                marginLeft: '5px',
                                transition: 'transform 350ms',
                                transform:
                                  filterOption === 'lowestTime'
                                    ? 'rotate(0deg)'
                                    : filterOption === 'highestTime'
                                      ? 'rotate(180deg)'
                                      : 'rotate(90deg)'
                              }}
                            />
                          </span>,
                          'Tipo'
                        ]}
                        responsiveText
                        textListStyle={{
                          fontWeight: 'bolder',
                          textDecoration: 'underline 2px solid #6d71f6'
                        }}
                        cardStyle={{
                          borderTop: '2px solid #ef921b',
                          borderLeft: '2px solid #ef921b',
                          borderRight: '2px solid #ef921b',
                          borderBottomLeftRadius: '0px',
                          borderBottomRightRadius: '0px'
                        }}
                        containerStyle={{
                          width: '98%',
                          marginRight: '2%'
                        }}
                      />
                    )}

                  </div>
                </Col>
                <Col span={24}>
                  <div className="filtro">
                    <InputContainer
                      type="text"
                      label="Filtro"
                      placeholder="Escribe y filtra por paqueteria, precio, tipo o tiempo"
                      valueContainerName="filter"
                      onChange={(e) => {
                        setFilterOption('textFiltration')
                        setTextToFilter(e.target.value.toLowerCase())
                      }}
                      noFeedback
                      style={{
                        width: '98%',
                        margin: '0',
                        marginRight: '2%',
                        borderBottomLeftRadius: '20px',
                        borderBottomRightRadius: '20px',
                        borderTop: '0px solid #ef921b',
                        borderBottom: '2px solid #ef921b',
                        borderLeft: '2px solid #ef921b',
                        borderRight: '2px solid #ef921b',
                        padding: '10px ',
                        paddingLeft: '20px',
                        outline: 'none',
                        backgroundColor: 'white'
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              style={{
                height: '80%',
                width: '100%'
              }}
            >
              <Row
                gutter={[0, 50]}
                style={{
                  width: '100%',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  rowGap: '10px',
                  overflowY: 'auto',
                }}
              >
                {quoteData?.FetchedParcelsArray?.length === 0 ? (
                  <Col span={24}>
                    <CardContainer
                      titleStyle={{
                        textAlign: 'center',
                        display: 'grid',
                        placeItems: 'center'
                      }}
                      title={apiCallResultString.message}
                    />
                  </Col>
                ) : (
                  filteredParcels.map((parcel, index) => {
                    const traduction = {
                      "Mismo día": 'Mismo día',
                      "Siguiente día": 'Siguiente día',
                      "hora": 'hora',
                      "horas": 'horas',
                      "un día": '1 día',
                      "día": 'día',
                      "días": 'días'
                    };
                    
                    const parcelI18N  = { days: parcel.days };
                    
                    for (const [key, value] of Object.entries(traduction)) {
                      if (parcel?.days?.includes(key)) {
                        parcelI18N.days = parcel.days.replace(key, value);
                        break;
                      }
                    }
                    return (
                      //tooltip solo funciona como clase padre del return
                      <Tooltip
                        // onVisibleChange={handleVisibleChange}
                        key={index}
                        title={
                          parcel.metadata ? (
                            parcel.metadata.extended_area?.origin ? (
                              <span>El origen tiene zona extendida</span>
                            ) : parcel.metadata.extended_area?.destination ? (
                              <span>El destino es zona extendida</span>
                            ) : parcel.metadata.extended_area?.destination &&
                              parcel.metadata.extended_area.origin ? (
                              <span>Ambos codigos postales son zona extendida</span>
                            ) : (
                              <span>No contiene zona extendida</span>
                            )
                          ) : (
                            <span></span>
                          )
                        }
                      >
                        <Col
                          span={24}
                          key={`${parcel.carrier} ${parcel.price} ${parcel.service} ${parcel.ObjectId} ${parcel.service_id}`}
                        >
                          {shipmentTypeSelected === "ContraEntrega" ? (
                            <CardContraEntregaContainer
                              textList={[
                                parcel.price > 0 ? (
                                  allParcels.get(parcel.carrier)?.image
                                ) : (
                                  <p>{'No disponible'}</p>
                                ),
                                <span
                                  style={{
                                    padding: '5px',
                                    borderRadius: '10px',
                                    backgroundColor:
                                      filterOption === 'lowestPrice' ||
                                        filterOption === 'highestPrice' ||
                                        (textToFilter && parcel.price.toString().includes(textToFilter))
                                        ? '#f7c88d'
                                        : 'transparent'
                                  }}
                                >
                                  {parcel.metadata?.account_id
                                    ? `$${formatPrice(parcel.price)} ${currentCountrySelectedData?.Currency
                                    } (Personal) `
                                    : `$${formatPrice(parcel.price)} ${currentCountrySelectedData?.Currency
                                    }`}
                                </span>,
                                <span
                                  style={{
                                    padding: '5px',
                                    borderRadius: '10px',
                                    backgroundColor:
                                      filterOption === 'lowestTime' ||
                                        filterOption === 'highestTime' ||
                                        (textToFilter && parcel.days.includes(textToFilter))
                                        ? '#f7c88d'
                                        : 'transparent'
                                  }}
                                >
                                  {parcel.days}
                                </span>,
                                <span
                                  style={{
                                    padding: '4px 8px',
                                    display: 'inline-block',
                                    width: 'auto',
                                    backgroundColor: '#6d71f6',
                                    borderRadius: '12px'
                                  }}
                                >
                                  {parcel.metadata?.comision === 0 || parcel.carrier === 'test' ? 'sin comision' : parcel.metadata?.comision}
                                </span>,
                                <span
                                  style={{
                                    padding: '5px',
                                    borderRadius: '10px',
                                    backgroundColor:
                                      textToFilter &&
                                        t(`Quote.Service.${parcel.service}`).includes(textToFilter)
                                        ? '#f7c88d'
                                        : 'transparent'
                                  }}
                                >
                                  {t(`Quote.Service.${parcel.service}`)}{' '}
                                  {parcel.metadata?.ocurre ? '(OCURRE)' : ''}
                                  {parcel.service_id.includes('estafeta_mx_A-P07') ? '(No cancelable)' : ''}
                                  {parcel.service_id.includes('estafeta_mx_D-C03') ? '(No cancelable)' : ''}
                                </span>
                              ]}
                              disabled={parcel.price ? false : true}
                              iconOrImg={allParcels.get(parcel.carrier)?.image}
                              iconOrImgStyle={{
                                height: '40px',
                                width: '100%',
                                borderRadius: '7px',
                                backgroundColor:
                                  filterOption === 'abcAscending' ||
                                    filterOption === 'abcDescending' ||
                                    (textToFilter && parcel.carrier.includes(textToFilter))
                                    ? '#f7c88d'
                                    : 'transparent'
                              }}
                              responsiveText
                              direction="horizontal"
                              contrastColor="transparent"
                              hoverEffect
                              onClick={() => {
                                setQuoteStepTransition('3) Fill all guide info form')
                                setQuoteData({
                                  ...quoteData,
                                  PackageData: {
                                    ...quoteData.PackageData,
                                    ShippingID: parcel.ShippingId,
                                    Name: parcel.carrier,
                                    Image: allParcels.get(parcel.carrier)?.imgSrc,
                                    Price: parcel.price,
                                    Time: parcel.days,
                                    Type: parcel.service,
                                    ObjectId: parcel.ObjectId,
                                    Currency: currentCountrySelectedData?.Currency,
                                    Service_Id: parcel.service_id,
                                    metadata: {
                                      account_id: parcel?.metadata?.account_id || undefined,
                                      account_price: parcel?.metadata?.account_price || undefined
                                    }
                                  }
                                })
                                handleStep()
                              }}
                              containerStyle={{
                                width: '98%',
                                marginRight: '2%',
                                marginBottom: '8px',
                              }}
                            />
                          ) : (
                            <CardContainer
                              textList={[
                                parcel.price > 0 ? (
                                  <span
                                    style={{
                                      padding: '5px',
                                      borderRadius: '10px',
                                      backgroundColor:
                                        filterOption === 'lowestPrice' ||
                                          filterOption === 'highestPrice' ||
                                          (textToFilter && parcel.price.toString().includes(textToFilter))
                                          ? '#f7c88d'
                                          : 'transparent'
                                    }}
                                  >
                                    {parcel.metadata?.account_id
                                      ? `$${formatPrice(parcel.price)} ${currentCountrySelectedData?.Currency
                                      } (Personal) `
                                      : `$${formatPrice(parcel.price)} ${currentCountrySelectedData?.Currency
                                      }`}
                                  </span>
                                ) : (
                                  <p>{'No disponible'}</p>
                                ),
                                <span
                                  style={{
                                    padding: '5px',
                                    borderRadius: '10px',
                                    backgroundColor:
                                      filterOption === 'lowestTime' ||
                                        filterOption === 'highestTime' ||
                                        (textToFilter && parcel.days.includes(textToFilter))
                                        ? '#f7c88d'
                                        : 'transparent'
                                  }}
                                >
                                  {parcelI18N.days}
                                  {/* {parcel.days} */}
                                </span>,
                                <span
                                  style={{
                                    padding: '5px',
                                    borderRadius: '10px',
                                    backgroundColor:
                                      textToFilter &&
                                        t(`Quote.Service.${parcel?.service}`)?.includes(textToFilter)
                                        ? '#f7c88d'
                                        : 'transparent'
                                  }}
                                >
                                  {t(`Quote.Service.${parcel?.service}`)}{' '}
                                  {parcel.metadata?.ocurre ? '(OCURRE)' : ''}
                                  {parcel?.service_id?.includes('estafeta_mx_A-P07') ? '(No cancelable)' : ''}
                                  {parcel?.service_id?.includes('estafeta_mx_D-C03') ? '(No cancelable)' : ''}
                                </span>
                              ]}
                              disabled={parcel?.price ? false : true}
                              iconOrImg={allParcels?.get(parcel?.carrier)?.image}
                              iconOrImgStyle={{
                                height: '40px',
                                maxWidth: '100%',
                                borderRadius: '7px',
                                backgroundColor:
                                  filterOption === 'abcAscending' ||
                                    filterOption === 'abcDescending' ||
                                    (textToFilter && parcel?.carrier?.includes(textToFilter))
                                    ? '#f7c88d'
                                    : 'transparent'
                              }}
                              responsiveText
                              direction="horizontal"
                              contrastColor="transparent"
                              hoverEffect
                              onClick={() => {
                                if (
                                  quoteData?.AddressData?.DestinyData?.country !==
                                  quoteData?.AddressData?.OriginData?.country &&
                                  !userData?.security.international_senders
                                ) {
                                  notification.error({
                                    message: 'Para activar envios internacionales, por favor comunicarse con el chat'
                                  })
                                  return
                                }

                                setQuoteStepTransition('3) Fill all guide info form')
                                setQuoteData({
                                  ...quoteData,
                                  PackageData: {
                                    ...quoteData.PackageData,
                                    ShippingID: parcel.ShippingId,
                                    Name: parcel.carrier,
                                    Image: allParcels.get(parcel.carrier)?.imgSrc,
                                    Price: parcel.price,
                                    Time: parcel.days,
                                    Type: parcel.service,
                                    ObjectId: parcel.ObjectId,
                                    Currency: currentCountrySelectedData?.Currency,
                                    Service_Id: parcel.service_id,
                                    metadata: {
                                      account_id: parcel?.metadata?.account_id || undefined,
                                      account_price: parcel?.metadata?.account_price || undefined
                                    }
                                  }
                                })
                                handleStep()
                              }}
                              containerStyle={{
                                width: '98%',
                                marginRight: '2%',
                                marginBottom: '8px',
                              }}
                            />
                          )}
                        </Col>
                      </Tooltip>
                    )
                  })
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          sm={24}
          lg={11}
          style={{
            overflow: 'hidden',
            display: isMobile || isTablet ? 'none' : 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'left',
            padding: '60px',
          }}
        >
          <LottieContainer lottieSelected={AnalyticsLottie} lottieOptions={{ loop: true }} />
          <Typography.Title level={3} style={{ color: '#2d1b6e' }}>
            Sabias que...
          </Typography.Title>
          {RandomTip}
        </Col>

        <InputContainer
          type="iconTitleHoverButton"
          shape={'circle'}
          title="Regresar"
          iconOrReactElement={<ArrowLeftOutlined />}
          style={{
            position: 'absolute',
            top: '50px',
            right: '20px',
            zIndex: '5'
          }}
          onClick={() => setQuoteStepTransition('1) Quote based on Postal Codes')}
        />
      </Row>

      <Tooltip title='Regresar' placement='left'>
        <span
          className='floating-button-back'
          onClick={() => setQuoteStepTransition('1) Quote based on Postal Codes')}>
          <ArrowLeftOutlined />
        </span>
      </Tooltip>

    </>
  ) : (
    <LoadingAnimation
      animationType="big"
      onClick={() => console.log(dataFetchingStatus)}
      fullPage={false}
      loadingText="Cotizando todas las opciones..."
    />
  )
}

export default SelectFavParcel
