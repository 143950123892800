import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Button } from 'antd';
import { useAlert } from 'react-alert';
import { axiosBase as axios } from '../../api/AxiosConfig'
import { useAuth0 } from '../../hooks/react-auth0-spa'

const styles = {
    buttons: {
        cursor:'pointer', 
        fontWeight: 'bold', 
        color: 'white', 
        borderRadius: '12px'
    }
}

const ColumnActionsUpdateProducts = ({ data: product, handleReload }) => {
  const alert = useAlert();
  const { getTokenSilently } = useAuth0();
  const [disabled, setDisabled] = useState(false)

  const handleRequest = async (state)=> {
    setDisabled(true)
    try {
      const userToken = await getTokenSilently()
      const { data } = await axios.post(
          `/products/update/${product.SKUFulfillment}`, 
          { approved: state }, 
          { headers: { Authorization: `Bearer ${userToken}` }}
      )

      if (data.code !== 200){
          setDisabled(false)
          alert.error(data?.error?.message)
          return 
      }

      alert.success(data?.res)
      setDisabled(false)
      handleReload()
    } catch (err){
        setDisabled(false)
        alert.error("Ha ocurrido un error")
    }
  }
    
  useEffect(() => {
  }, [product])

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <Button 
                type='primary' 
                disabled={disabled}
                style={{ ...styles.buttons, background: disabled ? 'rgba(76, 175, 80, 0.5)' : 'rgba(76, 175, 80, 1)' }} 
                onClick={() => handleRequest(true)}
            >
                Aceptar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
                type='primary'
                disabled={disabled} 
                style={{ ...styles.buttons, background: disabled ? 'rgba(244, 67, 54, 0.5)' : 'rgba(244, 67, 54, 1)' }} 
                onClick={() => handleRequest(false)}
            >
                Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ColumnActionsUpdateProducts