import React, { useEffect, useState, useCallback } from 'react';
import {Col, Row, Select, Table, Input, Button} from 'antd';
import { getStockByMercadolibre } from '../../../api/ProductAPI';
import { useAuth0 } from '../../../hooks/react-auth0-spa';
import {MercadoLibreStates} from "../../../utils/States";
import {ProfileOutlined} from "@ant-design/icons";
import * as XLSX from "xlsx";

const { Search } = Input;

const columns = [
    {
        title: "Foto",
        dataIndex: "",
        render: (rowData) => (
            <img width={80} src={rowData?.pictures[0]?.secure_url} alt={rowData?.title ?? ""} />
        ),
    },
    {
        title: "Título",
        dataIndex: "title",
        width: 200,
    },
    {
        title: "Precio",
        dataIndex: "",
        width: 100,
        render: (rowData) => (`${ rowData?.price ?? "0" } ${rowData?.currency_id ?? "MXN"}`)
    },
    {
        title: "Cant. Disponible",
        dataIndex: "available_quantity",
        width: 150,
        align: "center",
    },
    {
        title: "Cant. Vendida",
        dataIndex: "sold_quantity",
        width: 150,
        align: "center",
    },
    {
        title: "Estado",
        dataIndex: "",
        align: "center",
        render: (rowData) => (
            <div style={{ display:'flex', justifyContent:'center' }}>{ MercadoLibreStates(rowData?.status) }</div>
        )
    },
];

function Mercadolibre() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [pageSize, setPageSize] = useState(10);
    const { getTokenSilently, user } = useAuth0();
    const limit = 20;

    const fetchMoreData = useCallback(async () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);
        const token = await getTokenSilently();
        const offset = Math.floor(data.length / limit) * limit;

        const request = {
            userId: user.sub,
            status: selectedStatus,
            pagination: { offset, limit },
        };

        try {
            const response = await getStockByMercadolibre(token, request);
            if (response.status === 200) {
                const newData = [...data, ...response.data];
                setData(newData);
                setFilteredData(newData);
                setHasMore(response.data.length === limit);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [data, hasMore, isLoading, selectedStatus]);

    useEffect(() => {
        fetchMoreData();
    }, [fetchMoreData]);

    const exportToExcel = (fileType) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        const fileExtension = fileType === "xlsx" ? ".xlsx" : ".csv";
        const fileName = `Data${fileExtension}`;

        XLSX.writeFile(workbook, fileName);
    };

    const handlePageChange = (page, size) => {
        const maxPageLoaded = Math.ceil(filteredData.length / size);

        if (page > maxPageLoaded && hasMore) {
            fetchMoreData();
        }

        setCurrentPage(page);
    };

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
        setData([]);
        setFilteredData([]);
        setCurrentPage(1);
        setHasMore(true);
    };

    const handleSearch = (value) => {
        const filtered = data.filter((item) =>
            item.title.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    return (
        <div>
            <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={12} md={3}>
                    <Select
                        placeholder="Filtrar por estado"
                        style={{ width: '100%' }}
                        onChange={handleStatusChange}
                        options={[
                            { label: "Pausado", value: "paused" },
                            { label: "Activo", value: "active" },
                            { label: "Todo", value: "all" },
                        ]}
                    />
                </Col>
                <Col xs={24} sm={12} md={5}>
                    <Search
                        placeholder="Buscar por título"
                        allowClear
                        enterButton="Buscar"
                        onSearch={handleSearch}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} style={{ display: 'flex', justifyContent:'flex-start' }}>
                    <Button
                        loading={false}
                        onClick={() => exportToExcel('csv')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                    >
                        {"Descargar CSV"}
                    </Button>
                    <Button
                        loading={false}
                        onClick={() => exportToExcel('xlsx')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                    >
                        {"Descargar XLSX"}
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={filteredData}
                loading={isLoading}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    onChange: handlePageChange,
                    hideOnSinglePage: true,
                }}
                rowKey={(record) => record.id}
            />
        </div>
    );
}

export default Mercadolibre;
