import React from 'react'
import { Grid } from '@material-ui/core'
import { Popconfirm, Tooltip } from 'antd'
import Summarize from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import DeleteIcon from "@material-ui/icons/Delete";
import { jsPDF } from 'jspdf';
import bwipjs from 'bwip-js';
import { CancelWarehouseShipment, GetShippmentByCode } from "../../api/WarehouseShippingAPI";
import { useAuth0 } from '../../hooks/react-auth0-spa';
import { useAlert } from 'react-alert';

const ColumnActions = ({ data, status, setIsRefetching }) => {

    const { getTokenSilently } = useAuth0();
    const alert = useAlert()

    const generateBarCode = async (wrShipment) => {
        try {
          const shortId = wrShipment.EnvioId.slice(-12)
          const canvas = document.createElement('canvas');
          bwipjs.toCanvas(canvas, {
            bcid: 'code128',
            text: shortId,
            scale: 5,
            height: 7,
            includetext: true,
            textxalign: 'center',
            textsize: 5,
          })
          
          const pdf = new jsPDF();
          const imgData = canvas.toDataURL('image/png');
          const pageWidth = pdf.internal.pageSize.width // 210.0015555555555
          // const pageHeight = pdf.internal.pageSize.height // 297.0000833333333
          const imageX = 10
          const imageY = 15
          const imageWidth = pageWidth / 2
          const imageHeight = 40
    
          pdf.addImage(imgData, 'PNG', imageX, imageY, imageWidth, imageHeight);
          pdf.save(`${shortId}.pdf`);
        } catch (err) {
          console.error('Error generating barcode:', err);
          alert.error("Error al generar etiqueta, revisa bien el producto.")
        }
      }
    
    const generateSummary = async (wrShipment) => {
      try {
          const t = await getTokenSilently();
          const data = await GetShippmentByCode(t, wrShipment.EnvioId.slice(-12));
          
          if (data.code !== 200){
            alert.error("Ha ocurrido un error")
            return 
          }
  
          const wr = data.res
          const shortId = wr.EnvioId.slice(-12)
          const totalProducts = wr.InfoEnvioProductos.length
          const totalUnits = wr.InfoEnvioProductos.reduce((acum, item)=> acum += item.CantidadEnviada, 0)
  
          const pdf = new jsPDF();
  
          pdf.setFontSize(10)
          pdf.setFont(undefined, 'normal')
          pdf.text(`Envío: #${shortId}`, 10, 10)
  
          pdf.setFontSize(18)
          pdf.setFont(undefined, 'bold')
          pdf.text("Listado de productos e instrucciones de preparación", 10, 20)
  
          pdf.setFontSize(10)
          pdf.setFont(undefined, 'normal')
          pdf.text(`Productos del envío: ${totalProducts}  |  Total de unidades: ${totalUnits}`, 10, 28)
  
          const startY = 40
  
          pdf.setFillColor(230, 230, 230)
          pdf.rect(10, startY, 190, 10, 'F')  
          pdf.setFont(undefined, 'bold')
          pdf.setFontSize(6)
          pdf.text("PRODUCTO", 12, startY + 7)
          pdf.text("UNIDADES", 75, startY + 7)
          pdf.text("REQUIERE ETIQUETA", 105, startY + 7)
          pdf.text("INSTRUCCIÓN DE PREPARACIÓN", 145, startY + 7)
  
          pdf.setFont(undefined, 'normal')
          pdf.rect(10, startY, 190, 10) 
          pdf.line(70, startY, 70, startY + 10)  // Línea entre PRODUCTO y UNIDADES
          pdf.line(100, startY, 100, startY + 10)  // Línea entre UNIDADES y REQUIERE ETIQUETA
          pdf.line(140, startY, 140, startY + 10)  // Línea entre REQUIERE ETIQUETA y INSTRUCCIÓN DE PREPARACIÓN
  
          const detailsStartY = startY + 10
          wr.InfoEnvioProductos.forEach((product, index) => {
              console.log("product ==> ", product)
              const yPosition = detailsStartY + (index * 20)
  
              pdf.rect(10, yPosition, 190, 20)
              pdf.line(70, yPosition, 70, yPosition + 20)  // Línea entre PRODUCTO y UNIDADES
              pdf.line(100, yPosition, 100, yPosition + 20)  // Línea entre UNIDADES y REQUIERE ETIQUETA
              pdf.line(140, yPosition, 140, yPosition + 20)  // Línea entre REQUIERE ETIQUETA y INSTRUCCIÓN DE PREPARACIÓN
  
              pdf.text(`SKU: ${product.Producto.SKUCliente}`, 12, yPosition + 7)
              pdf.text(`Nombre: ${product.Producto.Nombre}`, 12, yPosition + 10)
              
              pdf.text(`${product.CantidadEnviada}`, 75, yPosition + 7)
              
              pdf.text("Cada unidad debe tener la etiqueta", 105, yPosition + 7)
              pdf.setFont(undefined, 'bold')
              pdf.text(`#${product.Producto.SKUFulfillment.slice(-12)}`, 105, yPosition + 10)
              
              pdf.text("Revisa el empaque de tus productos", 145, yPosition + 7)
              pdf.setFont(undefined, 'normal')
              pdf.text("• Cada unidad debe tener un empaque que la cubra", 145, yPosition + 10)
              pdf.text("  por completo para estar resguardada en la bodega.", 145, yPosition + 12)
              pdf.text("  Si no lo tienen, agrégales uno.", 145, yPosition + 14)
          })
  
          pdf.save(`${shortId}.pdf`)
      } catch (err) {
          console.error('Error generating detail pdf:', err)
          alert.error("Error al generar PDF de detalles.")
      }
    }

    const cancelWarehouseShipment = async(shipmentId) => {
      try {
        const t = await getTokenSilently();
        const response = await CancelWarehouseShipment(t, shipmentId)
        if(response.status === 200){
          setIsRefetching(true)
          return alert.success('Envío cancelado correctamente')
        }
      } catch (error) {
        return alert.error('Error al cancelar envío, inténtalo nuevamente.')
      }
    }

  return (
    <Grid container>
        <Grid item xs={4}>
            <Tooltip title="Descargar resumen">
                <Summarize style={{ cursor:'pointer' }} onClick={() => generateSummary(data)} />
            </Tooltip>
        </Grid>
        <Grid item xs={4}>
            <Tooltip title="Imprimir Etiqueta">
                <PrintIcon style={{ cursor:'pointer' }} onClick={() => generateBarCode(data)} />
            </Tooltip>
        </Grid>
        {
          status === 0 && (
            <Grid item xs={4}>
              <Tooltip title="Cancelar Envío">
                <Popconfirm
                  title="¿Seguro que quieres CANCELAR este envío?"
                  onConfirm={() => cancelWarehouseShipment(data.EnvioId)}
                  okText="Cancelar Envio"
                  cancelText="NO Cancelar"
                >
                  <DeleteIcon style={{ cursor:'pointer', color: '#FF4D4F' }}/>
                </Popconfirm>
              </Tooltip>
            </Grid>
          )
        }
    </Grid>
  )
}

export default ColumnActions