import { Typography } from 'antd'

export const tips = [
  <Typography.Paragraph>
    AMPM es una empresa de Telmex, la cual empezó entregando recibos bancarios, es por eso que ahora
    se especializa en paquetes pequeños.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    El servicio más rápido que ofrece DrEnvío es Fedex Express Prioridad.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Hacer un envío internacional es más fácil de lo que parece, sólo necesitas tu guía y un
    documento llamado Factura Comercial.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    También hacemos tu tienda en línea y puedes pagarla hasta en 12 mensualidades, te la entregamos
    en 4-5 semanas, pregunta en el chat.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    {' '}
    Redpack fue comprado en el 2018 por Grupo Traxión y se espera un crecimiento en los siguientes
    años.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Todos los reportes que tengas con tus guías se deben notificar al chat de plataforma.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Las recolecciones que se solicitan antes de las 12pm se programan ese mismo día, después de las 12 hasta el siguiente día.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Las recolecciones de 99minutos se realizan en automático.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Puedes cancelar una guía desde el apartado de envíos si no la utilizaste.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Las recargas arriba de $1,000 no te generan comisión.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Puedes seleccionar sólo tus paqueterías preferidas desde la sección de 'preferencias', eso hará que tus cotizaciones sean más rapidas
  </Typography.Paragraph>,
  <Typography.Paragraph>
    Ya contamos con envíos por tarima.
  </Typography.Paragraph>,
  <Typography.Paragraph>
    La opción de sobre al cotizar toma las medidas de 10x10x10 .5 gramos.
  </Typography.Paragraph>,
]
