import { Fragment, useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { warehouseShipmentsColumns } from '../../components/tables/schemaColumn'
import { warehouseShipmentsDownloads } from '../../components/tables/schemaDownload'
import { Grid } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { Button } from 'antd';

const ITEMS_PER_PAGES = 150

const WarehouseShipments = ({ status }) => {
  const { getTokenSilently } = useAuth0();
  const History = useHistory();

  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('') // string
  const [dataColumns, setDataColumns] = useState(null) // any 
  const [downloadColumns] = useState({
    range: {
      from: 0,
      to: topLimit
    },
    status,
    searched: searchText,
    isAdmin: false,
    columns: [
      {
        id: 'EnvioId',
        find: false
      },
      {
        id: 'Estatus',
        find: false
      },
      {
        id: 'FechaEnvio',
        find: false
      },
      {
        id: 'FechaRecepcion',
        find: false
      },
      {
        id: 'Guia',
        find: false
      },
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState({ ...downloadColumns })

  const handleFecthing = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/warehouseShipments/v2`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    if (saveState){
      setDataColumns(data)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit]) 

  useEffect(() => {
    (async () => {
      await handleFecthing(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns, refetching, status])


  return (
    <Fragment>
      <Grid item sm={6} container justify="flex-end">
      </Grid>
      {' '}
      <Table
        // title={"Mis envios al almacén"}
        tableType="warehouseShipments"
        data={dataColumns ?? []}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={warehouseShipmentsDownloads}
        columns={warehouseShipmentsColumns(status, setIsRefetching) ?? []}
        config={{ disableFilters: true }}
        optionalContent={{
          options: (
            status === 0 && (
              <Button
                type="primary"
                color="primary"
                onClick={() => History.push("/warehouseShippment/create")}
              >
                Enviar mercancía al almacén
              </Button>
            )
          )
        }}
        stateValues={{
          currentPage,
          rowsPerPages,
          topLimit,
          searchText,
          filterByColumns,
          downloadColumns,
          newProspectModal: false,
        }}
        filters={{
          filters: ['date'],
          filterBySearchText(text) { // string //
            setSearchText(text)
          },
          filterByColumns(filter) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter) => {
            return await handleFecthing(filter, false)
          },
        }}
      />
    </Fragment>
  )
}

export default WarehouseShipments