import React from 'react'
import CustonTabs from '../../components/tables/CustonTabs'
import Movements from './Movements'
import OutboundShipments from './OutboundShipments'

const OutboundShipmentsTabs = () => {
  return (
    <CustonTabs
        tabs={
            [
                // {
                //     title: "Despachos automaticos",
                //     component: <Movements />
                // },
                {
                    title: "Ordenes de envio",
                    component: <OutboundShipments />
                }
            ]
        }
    />
  )
}

export default OutboundShipmentsTabs