import { axiosBase as axios } from "./AxiosConfig";
import { GetAllUsersByAuthId } from "./userBalanceData";

export const GetOutputInfo = async (token, idEnvio) => {
  try {
    const response = await axios.get(`/outboundShipments/${idEnvio}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetAllOutputsProgram = async (token) => {
  try {
    const response = await axios.get("warehouse/outboundShipments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const allOutputsProgram = response.data    
    const allIds = allOutputsProgram.map(item => item?.UsuarioId)
    const allUsers = await GetAllUsersByAuthId(token, allIds)

    allOutputsProgram.forEach(item => {
      const find = allUsers.find(user => user.user === item.UsuarioId)
      if (find){
        item.user = find
      }
    })

    return allOutputsProgram
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const ScanOutput = async (token, output, envioId) => {
  try {
    const response = await axios.post(
      "/outboundShipments/scan/" + envioId,
      output,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Algun tipo de Información de Preuba ¿?

// const outputShippment = {
//   UsuarioId: "google-oauth2|2342341423",
//   EnvioId: "60977a4f40b701bde85f2283",
//   Estatus: "Pendiente envío",
//   GuiaURL: "https://google.com",
//   InfoEnvioProductos: [
//     {
//       Producto: {
//         SKUCliente: "54541",
//         Nombre: "Tablero de ajedrez",
//         PesoKG: 1,
//         Largo: 20,
//         Ancho: 20,
//         Altura: 20,
//         ValorMercancia: 200,
//         Descripcion: "Tablero profesional de ajedrez",
//         Fotografia:
//           "https://images-na.ssl-images-amazon.com/images/I/61ERJDxLSgL._AC_UX466_.jpg",
//         SKUFulfillment: "60977a4f40b701bde87f3282",
//       },
//       CantidadEnviada: 2,
//       CantidadRecibida: 0,
//     },
//     {
//       Producto: {
//         SKUCliente: "50468",
//         Nombre: "Reloj de ajedrez",
//         PesoKG: 3,
//         Largo: 20,
//         Ancho: 20,
//         Altura: 20,
//         ValorMercancia: 200,
//         Descripcion: "Reloj profesional de ajedrez",
//         Fotografia:
//           "https://images-na.ssl-images-amazon.com/images/I/617VFU3qPFL._AC_SX425_.jpg",
//         SKUFulfillment: "609c6761af6f5e5ee4175eca",
//       },
//       CantidadEnviada: 5,
//       CantidadRecibida: 0,
//     },
//   ],
// };
