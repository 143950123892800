import { Col, Row } from 'antd'
import Graph from './Graph'

const GraphList = ({ data, isLoading })=> {

    return (
        <Row gutter={[10, 10]}>
            {
                data.map(item => (
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Graph
                            title={item.title}
                            isLoading={isLoading}
                            filteredData={item.data}
                            chartType={item.chartType}
                            filterType={item.filterType}
                        />
                    </Col>
                ))
            }
        </Row>
    )
}

export default GraphList
