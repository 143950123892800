import { axiosBase as axios } from "./AxiosConfig";

export const FetchUserInfo = async (token) => {
  try {
    const res = await axios.get(
      `${
        process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL ||
        "https://prod.api-drenvio.com/v2"
      }/users/info`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const GetAllUsersByAuthId = async (token, userIds) => {
  try {
    const res = await axios.post(
      `${
        process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL ||
        "https://prod.api-drenvio.com/v2"
      }/users/infoByAuthIds`,
      userIds,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data.res;
  } catch (err) {
    return err;
  }
};

export const GetAllUsers = async (token, filterByColumns) => {
  try {
    const res = await axios.post(
      `${
        process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL ||
        "https://prod.api-drenvio.com/v2"
      }/users/fulfillment`,
      filterByColumns,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("res ==>", res)

    return res.data;
  } catch (err) {
    return err;
  }
};

export const ValidateAuth = async (token, user) => {
  try {
    const res = await axios.post(
      `${
        process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL ||
        "https://prod.api-drenvio.com/v2"
      }/users/fulfillment/auth`,
      { user },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data.res;
  } catch (err) {
    return err;
  }
};

export const UserIntegrationCheck = async(token) => {
  try {
    const response =  await axios.get(
      `/integrations/check`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}