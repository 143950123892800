// import moment from 'moment'
import * as XLSX from 'xlsx'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import {Button, Table, Input, Tooltip} from 'antd'
import {ProfileOutlined, ReloadOutlined} from '@ant-design/icons'
import { useToggle } from '../../hooks/useToggle'
// import { ModalDataPicker } from './ModalDataPicker'
// import ModalContainer from './ModalContainer'
// import TabsFilterContainer from './TabsFilterContainer'
// import { ColumnsType } from 'antd/es/table'
// import { AllColumnsType, GetAllFilteredShipmentsQuery } from '../interfaces/tableSchema'
// import { useLocation } from 'react-router-dom'


// interface TableProps {
//   type?: 'movements' | 'generic'
//   title?: string
//   isShipments?: boolean
//   isLoading?: boolean
//   tableType: TableType
//   data: object[] 
//   columns: ColumnsType<AllColumnsType>
//   schemaDownload: (data: any[]) => any[] 
//   onChangePage: (page: number) => void
//   onChangeRowsPerPage: (row: number) => void
//   optionalContent?: {search?: JSX.Element, options? : JSX.Element}
//   config?: {
//     exportButton?: boolean
//     selection?: any
//     actionsColumnIndex?: number
//     sorting?: boolean
//     disableExport?: boolean
//     disableFilters?: boolean
//   }
//   stateValues: {
//     currentPage: number
//     rowsPerPages: number
//     topLimit: number
//     searchText: string
//     filterByColumns: GetAllFilteredShipmentsQuery
//     downloadColumns: GetAllFilteredShipmentsQuery
//     newProspectModal?: boolean
//     newStpAccountModal?: boolean
//   }
//   filters: {
//     filters: ('date' | 'price')[]
//     filterBySearchText: (text: string) => void
//     filterByColumns: (filter: GetAllFilteredShipmentsQuery) => void
//     filterDownloads: (filter: GetAllFilteredShipmentsQuery) => Promise<any>
//     activateNewProspect: () => void 
//     addNewProspect: (newProspect: any) => void 
//     toggleNewProspectModal?: any
//     manageStpWithdrawalAccount?: {
//       activateNewStpAccount: () => void
//       addNewStpAccount: (newStpAccount: any) => void 
//       toggleNewStpAccountModal: any
//     }
//   }
// }

const CustomTable = ({
  columns,
  data,
  title,
  config,
  isShipments,
  filters,
  isLoading,
  stateValues,
  onChangePage,
  onChangeRowsPerPage,
  optionalContent,
  tableType,
  reload,
  handleReload,
  ...rest
}) => {
  const { state, toggle } = useToggle()
  const { state: isFiltersOpen, toggle: toggleFiltersModal } = useToggle()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [fileType, setFileType] = useState('xlsx')
  const [rangeDate, setRangeDate] = useState(null) // [string, string] | null
  const setFilterRange = useState([null, null])[1] // [Date | null, Date | null]
  const [searchInputText, setSearchInputText] = useState('') // string 
  const [actionSelected, setActionSelected] = useState(null) // string | null

// xlsx | csv
  const beforeDownload = (fileType) => {
    downloadExcel(fileType)
    // setFileType(fileType)
    // toggle()
  }


  // xlsx | csv
  const downloadExcel = async (type) => {
    setIsButtonLoading(true)
    try {      
      let formatedData = data 
      // if (rangeDate !== null && (rangeDate?.[0] || rangeDate[1])){
      //   const downloadColumns = { ...stateValues.downloadColumns }
      //   downloadColumns.range.to = 1000000000000000
        
      //   downloadColumns.columns.forEach((column, index ) => {  
      //     if (column.id === 'createdAt'){
      //       downloadColumns.columns[index] = {
      //         id: column.id,
      //         find: true,
      //         filters: {
      //             sort: false,
      //             filter: true,
      //             filter_values: [
      //               rangeDate?.[0],
      //               rangeDate?.[1] 
      //             ]
      //         }
      //       }
      //     }
      //   })

      //   const updateData = await filters.filterDownloads(downloadColumns)
      //   formatedData = rest.schemaDownload(updateData)
      // } 

      const downloadColumns = { ...stateValues.downloadColumns }
      downloadColumns.range.to = 1000000000000000

      const updateData = await filters.filterDownloads(downloadColumns)
      formatedData = rest.schemaDownload(updateData)

      const worksheet = XLSX.utils.json_to_sheet(formatedData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, `${title}`)
      XLSX.writeFile(workbook, `${title}.${type}`, { bookType: type })
    } catch (err) {
      console.log({ err })
    } finally {
      setIsButtonLoading(false)
      toggle()
    }
  }

  const handleTableChange = (pagination) => {
    const { page, pageSize } = pagination
    onChangePage(page)
    onChangeRowsPerPage(pageSize)
  }

  // prospects
//   const handleFiltersTableTitle = (tableType) => {
//     switch (tableType) {
//       case 'prospects':
//         return "mis prospectos"
//     }
//   }

  useEffect(() => {
    const copyFilterByColumns = { ...stateValues.filterByColumns }
    copyFilterByColumns.searched = stateValues?.searchText
    filters?.filterByColumns && filters?.filterByColumns(copyFilterByColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateValues?.searchText])

  const { Search } = Input
  
  return (
    <>
      <Table
        size="small"
        loading={isLoading}
        scroll={{ x: true }}
        pagination={{
          current: stateValues.currentPage,
          pageSize: stateValues.rowsPerPages,
          total: data?.length,
          onChange: (page, pageSize) => handleTableChange({ page, pageSize })
        }}
        title={() => (
          <div>
            {title && <Title level={4}>{title}</Title>}
            {/* {(location.pathname === '/' || location.pathname === '/prospects')  && <ReferralLink />}
            {(location.pathname === '/withdrawals')  && (
              <>
                <p style={{ maxWidth: '800px', marginBottom: '20px'}}>
                  {t('Dashboard.Table.Options.Withdrawal.Content')}
                </p>
                <div className='info-filter' style={{ maxWidth: '800px', marginBottom: '20px'}}>
                  <Info 
                      style={{
                          fontSize: '18px', 
                          marginRight: '6px', 
                          verticalAlign: 'middle'
                      }}
                  />
                  {t('Dashboard.Table.Options.Withdrawal.InformativeContent')}
              	</div>
              </>
            )} */}
            <div>
              {!config?.disableExport && (
                <div className="containerOptionsTable" style={{ display: 'flex', width: '100%' }}>
                  <div className="containerbuttons" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="downloadCSV">
                      <Button
                        loading={isButtonLoading}
                        onClick={() => beforeDownload('csv')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                      >
                        {"Descargar CSV"}
                      </Button>
                    </div>
                    <div className="downloadXLSX">
                      <Button
                        loading={isButtonLoading}
                        onClick={() => beforeDownload('xlsx')}
                        style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                        icon={<ProfileOutlined />}
                      >
                        {"Descargar XLSX"}
                      </Button>
                    </div>
                  </div>
                  <div className="containerButtonsTwo" style={{ display: 'flex', flexDirection: 'row' }}>
                    {!config?.disableFilters && (
                      <div className="applyFilter">
                        <Button
                          style={{ marginRight: '1rem', alignItems: 'center', display: 'flex' }}
                          onClick={() => {
                            setActionSelected('Filter')
                            toggleFiltersModal()
                          }}
                        >
                          {"Filtrar"}
                        </Button>
                      </div>
                    )}
                    {optionalContent?.options && (optionalContent?.options)}
                  </div>
                </div>
              )}
            </div>
            <div className='container-search-table'>
              <Search
                  className="searchInput"
                  placeholder={"Buscar"}
                  onChange={(e) => {
                    setSearchInputText(e.target.value)
                  }}
                  onSearch={() => {
                    filters?.filterBySearchText(searchInputText)
                  }}
                  style={{
                    width: 200,
                    marginTop: '15px',
                    marginLeft: 'none',
                    marginRight: '10px'
                  }}

              />
              {
                reload && (
                      <Tooltip title={"Recargar datos"}>
                        <Button icon={<ReloadOutlined />} onClick={handleReload} style={{ marginTop:'15px', marginLeft:'none', marginRight:'10px' }}/>
                      </Tooltip>
                  )
              }
              {optionalContent?.search && (optionalContent?.search)}
            </div>
          </div>
        )}
        columns={columns}
        dataSource={data}
        rowSelection={config?.selection ? config?.selection : undefined}
      />
      {/* <ModalDataPicker
        isLoading={isButtonLoading}
        onAction={() => {
          if (!rangeDate?.[0] || !rangeDate[1]) {
            notification.warning({
              message: "Debe completar los campos"
            })
            return
          }
          downloadExcel(fileType)
        }}
        isOpen={state}
        onCancel={toggle}
        setState={(e) => {
          setRangeDate([moment(e[0].$d).format('YYYY-MM-DD'), moment(e[1].$d).format('YYYY-MM-DD')])
        }}
      /> */}
      {/* <ModalContainer
        title={`${t('Dashboard.Table.filters.MainTitle')} ${handleFiltersTableTitle(tableType)}`}
        visible={isFiltersOpen}
        onCancel={toggleFiltersModal}
        footer={false}
      >
        <TabsFilterContainer
          data={data}
          actionSelected={actionSelected}
          toggleFiltersModal={toggleFiltersModal}
          setFilterRange={setFilterRange}
          stateValues={stateValues}
          setFilterByColumns={filters?.filterByColumns}
          tableType={tableType}
        />
      </ModalContainer> */}
    </>
  )
}

export default CustomTable