import React from 'react'
import CustonTabs from '../../components/tables/CustonTabs'
import BarcodeScanner from './BarcodeScanner'

const TestSectionTabs = () => {
  return (
    <CustonTabs
        tabs={[
            {
                title: "Prueba lector de barras",
                component: <BarcodeScanner />
            }
        ]}
    />
  )
}

export default TestSectionTabs