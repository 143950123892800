import React, {useState} from 'react';
import {Button, Card, Col, Modal, Row, Tooltip} from "antd";
import {DownloadOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {format} from "date-fns";

const InfoMovements = ({ data }) => {

    const [ openModal, setOpenModal ] = useState(false);

    const contentType = (type) => {
        switch (type) {
            case 'box':
                return 'Caja'
            case 'envelope':
                return 'Sobre'
            case 'pallet':
                return 'Tarima'
            default:
                return "N/A"
        }
    }

    const PackageData = () => {

        if(data.metadata.packages){
            return data?.metadata?.packages?.map((pckg, index) => {
                return (
                    <div>
                        <hr />
                        <h3>{contentType(pckg.type)} {index+1}</h3>
                        <h3 style={{margin: "0", padding: "0"}}>Kilos</h3>
                        <p style={{padding: '0', margin:'0'}}>{contentType(pckg.type)} {pckg.weight}Kg</p>
                        <h3 style={{margin: "0", padding: "0"}}>Medidas</h3>
                        <p style={{padding: '0', margin:'0'}}>{ pckg.height } x { pckg.width } x { pckg.length }</p>
                        <h3 style={{margin: "0", padding: "0"}}>Volumétrico</h3>
                        <p style={{padding: '0', margin:'0'}}>{pckg?.volumetric ?? "0"}</p>
                    </div>
                )
            })
        } else {
            return <p>Cargando ...</p>
        }
    }

    const InfoTypeSwitch = (type) => {

        if(!data) return (
            <p>Cargando ....</p>
        )

        switch (type) {
            case 'ENVIO':
                if(!data?.metadata?.label) {
                    return (
                        <p>No hay datos ...</p>
                    )
                }
                return (
                    <Row>
                        <Col span={24}>
                            <Card>
                                <h3 style={{margin: "0", padding: "0"}}>Guia</h3>
                                <p style={{margin: "0", padding: "0"}}>{data?.metadata?.tracking ?? ""}</p>

                                <h3 style={{margin: "0", padding: "0"}}>Fecha de creación</h3>
                                <p style={{margin: "0", padding: "0"}}>{
                                    data?.metadata?.created_at ? (
                                        format(
                                            new Date(data?.metadata?.created_at),
                                            "dd/MM/yyyy HH:MM:SS"
                                        )
                                    ) : (
                                        <p>N/A</p>
                                    )
                                }
                                </p>

                                <h3 style={{margin: "0", padding: "0"}}>Multipaquete</h3>
                                <p style={{
                                    margin: "0",
                                    padding: "0"
                                }}>{data?.metadata?.packages?.length > 1 ? 'Si' : 'No'}</p>

                                <h3 style={{margin: "0", padding: "0"}}>Destino</h3>
                                <p style={{
                                    margin: "0",
                                    padding: "0"
                                }}>{`${data?.metadata?.destination?.street} ${data?.metadata?.destination?.district} ${data?.metadata?.destination?.number} ${data?.metadata?.destination?.city} ${data?.metadata?.destination?.postal_code} ${data?.metadata?.destination?.country}`}</p>

                                <h3 style={{margin: "0", padding: "0"}}>Referencia del destino</h3>
                                <p style={{margin: "0", padding: "0"}}>{data?.metadata?.destination?.reference ?? "NA"}</p>

                                <h3 style={{margin: "0", padding: "0"}}>Teléfono destinatario</h3>
                                <p style={{margin: "0", padding: "0"}}>{data?.metadata?.destination?.phone ?? "NA"}</p>

                                <h3 style={{margin: "0", padding: "0"}}>Correo destinatario</h3>
                                <p style={{margin: "0", padding: "0"}}>{data?.metadata?.destination?.email ?? "NA"}</p>

                                <Button icon={<DownloadOutlined />} style={{ margin:'20px 0' }} type="primary" onClick={() => window.location.href = data?.metadata?.label}>Descargar Guía</Button>
                                <PackageData />
                            </Card>
                        </Col>
                    </Row>
                )
            case 'MODIFICACION':
                if(!data?.metadata?.newData) {
                    return (
                        <p>No hay datos</p>
                    )
                }
                return (
                    <div>
                        <h3>Información del producto modificado</h3>
                        <p>Nombre: { data?.metadata?.newData?.Nombre }</p>
                        <p>Descripción: { data?.metadata?.newData?.Descripcion }</p>
                        <p>SKU: { data?.metadata?.newData?.SKUCliente }</p>
                        <p>Cantidad: { data?.metadata?.newData?.CantidadEnInventario}</p>
                    </div>
                )
            default:
                return "No hay data"
        }
    }

    return (
        <>
            <Tooltip title="Ver información">
                {/*<Button onClick={() => setOpenModal(true)} disabled={data?.type !== "ENVIO"} shape="circle" type="primary" icon={<InfoCircleOutlined style={{ fontSize:'20px' }} />} />*/}
                <InfoCircleOutlined style={{ fontSize:'20px', cursor:'pointer' }} onClick={() => setOpenModal(true)} />
            </Tooltip>
            <Modal footer={<></>} open={openModal} onCancel={()=>setOpenModal(false)} width={'30rem'}>
                {InfoTypeSwitch(data.type)}
            </Modal>
        </>
    );
};

export default InfoMovements;