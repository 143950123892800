import React, { useEffect, useState } from 'react'

import { Box, Grid, Input, InputLabel } from '@material-ui/core'

import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";

import bwipjs from 'bwip-js';
import { jsPDF } from 'jspdf';
import { UpdateQTYProduct } from '../../api/ProductAPI';
import { Button, Modal, Popconfirm } from 'antd';
import { useAlert } from 'react-alert';
import { useAuth0 } from '../../hooks/react-auth0-spa';

const initialState = { Nombre:'', Descripcion:'', SKUCliente:'', CantidadEnInventario:'' }

const ColumnActions = ({ data, refetch, filterByColumns }) => {
  const [openModalEdit, setOpenModalEdit] = useState(false)
  const [updateValueEdit, setUpdateValueEdit] = useState(initialState)

  const [openModal, setOpenModal] = useState(false)
  const [updateValue, setUpdateValue] = useState(0)

  const alert = useAlert();
  const { getTokenSilently } = useAuth0();

  const generateBarCode = async (product, value) => {
    if (value <= 0) {
        alert.info('Debe seleccionar uno como mínimo')
        return
    }

    try {
        const shortId = product.SKUFulfillment.slice(-12)
        const canvas = document.createElement('canvas')
        bwipjs.toCanvas(canvas, {
            bcid: 'code128',
            text: shortId,
            scale: 4,
            height: 10,
            includetext: false,
        })
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const pageWidth = pdf.internal.pageSize.width
        const pageHeight = pdf.internal.pageSize.height

        const margin = 10
        const spacing = 5
        const codeWidth = (pageWidth - margin * 2 - spacing * 2) / 3
        const codeHeight = (pageHeight - margin * 2 - spacing * 9) / 10

        const codesPerRow = 3
        const codesPerColumn = 10
        const codesPerPage = codesPerRow * codesPerColumn

        for (let i = 0; i < value; i++) {
            if (i % codesPerPage === 0 && i !== 0) {
                pdf.addPage()
            } 

            const x = margin + (i % codesPerPage % codesPerRow) * (codeWidth + spacing)
            const y = margin + Math.floor((i % codesPerPage) / codesPerRow) * (codeHeight + spacing)

            pdf.setFontSize(7)
            pdf.setDrawColor(200) // Gris claro
            pdf.setLineWidth(0.2)
            pdf.rect(x, y, codeWidth, codeHeight) // Borde

            pdf.addImage(imgData, 'PNG', x + (codeWidth - codeWidth * 0.6) / 2, y + 2, codeWidth * 0.6, codeHeight * 0.4)

            pdf.setFont("helvetica", "bold")
            pdf.setFontSize(8)
            pdf.text(shortId, x + codeWidth / 2, y + codeHeight * 0.45 + 5, { align: "center" })

            pdf.setFont("helvetica", "normal")
            pdf.setFontSize(6)
            pdf.text(product.Nombre.length > 17 ? `${product.Nombre.slice(0,17)}...` : product.Nombre, x + 2, y + codeHeight * 0.7)
            pdf.text(`${product.Largo} x ${product.Ancho} x ${product.Altura}`, x + 2, y + codeHeight * 0.8)
            pdf.text(`SKU: ${product.SKUCliente}`, x + 2, y + codeHeight * 0.9)
        }

        pdf.save(`${shortId}.pdf`)
        setOpenModal(false)
    } catch (err) {
        console.error('Error generating barcode:', err)
        alert.error("Error al generar etiqueta, revisa bien el producto.")
    }
}

  const updateInventoryQty = async(productId, newValue) => {

    const userToken = await getTokenSilently()
    if(
        Number(newValue.CantidadEnInventario) === data.CantidadEnInventario
        && newValue.Nombre === data.Nombre
        && newValue.Descripcion === data.Descripcion
        && newValue.SKUCliente === data.SKUCliente
    ){
      setOpenModalEdit(false)
      alert.info('Producto sin modificar')
    }

    newValue['CantidadEnInventario'] = Number(newValue.CantidadEnInventario)

    try {
      const response = await UpdateQTYProduct(userToken,{ productId, newValue })
      if(response.status !== 200){
        return alert.error("Error al actualizar cantidad de producto")
      }
      setOpenModalEdit(false)
      await refetch(filterByColumns)
    } catch (error) {
      console.log('Error at updateInventoryQty', error)
      alert.error("Error al actualizar cantidad de producto")
    }
  }

  useEffect(() => {
    setUpdateValueEdit({
        Nombre: data?.Nombre ?? "",
        Descripcion: data?.Descripcion ?? "",
        SKUCliente: data?.SKUCliente ?? "",
        CantidadEnInventario: data?.CantidadEnInventario ?? 0
    })
  }, [data])

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <EditIcon style={{ cursor:'pointer' }} onClick={() => setOpenModalEdit(true)}/>
          </Grid>
          <Grid item xs={6}>
            <PrintIcon style={{ cursor:'pointer' }} onClick={() => setOpenModal(true)} />
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={openModalEdit}
        onCancel={()=>setOpenModalEdit(!openModalEdit)}
        footer={<></>}
        title="Editar"
        width={'300px'}
      >
          <Grid container>
            <Grid item xs={12} style={{ margin:'20px 0' }}>
                <InputLabel>Nombre</InputLabel>
                <Input value={updateValueEdit.Nombre} name="Nombre" onChange={(e)=>setUpdateValueEdit({ ...updateValueEdit, [e.target.name]: e.target.value })} />
            </Grid>
            <Grid item xs={12} style={{ margin:'20px 0' }}>
                <InputLabel>Descripción</InputLabel>
                <Input value={updateValueEdit.Descripcion} name="Descripcion" onChange={(e)=>setUpdateValueEdit({ ...updateValueEdit, [e.target.name]: e.target.value })}/>
            </Grid>
            <Grid item xs={12} style={{ margin:'20px 0' }}>
              <InputLabel>SKU</InputLabel>
              <Input value={updateValueEdit.SKUCliente} name="SKUCliente" onChange={(e)=>setUpdateValueEdit({ ...updateValueEdit, [e.target.name]: e.target.value } )}/>
            </Grid>
            <Grid item xs={12} style={{ margin:'20px 0' }}>
                <InputLabel>Cant. productos en inventario</InputLabel>
                <Input type='number' name="CantidadEnInventario" value={updateValueEdit.CantidadEnInventario} onChange={(e)=>setUpdateValueEdit({ ...updateValueEdit, [e.target.name]: e.target.value })}/>
            </Grid>
            <Grid item xs={6}>
                <Button type='primary' danger style={{ width:'100%' }} onClick={()=> setOpenModalEdit(false)}>Cancelar</Button>
            </Grid>
            <Grid item xs={6}>
              <Popconfirm
                title="¿Actualizar la cant. de productos en el inventario?"
                onConfirm={() => updateInventoryQty(data._id, updateValueEdit)}
                okText="Actualizar"
                cancelText="Cancelar"
              >
                <Button type='primary' style={{ width:'100%' }}>Actualizar</Button>
              </Popconfirm>
            </Grid>
          </Grid>
      </Modal>
      <Modal
        open={openModal}
        onCancel={()=> setOpenModal(false)}
        footer={<></>}
        title="Descargar codigos de barras"
        width={'300px'}
      >
        <Grid container>
          <Grid item xs={12} style={{ margin:'20px 0' }}>
            <InputLabel>
              Para ahorrar papel, puedes seleccionar la cantidad de codigos de barras que deseas
            </InputLabel>
            <Input 
              type='number' 
              value={updateValue} 
              onChange={(e)=> setUpdateValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button 
              type='primary' 
              danger style={{ width:'100%' }} 
              onClick={()=> setOpenModal(false)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
              type='primary' 
              style={{ width:'100%' }}
              onClick={()=> generateBarCode(data, updateValue)}
            >
              Generar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default ColumnActions