import React, { useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { outboundsDownloads } from '../../components/tables/schemaDownload'
import {outboundShipments, outboundShipmentsAdmin} from '../../components/tables/schemaColumn';
import { useHistory } from "react-router-dom";
import {Button, Card, Modal} from 'antd';
import {format} from "date-fns";
import {DownloadOutlined} from "@ant-design/icons";
import {GetAllUsers} from "../../api/userBalanceData";

const ITEMS_PER_PAGES = 150

const WarehouseOutputIndex = () => {
  const { getTokenSilently } = useAuth0();
  const History = useHistory();

  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('') // string
  const [dataColumns, setDataColumns] = useState([]) // any
  const [openModal, setOpenModal] = useState({ open: false, data: undefined })

  const getUserData = async (dataColumns) => {

    console.log('Data Columns ------------->', dataColumns)

    const userToken = await getTokenSilently()
    const data = await GetAllUsers(userToken, [])
    const filteredData = data.filter(item => item.ApiClientsV2.length > 0)
    if(dataColumns.length > 0){
      const newArray = []
      dataColumns.forEach(item => {
        newArray.push({
          ...item,
          envioId: item?.EnvioId ?? "",
          email: item?.user.email ?? "",
          estatus: item?.Estatus ?? "",
          fechaEnvio: item?.FechaEnvio ?? "",
          user: item?.UsuarioId ?? "",
          ApiClientsV2: filteredData.find(fi => fi.user === item.UsuarioId)?.ApiClientsV2 ?? []
        })
      })
      if(newArray.length > 0){
        // console.log('New Array ----------->', newArray)
        setDataColumns(newArray)
      }
    }

  }

  const [downloadColumns] = useState({
    range: {
      from: 0,
      to: topLimit
    },
    isAdmin: true,
    searched: searchText,
    columns: [
      {
        id: 'EnvioId',
        find: false
      },
      {
        id: 'Estatus',
        find: false
      },
      {
        id: 'FechaEnvio',
        find: false
      },
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState({ ...downloadColumns })

  const handleFecthing = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/outboundShipments/get/v2`,
        filterByColumns,
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    if (saveState){
      await getUserData(data)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit])

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecthing(filterByColumns)
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns])

  return (
      <Card>

        <Modal
            open={openModal.open}
            onCancel={() => setOpenModal({data: undefined, open: false})}
            footer={<></>}
        >

          <h3>Productos enviados</h3>
          {
            !openModal?.data
                ? (<p>No hay datos</p>)
                : openModal?.data && openModal.data.productsData.length > 0
                    ? (
                        openModal.data?.productsData?.map((item) => {
                          return (
                              <>
                                {
                                  openModal.data.InfoEnvioProductos.map((producto, index) => {
                                    return (
                                        <div style={{ margin:'20px 0' }}>
                                          <p style={{ margin: 0, padding: 0 }}><span
                                              style={{fontWeight: '600'}}>{index + 1} )</span> {item.SKUCliente} - {item.Nombre}
                                          </p>
                                          <p style={{ margin: 0, padding: 0, fontWeight:'600' }}>Cantidad a Enviar - {producto?.CantidadEnviada ?? 0}</p>
                                          <p style={{ margin: 0, padding: 0, fontWeight:'600' }}>Cantidad Despachada - {producto?.CantidadRecibida ?? 0}</p>
                                        </div>
                                    )
                                  })
                                }
                              </>
                          )
                        })
                    )
                    : <p>No hay productos</p>
          }

          <h3 style={{ margin:0, padding: 0 }}>Fecha de envio</h3>
          <p style={{ margin:0, padding: 0 }}>{ openModal?.data?.FechaEnvio
              ? format(
                  new Date(openModal?.data?.FechaEnvio),
                  "dd/MM/yyyy HH:MM:SS"
              ) : "NA" }
          </p>

          <Button icon={<DownloadOutlined />} style={{ margin:'20px 0' }} type="primary" disabled={!openModal?.data?.GuiaURL} onClick={() => window.location.href = openModal?.data?.GuiaURL}>{ openModal?.data?.GuiaURL ? "Descargar Guía" : "Guía no disponible" }</Button>

        </Modal>

        <Table
            tableType="outboundShipments"
            data={dataColumns ?? []}
            isLoading={refetching}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeRowsPerPage={(row) => setRowPerPages(row)}
            schemaDownload={outboundsDownloads}
            columns={outboundShipmentsAdmin(setOpenModal) ?? []}
            config={{ disableFilters: true }}
            stateValues={{
              currentPage,
              rowsPerPages,
              topLimit,
              searchText,
              filterByColumns,
              downloadColumns,
              newProspectModal: false,
            }}
            filters={{
              filters: ['date'],
              filterBySearchText(text) { // string //
                setSearchText(text)
              },
              filterByColumns(filter) {
                setFilterByColumns(filter)
              },
              filterDownloads: async (filter) => {
                return await handleFecthing(filter, false)
              },
            }}
        />
      </Card>
  )
}

export default WarehouseOutputIndex
