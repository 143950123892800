import { useState, useEffect } from 'react'
import Chart from "react-apexcharts"
import CardContainer from "../../pages/quoter/containers/CardContainer"
import Loader from '../../components/generals/Loader'

const Graph = ({ title, isLoading, filteredData, chartType, filterType }) => {
    const [categories, setCategories] = useState([])
    const [data, setData] = useState([])
    const [height, setHeight] = useState(287)
    
    const handleData = async () => {
        switch (filterType) {
            case 'day-count':
                const days = filteredData?.map((item) => item?.day.toString())
                const countsDays = filteredData?.map((item) => item?.count)

                setCategories(days)
                setData(countsDays)
                break
            case 'month-count':
                filteredData?.sort((a, b) => {
                    const orderedMonths = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]
                    return orderedMonths.indexOf(a?.month) - orderedMonths.indexOf(b?.month)
                })

                const months = []
                const counts = []

                if (filteredData){
                    for (const obj of filteredData) {
                        months.push(obj.month)
                        counts.push(obj.count)
                    }
    
                    setCategories(months)
                    setData(counts)
                }
                break
        }
    }

    useEffect(() => {
        handleData()
    }, [filteredData])//eslint-disable-line

    useEffect(() => {
        const trackingScreen = ()=> {
            if (window.innerWidth <= 1115) {
                setHeight(230.28)
            } else {
                setHeight(287)
            }
        }

        trackingScreen()
        window.addEventListener('resize', trackingScreen)

        return () => {
            window.removeEventListener('resize', trackingScreen)
        }
    }, [])

    // area | bar 
    const options = {
        chart: {
            id: `${filterType}-bar`
        },
        xaxis: {
            categories
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value.toFixed(0)
                }
            }
        }
    }

    const series = [
        {   
            name: 'Cantidad',
            data
        }

    ]

    // donut 
    const donutOptions = {
        labels: categories
    }

    const donutSeries = data

    return (
        <CardContainer cardStyle={{ marginTop: '20px' }} title={title} >
            {isLoading ? (
                <div className='containerStatistics' style={{ height }}>
                    <Loader />
                </div>
            ) : (
                <>
                    {
                        categories.length === 0 || data.length === 0 ? (
                            <div className='containerStatistics' style={{ height }}>
                                No hay datos para mostrar
                            </div>
                        ) : (
                            <Chart
                                options={chartType === 'donut' ? donutOptions : options}
                                series={chartType === 'donut' ? donutSeries : series}
                                type={chartType}
                                width="100%"
                            />
                        )
                    }
                </>
            )}
        </CardContainer>
    )
}

export default Graph