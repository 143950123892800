import React, { useContext } from 'react'
import { QuoteDataContext } from '../../context/QuoteDataContext'

// Quote Steps
import PostalCodeQuote from './steps/PostalCodeQuote'
import SelectFavParcel from './steps/SelectFavParcel'
import FillGuideInfoForm from './steps/FillGuideInfoForm'
// import GuideSuccesScreen from '../QuoteSteps/Classic/GuideSuccesScreen'

const Quotes = ({ token, 
  userId, 
  shipment, 
  handleStep, 
  handleStep3, 
  handleFinish, 
  saveData, 
  initAddressData, 
  integrationOrder, 
  updateOrdersData
}) => {
  const { quoteStep } = useContext(QuoteDataContext)

  const { DestinationData } = saveData
  if(DestinationData){
    DestinationData['envioId'] = saveData.envioId
    // console.log('saveData =======>', saveData)
  }

  const QuoteStepComponentsList = {
    '1) Quote based on Postal Codes': (
      <PostalCodeQuote 
        token={token} 
        initAddressData={initAddressData} 
        destinationData={DestinationData} 
        integrationOrder={integrationOrder} 
      />
    ),
    '2) Select your favorite parcel': (
      <SelectFavParcel 
        token={token} 
        saveData={saveData}
        handleStep={handleStep} 
        isIntegration={Boolean(integrationOrder && integrationOrder.fulfillment_info.status !== 'fulfilled')}
      />
    ),
    '3) Fill all guide info form': (
      <FillGuideInfoForm 
        userId={userId} 
        token={token} 
        saveData={saveData}
        handleStep3={handleStep3} 
        shipment={shipment} 
        destinationData={DestinationData} 
        integrationOrder={integrationOrder} 
        updateOrdersData={updateOrdersData}
      />
    ),
    // '4) Guide bought succesfully screen': <GuideSuccesScreen />
  }

  return <section className="dashboardSection ">{QuoteStepComponentsList[quoteStep]}</section>
}

export { Quotes };
