import React from 'react'
import CustonTabs from '../../components/tables/CustonTabs'
import WarehouseShipments from './WarehouseShipments'

const WarehouseShipmentsTabs = () => {
  return (
    <CustonTabs
        tabs={
            [
                {
                    title: "Enviados",
                    component: <WarehouseShipments status={0} />
                },
                {
                    title: "Recibidos",
                    component: <WarehouseShipments status={1} />
                },
                {
                    title: "Cancelados",
                    component: <WarehouseShipments status={2} />
                }
            ]
        }
    />
  )
}

export default WarehouseShipmentsTabs