import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Modal } from 'antd';
import InfoIcon  from "@material-ui/icons/Info";

const DetailsChanges = ({ data: product }) => {
  const [openModal, setOpenModal] = useState(false)
  const [requestDate, setRequestDate] = useState('')
  const [before, setBefore] = useState(null)
  const [after, setAfter] = useState(null);

  useEffect(()=> {
    const find = product.Tracking.find(item => !item.Revised)
    if (find){
        setRequestDate(find?.Date)
        setBefore(find.Before)
        setAfter(find.After)
    }
  }, [product])

  return (
    <>
      <Box>
        <Grid container style={{ justifyContent: 'center'}}>
          <InfoIcon onClick={()=> setOpenModal(true)} />
        </Grid>
      </Box>
      <Modal
        open={openModal}
        onCancel={()=> setOpenModal(false)}
        footer={<></>}
        width={'95%'}
        height={"95%"}
      >
        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Fecha de solicitud: </span>{new Date(requestDate).toLocaleString()}</p>
        <Grid container>
            {(before && after) && (
                <>
                    <Grid item xs={6}>
                        <h2 style={{ textAlign: 'center'}}>Antes</h2>
                        <p style={{ textAlign: 'center'}}>
                            <img
                                src={before.Fotografia}
                                alt={before.Nombre}
                                width="80"
                                height="80"
                                style={{ borderRadius: '50%'}}
                            />
                        </p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>SKU: </span>{before.SKUCliente}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Nombre: </span>{before.Nombre}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Altura: </span>{before.Altura}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Largo: </span>{before.Largo}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Ancho: </span>{before.Ancho}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>PesoKG: </span>{before.PesoKG}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Descripcion: </span>{before.Descripcion}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>ValorMercancia: </span>{before.ValorMercancia}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <h2  style={{ textAlign: 'center'}}>Despues</h2>
                        <p style={{ textAlign: 'center'}}>
                            <img
                                src={after.Fotografia}
                                alt={after.Nombre}
                                width="80"
                                height="80"
                                style={{ borderRadius: '50%'}}
                            />
                        </p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>SKU: </span>{after.SKUCliente}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Nombre: </span>{after.Nombre}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Altura: </span>{after.Altura}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Largo: </span>{after.Largo}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Ancho: </span>{after.Ancho}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>PesoKG: </span>{after.PesoKG}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>Descripcion: </span>{after.Descripcion}</p>
                        <p style={{ textAlign: 'center'}}><span style={{ fontWeight: "bold" }}>ValorMercancia: </span>{after.ValorMercancia}</p>
                    </Grid>
                </>
            )}
        </Grid>
      </Modal>
    </>
  )
}

export default DetailsChanges