import { useEffect, useState } from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import {usersByIntegration} from '../../components/tables/schemaColumn';
import {Button, Card} from "antd";
import {usersByIntegrationDownloads} from "../../components/tables/schemaDownload";
import {ArrowLeftOutlined} from "@ant-design/icons";

const ITEMS_PER_PAGES = 150

const IntegrationCustomers = ({ integration, setShowIntegration, setModal, modal }) => {
    const { getTokenSilently } = useAuth0();

    const [rowsPerPages, setRowPerPages] = useState(20)
    const [currentPage, setCurrentPage] = useState(1)
    const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
    const [refetching, setIsRefetching] = useState(false)
    const [searchText, setSearchText] = useState('') // string
    const [dataColumns, setDataColumns] = useState(null) // any
    const [downloadColumns] = useState({
        range: {
            from: 0,
            to: topLimit
        },
        searched: searchText,
        isAdmin: true,
        type: integration,
        columns: [
            {
                id: 'name',
                find: false
            },
            {
                id: 'created_at',
                find: false
            },
            {
                id: 'url',
                find: false
            }
        ]
    })
    const [filterByColumns, setFilterByColumns] = useState({ ...downloadColumns })

    const handleFecthing = async (filterByColumns, saveState = true) => {
        const userToken = await getTokenSilently()
        const { data } = await axios.post(
            `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/fulfillment/getUsersByIntegration`,
            filterByColumns,
            { headers: { Authorization: `Bearer ${userToken}` }}
        )

        if (saveState){
            setDataColumns(data?.usersByIntegrations ?? [])
        } else {
            return data ?? []
        }
    }

    useEffect(() => {
        if (!dataColumns?.length) return
        const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

        if (currentPage + 1 < Math.round(totalPages)) return
        setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    useEffect(() => {
        (async () => {
            if (topLimit === ITEMS_PER_PAGES) return
            setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topLimit])

    useEffect(() => {
        (async () => {
            setIsRefetching(true)
            await handleFecthing(filterByColumns)
            setIsRefetching(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByColumns])

    return (
        <>
        <Button style={{ marginBottom:'10px' }} onClick={() => setShowIntegration({ name: '', show: false })} icon={<ArrowLeftOutlined />}>Ver integraciones</Button>
        <Card>
            <Table
                title={"Lista de clientes"}
                tableType="integrationsCustomers"
                data={dataColumns ?? []}
                isLoading={refetching}
                onChangePage={(page) => setCurrentPage(page)}
                onChangeRowsPerPage={(row) => setRowPerPages(row)}
                schemaDownload={usersByIntegrationDownloads}
                columns={usersByIntegration(setModal, modal) ?? []}
                config={{ disableFilters: true }}
                stateValues={{
                    currentPage,
                    rowsPerPages,
                    topLimit,
                    searchText,
                    filterByColumns,
                    downloadColumns,
                    newProspectModal: false,
                }}
                filters={{
                    filters: ['date'],
                    filterBySearchText(text) { // string //
                        setSearchText(text)
                    },
                    filterByColumns(filter) {
                        setFilterByColumns(filter)
                    },
                    filterDownloads: async (filter) => {
                        return await handleFecthing(filter, false)
                    },
                }}
            />
        </Card>
        </>

    )
}

export default IntegrationCustomers