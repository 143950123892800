import { axiosBase as axios } from "./AxiosConfig";

export const GetAllShipments = async (token) => {
  try {
    const response = await axios.get("/warehouseShipments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const CreateShippment = async (token, warehouseShippment) => {
  try {
    const response = await axios.post(
      "/warehouseShipments",
      warehouseShippment,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetShippmentByCode = async (token, codigoEnvio) => {
  try {
    const response = await axios.get(`/warehouseShipments/${codigoEnvio}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;

    //return shipment2;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const FinishScanShippment = async (token, envio) => {
  try {
    const response = await axios.post(
      `/warehouseShipments/scan/${envio.EnvioId}`,
      envio,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GenerateBarCodePDF = async (token, EnvioId) => {
  try {
    const response = await axios.get(
      `/barcode/generate/warehouse-shipment/${EnvioId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const CancelWarehouseShipment = async (token, shipmentId) => {
  try {
    const response = await axios.post(
      `/warehouseShipments/cancel`,
      { shipmentId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response
  } catch (error) {
    console.log('Error at CancelWarehouseShipment', error)
    return error
  }
}

// Shipment Data for Teseting Purposes

// const shipments = [
//   {
//     Codigo: "0000001",
//     Estatus: "Enviado",
//     FechaEnvio: "12/05/2021",
//     FechaRecepcion: null,
//     Guia: {
//       Proveedor: "Fedex",
//       Numero: "6540684",
//     },
//     Productos: [
//       {
//         SkuFulfillment: "3043540",
//         Cantidad: 2,
//       },
//     ],
//   },
//   {
//     Codigo: "0000002",
//     Estatus: "Enviado",
//     FechaEnvio: "12/05/2021",
//     FechaRecepcion: null,
//     Guia: {
//       Proveedor: "DHL",
//       Numero: "5135103",
//     },
//     Productos: [
//       {
//         SkuFulfillment: "3043540",
//         Cantidad: 2,
//       },
//       {
//         SkuFulfillment: "3043550",
//         Cantidad: 1,
//       },
//     ],
//   },
//   {
//     Codigo: "0000003",
//     Estatus: "Recibido en Almacén",
//     FechaEnvio: "12/05/2021",
//     FechaRecepcion: "15/05/2021",
//     Guia: {
//       Proveedor: "DHL",
//       Numero: "5135143",
//     },
//     Productos: [
//       {
//         SkuFulfillment: "3043540",
//         Cantidad: 2,
//       },
//       {
//         SkuFulfillment: "3043550",
//         Cantidad: 1,
//       },
//     ],
//   },
// ];

// const shipment = {
//   Codigo: "0000002",
//   Estatus: "Por Confirmar envio",
//   Guia: {
//     Proveedor: "DHL",
//     Numero: "5135103",
//   },
//   Productos: [
//     {
//       SkuFulfillment: "3043540",
//       Cantidad: 2,
//     },
//     {
//       SkuFulfillment: "3043550",
//       Cantidad: 1,
//     },
//   ],
// };

// const shipment2 = {
//   UsuarioId: "google-oauth2|2342341423",
//   EnvioId: "60977a4f40b701bde85f2283",
//   Estatus: "Pendiente envío",
//   Guia: "AS123",
//   ProveedorGuia: "FEDEX",
//   InfoEnvioProductos: [
//     {
//       Producto: {
//         SKUCliente: "54541",
//         Nombre: "Tablero de ajedrez",
//         PesoKG: 1,
//         Largo: 20,
//         Ancho: 20,
//         Altura: 20,
//         ValorMercancia: 200,
//         Descripcion: "Tablero profesional de ajedrez",
//         Fotografia:
//           "https://images-na.ssl-images-amazon.com/images/I/61ERJDxLSgL._AC_UX466_.jpg",
//         SKUFulfillment: "60977a4f40b701bde87f3282",
//       },
//       CantidadEnviada: 2,
//       CantidadRecibida: 0
//     },
//     {
//       Producto: {
//         SKUCliente: "50468",
//       Nombre: "Reloj de ajedrez",
//       PesoKG: 3,
//       Largo: 20,
//         Ancho: 20,
//         Altura: 20,
//         ValorMercancia: 200,
//       Descripcion: "Reloj profesional de ajedrez",
//       Fotografia:
//         "https://images-na.ssl-images-amazon.com/images/I/617VFU3qPFL._AC_SX425_.jpg",
//       SKUFulfillment: "609c6761af6f5e5ee4175eca",
//       },
//       CantidadEnviada: 5,
//       CantidadRecibida: 0
//     },
//   ]
// }
