import CustonTabs from '../../components/tables/CustonTabs';
import ProductStatistics from "./ProductStatistics"
import WarehouseShipmentStatistics from "./WarehouseShipmentStatistics"
import OutboundShipmentStatistics from "./OutboundShipmentStatistics"

const Statistics = ()=> {
    return (
        <CustonTabs 
          tabs={
            [
              {
                title: 'Productos',
                component: <ProductStatistics />
              },
              {
                title: 'Envios almacen',
                component: <WarehouseShipmentStatistics />
              },
              {
                title: 'Ordenes de envio',
                component: <OutboundShipmentStatistics />
              },
            ]
          }
        />
    )
}

export default Statistics