import { Button, Input, Select } from 'antd';
import React, { useState } from 'react'

const BarcodeScanner = () => {
    const [barcode, setBarcode] = useState('');
    const [codes, setCodes] = useState([]);
    const [eventKey, setEventKey] = useState(undefined)

    const handleInputChange = (event) => {
        setBarcode(event.target.value);
    };

    const handleKeyDown = (event) => {
        if(!eventKey) return
        if (event.key === eventKey) {
            if(codes.length > 3) {
                setBarcode('')
                setCodes([])
            }
            setCodes(prevCodes => [...prevCodes, barcode]);
            setBarcode('');
        }
    };

    const reloadTest = () => {
        setBarcode('')
        setCodes([])
        setEventKey(undefined)
    }

    return (
        <div>
            <div style={{ border:'2px dashed gray', padding:'10px 20px', width:'50%' }}>
                <p>Prueba #1</p>
                <div style={{ display:'flex', justifyContent:'center', alignItems:'center', width:'100%' }}>
                    {codes.length > 0 && codes.map((item, index) => (
                        <p key={index} style={{ background:'#e6e6e6', padding:'10px 20px', border:'1px solid gray', borderRadius:'10px' }}>{item}</p>
                    ))}
                </div>
                <h3>Escanear codigo de barras</h3>
                <div style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <Select
                        placeholder="Selecciona tipo"
                        options={[
                            { value: 'Enter' },
                            { value: 'Tab' },
                        ]}
                        value={eventKey}
                        onChange={(e) => setEventKey(e)}
                        style={{ width:'10rem' }}
                    />
                    <Input
                        type="text"
                        value={barcode}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Escanee el código de barras aquí"
                    />
                    <Button type='primary' onClick={reloadTest} danger>Reiniciar prueba</Button>
                </div>
            </div>
        </div>
    );
};

export default BarcodeScanner;
