import { useEffect, useState } from 'react'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { useAuth0 } from "../../hooks/react-auth0-spa"
import TopCards from './TopCards'
import GraphList from './GraphList'

const ProductStatistics = ()=> {
    const [loading, setLoading] = useState(false)
    const [fetchingData, setFetchingData] = useState(null)
    const { getTokenSilently } = useAuth0()

    const handleFecthStatistics = async (statisticsType) => {
        setLoading(true)
        const userToken = await getTokenSilently()
        const { data } = await axios.post(
            `/statistics`, 
            statisticsType, 
            { headers: { Authorization: `Bearer ${userToken}` } }
        )  
        
        setFetchingData(data)
        setLoading(false)
    }

    useEffect(()=> {
        (async()=> {
            await handleFecthStatistics({
                products: true,
                warehouseShipments: false,
                outboundShipments: false
            })
        })()
    }, [])

    return (
        <>
            <TopCards
                data={
                    [
                        {
                            title: 'Productos hoy',
                            data: fetchingData?.res?.products?.totalProductsPerDay ?? 0
                        },
                        {
                            title: 'Productos mes',
                            data: fetchingData?.res?.products?.totalProductsPerMonth ?? 0
                        },
                    ]
                }
                isLoading={loading}
            />
            <GraphList 
                data={
                    [
                        {
                            title: 'Productos dia',
                            data: fetchingData?.res?.products?.productsPerDay,
                            chartType: 'area',
                            filterType: 'day-count'
                        },
                        {
                            title: 'Productos mes',
                            data: fetchingData?.res?.products?.productsPerMonth,
                            chartType: 'bar',
                            filterType: 'month-count'
                        },
                    ]
                }
                isLoading={loading}
            />
        </>
    )
}

export default ProductStatistics
