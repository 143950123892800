import React, { createContext, useState, useEffect, useContext } from "react";
import { Auth0Context } from "./../hooks/react-auth0-spa";

// Api Fetchers
import { FetchUserInfo } from "./../api/userBalanceData";
import { GetAllShipments } from "./../api/WarehouseShippingAPI";
import { GetAllOutboundShipments } from "../api/OutboundShipmentsAPI";
import { ValidateAuth } from "../api/userBalanceData";

export const DataContext = createContext();

export const DataContextProvider = (props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    userBalanceData: 0,
    wareHouseShipmentData: [],
    outboundShipmentDetail: [],
    updateData: true,
  });

  const { user, getTokenSilently, isAuthenticated } = useContext(Auth0Context);

  const getUserData = async() => {
    setIsLoading(true)
    const token = await getTokenSilently();
    await ValidateAuth(token, user.sub)
    const response = await FetchUserInfo(token, user.sub)
    if(response.success){
      setData({
        ...data,
        userBalanceData: response.res.balance,
        userInfo: response.res 
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if(isAuthenticated && user){
      getUserData()
    }
  }, [getTokenSilently, isAuthenticated, user])

  useEffect(() => {
    const dataFetcher = async () => {
      setIsLoading(true)
      const token = await getTokenSilently();

      // Fetch All User Data
      const wareHouseShipmentData = await GetAllShipments(token);

      setData({
          ...data,
          wareHouseShipmentData: wareHouseShipmentData,
          updateData: false,
      });

      setIsLoading(false)
    };

    if (user && isAuthenticated && data.updateData) dataFetcher();
  }, [user, data, getTokenSilently, isAuthenticated]);

  return (
    <DataContext.Provider value={{ data, setData, isLoading }}>
      {props.children}
    </DataContext.Provider>
  );
};
