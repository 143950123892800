import MexicoSvg from '../assets/images/mexico.svg'
import PeruSvg from '../assets/images/peru.svg'
import ColombiaSvg from '../assets/images/colombia.svg'
import USSvg from '../assets/images/united-states-of-america.svg'
import CanadaSvg from '../assets/images/canada.svg'

const CountriesSettings = new Map([])

CountriesSettings.set('MX', {
  Name: 'México',
  SvgFlag: MexicoSvg,
  Currency: 'mxn',
  Language: 'es',
  PhoneExtension: '+52',
  PostalCodesLength: 4,
  AllowedParcels: [
    'estafeta',
    'mensajerosUrbanos',
    'noventa9Minutos',
    'quiken',
    'fedex',
    'dhl',
    'redpack',
    'carssa',
    'scm',
    'sendex',
    'ampm',
    'ivoy',
    'paquetexpress',
    // 'tracusa',
    'uber',
    'ups',
    'vencedor',
    'jtexpress',
    'borzo',
    'tresguerras',
    'treggo',
    'pickit'
  ],
  StoreTabImages: [],
  TopUpRechargeComponents: [],
  FormExtraFields: {}
})

CountriesSettings.set('PE', {
  Name: 'Perú',
  SvgFlag: PeruSvg,
  Currency: 'pen',
  Language: 'es',
  PhoneExtension: '+51',
  PostalCodesLength: 4,
  AllowedParcels: [
    'estafeta',
    'mensajerosUrbanos',
    'noventa9Minutos',
    'quiken',
    'fedex',
    'dhl',
    'redpack',
    'carssa',
    'scm',
    'sendex',
    'ampm',
    'ivoy',
    'paquetexpress',
    // 'tracusa',
    'uber',
    'ups',
    'vencedor',
    'jtexpress',
    'borzo',
    'tresguerras',
    'treggo',
    'pickit'
  ],
  StoreTabImages: [],
  TopUpRechargeComponents: [],
  FormExtraFields: {}
})

CountriesSettings.set('CO', {
  Name: 'Colombia',
  SvgFlag: ColombiaSvg,
  Currency: 'cop',
  Language: 'es',
  PhoneExtension: '+57',
  PostalCodesLength: 6,
  AllowedParcels: [
    'fedex',
    'dhl',
    'ivoy',
    'mensajerosUrbanos',
    'noventa9Minutos',
    'aExpress',
    'coordinadora',
    'redServi',
    'serviEntrega',
    'deprisa',
    'interrapidisimo',
    'moova',
    'picap'
  ],
  StoreTabImages: [],
  TopUpRechargeComponents: [],
  FormExtraFields: {}
})

CountriesSettings.set('PE', {
  Name: 'peru',
  SvgFlag: PeruSvg,
  Currency: 'pen',
  Language: 'es',
  PhoneExtension: '+51',
  PostalCodesLength: 6,
  AllowedParcels: [
    'olva', 'nirex'
  ],
  StoreTabImages: [],
  TopUpRechargeComponents: [],
  FormExtraFields: {}
})

CountriesSettings.set('US', {
  Name: 'United States',
  SvgFlag: USSvg,
  Currency: 'usd',
  Language: 'en',
  PhoneExtension: '+1',
  PostalCodesLength: 5,
  AllowedParcels: ['fedex', 'dhl', 'ups'],
  StoreTabImages: [],
  TopUpRechargeComponents: []
})

CountriesSettings.set('CA', {
  Name: 'Canada',
  SvgFlag: CanadaSvg,
  Currency: 'usd',
  Language: 'es',
  PhoneExtension: '+1',
  PostalCodesLength: 6,
  AllowedParcels: ['fedex', 'dhl', 'ups'],
  StoreTabImages: [],
  TopUpRechargeComponents: []
})

const MapableCountriesSettings = []
CountriesSettings.forEach((countryData, key) => MapableCountriesSettings.push([key, countryData]))

export { CountriesSettings, MapableCountriesSettings }
