import React from 'react';
import { Tabs } from "antd"
import TabPane from "antd/es/tabs/TabPane"

const styles = { 
  width: '100%', 
  backgroundColor: 'white', 
  borderRadius: '8px', 
  padding: '15px 30px'
}

const CustonTabs = ({ tabs }) => {
  return (
    <div>
        <Tabs rootClassName="customTabs" style={{ ...styles, boxShadow:'1px 5px 20px 5px rgba(0, 0, 0, 0.14)', borderRadius: '15px' }} >
            {tabs.map((item, index) => (
              <TabPane tab={item.title} key={`tab${index}`}>
                {item.component}
              </TabPane>
            ))}
        </Tabs>
    </div>
  )
}

export default CustonTabs