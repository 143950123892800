import { axiosBase as axios } from "./AxiosConfig";

export const GetAllOutboundShipments = async (token) => {
  try {
    const response = await axios.get("/outboundShipments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const CreateOutboundShipments = async (token, outboundShipments) => {

  try {
    const formData = new FormData();
    formData.append('GuiaPDF', outboundShipments.guiaPDF );
    formData.append('DatosEnvio', JSON.stringify({InfoEnvioProductos: outboundShipments.InfoEnvioProductos}));

    const response = await axios.post(
      "/outboundShipments",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const CreateOutboundShipmentV2 = async (token, data) => {
  try {
    const response = await axios.post(
        "/outboundShipments/v2",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    )
    return response
  } catch (error) {
    return error;
  }
}

export const GetOutboundShipmentDetail = async (token, outboundShipmentId) => {
  try {
    const response = await axios.get(`/outboundShipments/${outboundShipmentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}



export const GenerateShippingGuide = async(token, request, userToken) => {
  try {
    const response = await axios.post(
      '/warehouse/shipment/generate',
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          userToken
        }
      }
    )
    return response
  } catch (error) {
    return error
  }
}