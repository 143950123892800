import { Button, Card, Modal } from "antd"
import { useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { Steps } from 'antd'
import SelectedProducts from './SelectedProducts'
import FlexibleSelectedProductsByIntegration from './FlexibleSelectedProductsByIntegration'
import StrictSelectedProductsByIntegration from './StrictSelectedProductsByIntegration'
import { Quotes } from "../quoter/Quotes"
import {ArrowRightOutlined} from "@ant-design/icons";


const RateModal = ({ data: user, integrationOrder, updateOrdersData, isFlexible, text, Icon })=> {

    const alert = useAlert()
    const [openModal, setOpenModal] = useState(false)
    const [userId, setUserId] = useState('')
    const [token, setToken] = useState('')
    const [step, setStep] = useState({ step: 0, description: 'select products' })
    const [shipment, setShipment] = useState(null)
    const [isFinish, setIsFinish] = useState(false)

    const handleModal = ()=> setOpenModal(!openModal)

    const resetFields = () => {
        setUserId(user?.user)
        setToken(user?.ApiClientsV2[0]?.prod_token?.token)
        setStep({ step: 0, description: 'select products' })
        setShipment(null)
        setIsFinish(false)
    }

    const handleStep1 = () => setStep({ step: 1, description: 'rate' })
    const handleStep2 = () => setStep({ step: 2, description: 'generate' })
    const handleStep3 = () => handleFinish()

    const handleFinish = ()=> {
        setIsFinish(true)
        setTimeout(()=> {
            handleModal()
        }, 2000)    
    }

    const handleShipment = (shipment)=> {
        if (!shipment || shipment?.InfoEnvioProductos.length === 0){
            alert.info("Debes seleccionar un producto")
            return 
        }

        setShipment(shipment)
    }

    const handleTransition = (step)=> {
        switch(step){
            case 'select products':
                if (integrationOrder){
                    if (isFlexible){
                        return (
                            <FlexibleSelectedProductsByIntegration 
                                userId={userId}
                                handleShipment={handleShipment} 
                                handleStep={handleStep1}
                                integrationOrder={integrationOrder}
                            />
                        )
                    } else {
                        return (
                            <StrictSelectedProductsByIntegration 
                                userId={userId}
                                handleShipment={handleShipment} 
                                handleStep={handleStep1}
                                integrationOrder={integrationOrder}
                            />
                        )
                    }

                } else {
                    return (
                        <SelectedProducts 
                            userId={userId}
                            saveData={user} // data -> [ Ordenes de envio ]
                            handleShipment={handleShipment} 
                            handleStep={handleStep1}
                        />
                    )
                }

            case 'rate':
            case 'generate':
                return (
                    <Quotes
                        userId={userId}
                        token={token}
                        saveData={user}
                        shipment={shipment}
                        handleStep={handleStep2} 
                        handleStep3={handleStep3}
                        handleFinish={handleFinish}
                        integrationOrder={integrationOrder}
                        updateOrdersData={updateOrdersData}
                    />
                )
        }
    }

    useEffect(()=> {
        resetFields()
    }, [user, openModal])

    return (
        <>
            <Button disabled={user.ApiClientsV2.length === 0 } icon={Icon ? Icon : <ArrowRightOutlined />} onClick={handleModal}>
                {text}
            </Button>
            <Modal
                open={openModal}
                title={false}
                width={'90%'}
                styles={{ body: { minHeight: '90vh', padding: '20px'}}}
                onCancel={handleModal}
                footer={false}
            >
                <Card style={{ boxShadow:'0 0 10px 2px #0001', marginBottom: '25px' }}>
                    <Steps 
                        current={step.step}
                        status={isFinish ? "finish" : "process"}
                        items={[
                            {
                                title: integrationOrder ? 'Validar productos' : 'Seleccionar producto',
                                description: integrationOrder ? 'Verifica los artículos incluidos en el paquete' : 'Selecciona los productos que contiene el paquete',
                            },
                            {
                                title: 'Cotizar',
                                description: 'Selecciona tu paqueteria favorita',
                            },
                            {
                                title: 'Generar guia',
                                description: 'Genera tus guias a tus destinos favoritos',
                            },
                        ]}
                    />
                </Card>
                {/* <Card style={{ boxShadow:'0 0 10px 2px #0001' }}> */}
                    {handleTransition(step.description)}
                {/* </Card> */}
            </Modal>
        </>
    )
}

export default RateModal