export const quoteErrorMessage = (message) => {
    switch (message) {
        case 'Sender limit reached, verify your account.':
            return 'Tu cuenta ha alcanzado el límite de remitentes, pide la verificación en el chat.'

        case 'International shipments blocked':
            return 'Para habilitar los envios internacionales, favor de comunicarse por el chat.'

        case 'Sender Cp is blocked':
            return 'El Cp de origen ha sido bloqueado, favor de comunicarse por el chat.'

        case 'Cannot afford this shipment':
            return 'No tiene suficiente saldo para realizar el envío.'

        case 'Provider not found':
            return 'Error generando la guía, por favor contáctenos por el chat.'
        default:
            return 'Error cotizando, por favor regrese e intente de nuevo. Si el error persiste, contáctenos por el chat.'
    }
}