import AF_flag from '../assets/countriesFlags/afghanistan.svg'
import AX_flag from '../assets/countriesFlags/aland-islands.svg'
import AL_flag from '../assets/countriesFlags/albania.svg'
import DZ_flag from '../assets/countriesFlags/algeria.svg'
import AS_flag from '../assets/countriesFlags/american-samoa.svg'
import AD_flag from '../assets/countriesFlags/andorra.svg'
import AO_flag from '../assets/countriesFlags/angola.svg'
import AI_flag from '../assets/countriesFlags/anguilla.svg'
// import AQ_flag from '../assets/countriesFlags/antarctica.svg'
// import AG_flag from '../assets/countriesFlags/antigua.svg'
import AR_flag from '../assets/countriesFlags/argentina.svg'
import AM_flag from '../assets/countriesFlags/armenia.svg'
import AW_flag from '../assets/countriesFlags/aruba.svg'
import AU_flag from '../assets/countriesFlags/australia.svg'
import AT_flag from '../assets/countriesFlags/austria.svg'
import AZ_flag from '../assets/countriesFlags/azerbaijan.svg'
import BS_flag from '../assets/countriesFlags/bahamas.svg'
import BH_flag from '../assets/countriesFlags/bahrain.svg'
import BD_flag from '../assets/countriesFlags/bangladesh.svg'
import BB_flag from '../assets/countriesFlags/barbados.svg'
import BY_flag from '../assets/countriesFlags/belarus.svg'
import BE_flag from '../assets/countriesFlags/belgium.svg'
import BZ_flag from '../assets/countriesFlags/belize.svg'
import BJ_flag from '../assets/countriesFlags/benin.svg'
import BM_flag from '../assets/countriesFlags/bermuda.svg'
import BT_flag from '../assets/countriesFlags/bhutan.svg'
import BO_flag from '../assets/countriesFlags/bolivia.svg'
import BA_flag from '../assets/countriesFlags/bosnia.svg'
import BW_flag from '../assets/countriesFlags/botswana.svg'
// import BV_flag from '../assets/countriesFlags/bouvet.svg'
import BR_flag from '../assets/countriesFlags/brazil.svg'
import IO_flag from '../assets/countriesFlags/british-indian-ocean-territory.svg'
import BN_flag from '../assets/countriesFlags/brunei.svg'
import BG_flag from '../assets/countriesFlags/bulgaria.svg'
import BF_flag from '../assets/countriesFlags/burkina-faso.svg'
import BI_flag from '../assets/countriesFlags/burundi.svg'
import KH_flag from '../assets/countriesFlags/cambodia.svg'
import CM_flag from '../assets/countriesFlags/cameroon.svg'
import CA_flag from '../assets/countriesFlags/canada.svg'
import CV_flag from '../assets/countriesFlags/cape-verde.svg'
import KY_flag from '../assets/countriesFlags/cayman-islands.svg'
import CF_flag from '../assets/countriesFlags/central-african-republic.svg'
import TD_flag from '../assets/countriesFlags/chad.svg'
import CL_flag from '../assets/countriesFlags/chile.svg'
import CN_flag from '../assets/countriesFlags/china.svg'
import CX_flag from '../assets/countriesFlags/christmas-island.svg'
import CC_flag from '../assets/countriesFlags/cocos-island.svg'
import CO_flag from '../assets/countriesFlags/colombia.svg'
import KM_flag from '../assets/countriesFlags/comoros.svg'
import CG_flag from '../assets/countriesFlags/congo.svg'
import CD_flag from '../assets/countriesFlags/congo.svg'
import CK_flag from '../assets/countriesFlags/cook-islands.svg'
import CR_flag from '../assets/countriesFlags/costa-rica.svg'
// import CI_flag from '../assets/countriesFlags/cote.svg'
import HR_flag from '../assets/countriesFlags/croatia.svg'
import CU_flag from '../assets/countriesFlags/cuba.svg'
import CY_flag from '../assets/countriesFlags/cyprus.svg'
import CZ_flag from '../assets/countriesFlags/czech-republic.svg'
import DK_flag from '../assets/countriesFlags/denmark.svg'
import DJ_flag from '../assets/countriesFlags/djibouti.svg'
import DM_flag from '../assets/countriesFlags/dominica.svg'
import DO_flag from '../assets/countriesFlags/dominican-republic.svg'
import EC_flag from '../assets/countriesFlags/ecuador.svg'
import EG_flag from '../assets/countriesFlags/egypt.svg'
import SV_flag from '../assets/countriesFlags/salvador.svg'
import GQ_flag from '../assets/countriesFlags/equatorial-guinea.svg'
import ER_flag from '../assets/countriesFlags/eritrea.svg'
import EE_flag from '../assets/countriesFlags/estonia.svg'
import ET_flag from '../assets/countriesFlags/ethiopia.svg'
import FK_flag from '../assets/countriesFlags/falkland-islands.svg'
import FO_flag from '../assets/countriesFlags/faroe-islands.svg'
import FJ_flag from '../assets/countriesFlags/fiji.svg'
import FI_flag from '../assets/countriesFlags/finland.svg'
import FR_flag from '../assets/countriesFlags/france.svg'
// import GF_flag from '../assets/countriesFlags/french-guiana.svg'
import PF_flag from '../assets/countriesFlags/french-polynesia.svg'
// import TF_flag from '../assets/countriesFlags/french-souther-territories.svg'
import GA_flag from '../assets/countriesFlags/gabon.svg'
import GM_flag from '../assets/countriesFlags/gambia.svg'
import GE_flag from '../assets/countriesFlags/georgia.svg'
import DE_flag from '../assets/countriesFlags/germany.svg'
import GH_flag from '../assets/countriesFlags/ghana.svg'
import GI_flag from '../assets/countriesFlags/gibraltar.svg'
import GR_flag from '../assets/countriesFlags/greece.svg'
import GL_flag from '../assets/countriesFlags/greenland.svg'
import GD_flag from '../assets/countriesFlags/grenada.svg'
// import GP_flag from '../assets/countriesFlags/guadeloupe.svg'
import GU_flag from '../assets/countriesFlags/guam.svg'
import GT_flag from '../assets/countriesFlags/guatemala.svg'
import GG_flag from '../assets/countriesFlags/guernsey.svg'
import GN_flag from '../assets/countriesFlags/guinea.svg'
import GW_flag from '../assets/countriesFlags/guinea.svg'
import GY_flag from '../assets/countriesFlags/guyana.svg'
import HT_flag from '../assets/countriesFlags/haiti.svg'
// import HM_flag from '../assets/countriesFlags/heard.svg'
// import VA_flag from '../assets/countriesFlags/holy.svg'
import HN_flag from '../assets/countriesFlags/honduras.svg'
import HK_flag from '../assets/countriesFlags/hong-kong.svg'
import HU_flag from '../assets/countriesFlags/hungary.svg'
import IS_flag from '../assets/countriesFlags/iceland.svg'
import IN_flag from '../assets/countriesFlags/india.svg'
import ID_flag from '../assets/countriesFlags/indonesia.svg'
import IR_flag from '../assets/countriesFlags/iran.svg'
import IQ_flag from '../assets/countriesFlags/iraq.svg'
import IE_flag from '../assets/countriesFlags/ireland.svg'
import IM_flag from '../assets/countriesFlags/isle-of-man.svg'
import IL_flag from '../assets/countriesFlags/israel.svg'
import IT_flag from '../assets/countriesFlags/italy.svg'
import JM_flag from '../assets/countriesFlags/jamaica.svg'
import JP_flag from '../assets/countriesFlags/japan.svg'
import JE_flag from '../assets/countriesFlags/jersey.svg'
import JO_flag from '../assets/countriesFlags/jordan.svg'
import KZ_flag from '../assets/countriesFlags/kazakhstan.svg'
import KE_flag from '../assets/countriesFlags/kenya.svg'
import KI_flag from '../assets/countriesFlags/kiribati.svg'
import KP_flag from '../assets/countriesFlags/north-korea.svg'
import KR_flag from '../assets/countriesFlags/south-korea.svg'
import KW_flag from '../assets/countriesFlags/kuwait.svg'
import KG_flag from '../assets/countriesFlags/kyrgyzstan.svg'
import LA_flag from '../assets/countriesFlags/laos.svg'
import LV_flag from '../assets/countriesFlags/latvia.svg'
import LB_flag from '../assets/countriesFlags/lebanon.svg'
import LS_flag from '../assets/countriesFlags/lesotho.svg'
import LR_flag from '../assets/countriesFlags/liberia.svg'
import LY_flag from '../assets/countriesFlags/libya.svg'
import LI_flag from '../assets/countriesFlags/liechtenstein.svg'
import LT_flag from '../assets/countriesFlags/lithuania.svg'
import LU_flag from '../assets/countriesFlags/luxembourg.svg'
import MO_flag from '../assets/countriesFlags/macao.svg'
import MK_flag from '../assets/countriesFlags/republic-of-macedonia.svg'
import MG_flag from '../assets/countriesFlags/madagascar.svg'
import MW_flag from '../assets/countriesFlags/malawi.svg'
import MY_flag from '../assets/countriesFlags/malaysia.svg'
import MV_flag from '../assets/countriesFlags/maldives.svg'
import ML_flag from '../assets/countriesFlags/mali.svg'
import MT_flag from '../assets/countriesFlags/malta.svg'
import MH_flag from '../assets/countriesFlags/marshall-island.svg'
import MQ_flag from '../assets/countriesFlags/martinique.svg'
import MR_flag from '../assets/countriesFlags/mauritania.svg'
import MU_flag from '../assets/countriesFlags/mauritius.svg'
// import YT_flag from '../assets/countriesFlags/mayotte.svg'
import MX_flag from '../assets/countriesFlags/mexico.svg'
import FM_flag from '../assets/countriesFlags/micronesia.svg'
import MD_flag from '../assets/countriesFlags/moldova.svg'
import MC_flag from '../assets/countriesFlags/monaco.svg'
import MN_flag from '../assets/countriesFlags/mongolia.svg'
import MS_flag from '../assets/countriesFlags/montserrat.svg'
import MA_flag from '../assets/countriesFlags/morocco.svg'
import MZ_flag from '../assets/countriesFlags/mozambique.svg'
import MM_flag from '../assets/countriesFlags/myanmar.svg'
import NA_flag from '../assets/countriesFlags/namibia.svg'
import NR_flag from '../assets/countriesFlags/nauru.svg'
import NP_flag from '../assets/countriesFlags/nepal.svg'
import NL_flag from '../assets/countriesFlags/netherlands.svg'
import AN_flag from '../assets/countriesFlags/netherlands.svg'
// import NC_flag from '../assets/countriesFlags/new.svg'
import NZ_flag from '../assets/countriesFlags/new-zealand.svg'
import NI_flag from '../assets/countriesFlags/nicaragua.svg'
import NE_flag from '../assets/countriesFlags/niger.svg'
import NG_flag from '../assets/countriesFlags/nigeria.svg'
import NU_flag from '../assets/countriesFlags/niue.svg'
import NF_flag from '../assets/countriesFlags/norfolk-island.svg'
import MP_flag from '../assets/countriesFlags/northern-marianas-islands.svg'
import NO_flag from '../assets/countriesFlags/norway.svg'
import OM_flag from '../assets/countriesFlags/oman.svg'
import PK_flag from '../assets/countriesFlags/pakistan.svg'
import PW_flag from '../assets/countriesFlags/palau.svg'
import PS_flag from '../assets/countriesFlags/palestine.svg'
import PA_flag from '../assets/countriesFlags/panama.svg'
import PG_flag from '../assets/countriesFlags/papua-new-guinea.svg'
import PY_flag from '../assets/countriesFlags/paraguay.svg'
import PE_flag from '../assets/countriesFlags/peru.svg'
import PH_flag from '../assets/countriesFlags/philippines.svg'
import PN_flag from '../assets/countriesFlags/pitcairn-islands.svg'
import PL_flag from '../assets/countriesFlags/republic-of-poland.svg'
import PT_flag from '../assets/countriesFlags/portugal.svg'
import PR_flag from '../assets/countriesFlags/puerto-rico.svg'
import QA_flag from '../assets/countriesFlags/qatar.svg'
// import RE_flag from '../assets/countriesFlags/reunion.svg'
import RO_flag from '../assets/countriesFlags/romania.svg'
import RU_flag from '../assets/countriesFlags/russia.svg'
import RW_flag from '../assets/countriesFlags/rwanda.svg'

// import SH_flag from '../assets/countriesFlags/saint.svg'
import KN_flag from '../assets/countriesFlags/saint-kitts-and-nevis.svg'
import LC_flag from '../assets/countriesFlags/st-lucia.svg'
// import PM_flag from '../assets/countriesFlags/saint.svg'
import VC_flag from '../assets/countriesFlags/st-vincent-and-the-grenadines.svg'
import WS_flag from '../assets/countriesFlags/samoa.svg'
import SM_flag from '../assets/countriesFlags/san-marino.svg'
import ST_flag from '../assets/countriesFlags/sao-tome-and-principe.svg'
import SA_flag from '../assets/countriesFlags/saudi-arabia.svg'
import SN_flag from '../assets/countriesFlags/senegal.svg'
import CS_flag from '../assets/countriesFlags/serbia.svg'
import SC_flag from '../assets/countriesFlags/seychelles.svg'
import SL_flag from '../assets/countriesFlags/sierra-leone.svg'
import SG_flag from '../assets/countriesFlags/singapore.svg'
import SK_flag from '../assets/countriesFlags/slovakia.svg'
import SI_flag from '../assets/countriesFlags/slovenia.svg'
import SB_flag from '../assets/countriesFlags/solomon-islands.svg'
import SO_flag from '../assets/countriesFlags/somalia.svg'
import ZA_flag from '../assets/countriesFlags/south-africa.svg'
// import GS_flag from '../assets/countriesFlags/south.svg'
import ES_flag from '../assets/countriesFlags/spain.svg'
import LK_flag from '../assets/countriesFlags/sri-lanka.svg'
import SD_flag from '../assets/countriesFlags/sudan.svg'
import SR_flag from '../assets/countriesFlags/suriname.svg'
// import SJ_flag from '../assets/countriesFlags/svalbard.svg'
import SZ_flag from '../assets/countriesFlags/swaziland.svg'
import SE_flag from '../assets/countriesFlags/sweden.svg'
import CH_flag from '../assets/countriesFlags/switzerland.svg'
import SY_flag from '../assets/countriesFlags/syria.svg'
import TW_flag from '../assets/countriesFlags/taiwan.svg'
import TJ_flag from '../assets/countriesFlags/tajikistan.svg'
import TZ_flag from '../assets/countriesFlags/tanzania.svg'
import TH_flag from '../assets/countriesFlags/thailand.svg'
// import TL_flag from '../assets/countriesFlags/timor.svg'
import TG_flag from '../assets/countriesFlags/togo.svg'
import TK_flag from '../assets/countriesFlags/tokelau.svg'
import TO_flag from '../assets/countriesFlags/tonga.svg'
import TT_flag from '../assets/countriesFlags/trinidad-and-tobago.svg'
import TN_flag from '../assets/countriesFlags/tunisia.svg'
import TR_flag from '../assets/countriesFlags/turkey.svg'
import TM_flag from '../assets/countriesFlags/turkmenistan.svg'
import TC_flag from '../assets/countriesFlags/turks-and-caicos.svg'
import TV_flag from '../assets/countriesFlags/tuvalu.svg'
import UG_flag from '../assets/countriesFlags/uganda.svg'
import UA_flag from '../assets/countriesFlags/ukraine.svg'
import AE_flag from '../assets/countriesFlags/united-arab-emirates.svg'
import GB_flag from '../assets/countriesFlags/united-kingdom.svg'
import US_flag from '../assets/countriesFlags/united-states-of-america.svg'
// import UM_flag from '../assets/countriesFlags/united-states-minor-outlying-islands.svg'
import UY_flag from '../assets/countriesFlags/uruguay.svg'
import UZ_flag from '../assets/countriesFlags/uzbekistn.svg'
import VU_flag from '../assets/countriesFlags/vanuatu.svg'
import VE_flag from '../assets/countriesFlags/venezuela.svg'
import VN_flag from '../assets/countriesFlags/vietnam.svg'
import VI_flag from '../assets/countriesFlags/virgin-islands.svg'
import WF_flag from '../assets/countriesFlags/wales.svg'
import EH_flag from '../assets/countriesFlags/western-sahara.svg'
import YE_flag from '../assets/countriesFlags/yemen.svg'
import ZM_flag from '../assets/countriesFlags/zambia.svg'
import ZW_flag from '../assets/countriesFlags/zimbabwe.svg'

export const allCountries = [
  { label: 'Afghanistan', value: 'AF', svgFlag: AF_flag, numExt: '+93' },
  { label: 'Åland Islands', value: 'AX', svgFlag: AX_flag, numExt: '+358' },
  { label: 'Albania', value: 'AL', svgFlag: AL_flag, numExt: '+355' },
  { label: 'Argelia', value: 'DZ', svgFlag: DZ_flag, numExt: '+213' },
  { label: 'American Samoa', value: 'AS', svgFlag: AS_flag, numExt: '+1684' },
  { label: 'Andorra', value: 'AD', svgFlag: AD_flag, numExt: '+376' },
  { label: 'Angola', value: 'AO', svgFlag: AO_flag, numExt: '+244' },
  { label: 'Anguilla', value: 'AI', svgFlag: AI_flag, numExt: '+1264' },
  // { label: 'Antarctica', value: 'AQ', svgFlag: AQ_flag, numExt: "" },
  // { label: 'Antigua and Barbuda', value: 'AG', svgFlag: AG_flag, numExt: "" },
  { label: 'Argentina', value: 'AR', svgFlag: AR_flag, numExt: '+54' },
  { label: 'Armenia', value: 'AM', svgFlag: AM_flag, numExt: '+374' },
  { label: 'Aruba', value: 'AW', svgFlag: AW_flag, numExt: '+297' },
  { label: 'Australia', value: 'AU', svgFlag: AU_flag, numExt: '+61' },
  { label: 'Austria', value: 'AT', svgFlag: AT_flag, numExt: '+43' },
  { label: 'Azerbaijan', value: 'AZ', svgFlag: AZ_flag, numExt: '+994' },
  { label: 'Bahamas', value: 'BS', svgFlag: BS_flag, numExt: '+1242' },
  { label: 'Bahrain', value: 'BH', svgFlag: BH_flag, numExt: '+973' },
  { label: 'Bangladesh', value: 'BD', svgFlag: BD_flag, numExt: '+880' },
  { label: 'Barbados', value: 'BB', svgFlag: BB_flag, numExt: '+1246' },
  { label: 'Belarus', value: 'BY', svgFlag: BY_flag, numExt: '+375' },
  { label: 'Belgium', value: 'BE', svgFlag: BE_flag, numExt: '+32' },
  { label: 'Belize', value: 'BZ', svgFlag: BZ_flag, numExt: '+501' },
  { label: 'Benin', value: 'BJ', svgFlag: BJ_flag, numExt: '+229' },
  { label: 'Bermuda', value: 'BM', svgFlag: BM_flag, numExt: '+1441' },
  { label: 'Bhutan', value: 'BT', svgFlag: BT_flag, numExt: '+975' },
  { label: 'Bolivia', value: 'BO', svgFlag: BO_flag, numExt: '+591' },
  { label: 'Bosnia and Herzegovina', value: 'BA', svgFlag: BA_flag, numExt: '+387' },
  { label: 'Botswana', value: 'BW', svgFlag: BW_flag, numExt: '+267' },
  // { label: 'Bouvet Island', value: 'BV', svgFlag: BV_flag, numExt: "" },
  { label: 'Brazil', value: 'BR', svgFlag: BR_flag, numExt: '+55' },
  { label: 'British Indian Ocean Territory', value: 'IO', svgFlag: IO_flag, numExt: '+246' },
  { label: 'Brunei Darussalam', value: 'BN', svgFlag: BN_flag, numExt: '+673' },
  { label: 'Bulgaria', value: 'BG', svgFlag: BG_flag, numExt: '+359' },
  { label: 'Burkina Faso', value: 'BF', svgFlag: BF_flag, numExt: '+226' },
  { label: 'Burundi', value: 'BI', svgFlag: BI_flag, numExt: '+257' },
  { label: 'Cambodia', value: 'KH', svgFlag: KH_flag, numExt: '+855' },
  { label: 'Cameroon', value: 'CM', svgFlag: CM_flag, numExt: '+237' },
  { label: 'Canada', value: 'CA', svgFlag: CA_flag, numExt: '+1' },
  { label: 'Cape Verde', value: 'CV', svgFlag: CV_flag, numExt: '+238' },
  { label: 'Cayman Islands', value: 'KY', svgFlag: KY_flag, numExt: '+345' },
  { label: 'Central African Republic', value: 'CF', svgFlag: CF_flag, numExt: '+236' },
  { label: 'Chad', value: 'TD', svgFlag: TD_flag, numExt: '+235' },
  { label: 'Chile', value: 'CL', svgFlag: CL_flag, numExt: '+56' },
  { label: 'China', value: 'CN', svgFlag: CN_flag, numExt: '+86' },
  { label: 'Christmas Island', value: 'CX', svgFlag: CX_flag, numExt: '+61' },
  { label: 'Cocos (Keeling) Islands', value: 'CC', svgFlag: CC_flag, numExt: '+61' },
  { label: 'Colombia', value: 'CO', svgFlag: CO_flag, numExt: '+57' },
  { label: 'Comoros', value: 'KM', svgFlag: KM_flag, numExt: '+269' },
  { label: 'Congo', value: 'CG', svgFlag: CG_flag, numExt: '+242' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD', svgFlag: CD_flag, numExt: '+243' },
  { label: 'Cook Islands', value: 'CK', svgFlag: CK_flag, numExt: '+682' },
  { label: 'Costa Rica', value: 'CR', svgFlag: CR_flag, numExt: '+506' },
  // { label: "Cote D'Ivoire", value: 'CI', svgFlag: CI_flag, numExt: "" },
  { label: 'Croatia', value: 'HR', svgFlag: HR_flag, numExt: '+385' },
  { label: 'Cuba', value: 'CU', svgFlag: CU_flag, numExt: '+53' },
  { label: 'Cyprus', value: 'CY', svgFlag: CY_flag, numExt: '+537' },
  { label: 'Czech Republic', value: 'CZ', svgFlag: CZ_flag, numExt: '+420' },
  { label: 'Denmark', value: 'DK', svgFlag: DK_flag, numExt: '+45' },
  { label: 'Djibouti', value: 'DJ', svgFlag: DJ_flag, numExt: '+253' },
  { label: 'Dominica', value: 'DM', svgFlag: DM_flag, numExt: '+1767' },
  { label: 'Dominican Republic', value: 'DO', svgFlag: DO_flag, numExt: '+1849' },
  { label: 'Ecuador', value: 'EC', svgFlag: EC_flag, numExt: '+593' },
  { label: 'Egypt', value: 'EG', svgFlag: EG_flag, numExt: '+20' },
  { label: 'El Salvador', value: 'SV', svgFlag: SV_flag, numExt: '+503' },
  { label: 'Equatorial Guinea', value: 'GQ', svgFlag: GQ_flag, numExt: '+240' },
  { label: 'Eritrea', value: 'ER', svgFlag: ER_flag, numExt: '+291' },
  { label: 'Estonia', value: 'EE', svgFlag: EE_flag, numExt: '+372' },
  { label: 'Ethiopia', value: 'ET', svgFlag: ET_flag, numExt: '+251' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK', svgFlag: FK_flag, numExt: '+500' },
  { label: 'Faroe Islands', value: 'FO', svgFlag: FO_flag, numExt: '+298' },
  { label: 'Fiji', value: 'FJ', svgFlag: FJ_flag, numExt: '+679' },
  { label: 'Finland', value: 'FI', svgFlag: FI_flag, numExt: '+358' },
  { label: 'France', value: 'FR', svgFlag: FR_flag, numExt: '+33' },
  // { label: 'French Guiana', value: 'GF', svgFlag: GF_flag, numExt: "" },
  { label: 'French Polynesia', value: 'PF', svgFlag: PF_flag, numExt: '+689' },
  // { label: 'French Southern Territories', value: 'TF', svgFlag: TF_flag, numExt: "" },
  { label: 'Gabon', value: 'GA', svgFlag: GA_flag, numExt: '+241' },
  { label: 'Gambia', value: 'GM', svgFlag: GM_flag, numExt: '+220' },
  { label: 'Georgia', value: 'GE', svgFlag: GE_flag, numExt: '+995' },
  { label: 'Germany', value: 'DE', svgFlag: DE_flag, numExt: '+49' },
  { label: 'Ghana', value: 'GH', svgFlag: GH_flag, numExt: '+233' },
  { label: 'Gibraltar', value: 'GI', svgFlag: GI_flag, numExt: '+350' },
  { label: 'Greece', value: 'GR', svgFlag: GR_flag, numExt: '+30' },
  { label: 'Greenland', value: 'GL', svgFlag: GL_flag, numExt: '+299' },
  { label: 'Grenada', value: 'GD', svgFlag: GD_flag, numExt: '+1473' },
  // { label: 'Guadeloupe', value: 'GP', svgFlag: GP_flag, numExt: "" },
  { label: 'Guam', value: 'GU', svgFlag: GU_flag, numExt: '+1671' },
  { label: 'Guatemala', value: 'GT', svgFlag: GT_flag, numExt: '+502' },
  { label: 'Guernsey', value: 'GG', svgFlag: GG_flag, numExt: '+44' },
  { label: 'Guinea', value: 'GN', svgFlag: GN_flag, numExt: '+224' },
  { label: 'Guinea-Bissau', value: 'GW', svgFlag: GW_flag, numExt: '+245' },
  { label: 'Guyana', value: 'GY', svgFlag: GY_flag, numExt: '+595' },
  { label: 'Haiti', value: 'HT', svgFlag: HT_flag, numExt: '+509' },
  // { label: 'Heard Island and Mcdonald Islands', value: 'HM', svgFlag: HM_flag, numExt: "" },
  // { label: 'Holy See (Vatican City State)', value: 'VA', svgFlag: VA_flag, numExt: "" },
  { label: 'Honduras', value: 'HN', svgFlag: HN_flag, numExt: '+504' },
  { label: 'Hong Kong', value: 'HK', svgFlag: HK_flag, numExt: '+852' },
  { label: 'Hungary', value: 'HU', svgFlag: HU_flag, numExt: '+36' },
  { label: 'Iceland', value: 'IS', svgFlag: IS_flag, numExt: '+354' },
  { label: 'India', value: 'IN', svgFlag: IN_flag, numExt: '+91' },
  { label: 'Indonesia', value: 'ID', svgFlag: ID_flag, numExt: '+62' },
  { label: 'Iran, Islamic Republic Of', value: 'IR', svgFlag: IR_flag, numExt: '+98' },
  { label: 'Iraq', value: 'IQ', svgFlag: IQ_flag, numExt: '+964' },
  { label: 'Ireland', value: 'IE', svgFlag: IE_flag, numExt: '+353' },
  { label: 'Isle of Man', value: 'IM', svgFlag: IM_flag, numExt: '+44' },
  { label: 'Israel', value: 'IL', svgFlag: IL_flag, numExt: '+972' },
  { label: 'Italy', value: 'IT', svgFlag: IT_flag, numExt: '+39' },
  { label: 'Jamaica', value: 'JM', svgFlag: JM_flag, numExt: '+1876' },
  { label: 'Japan', value: 'JP', svgFlag: JP_flag, numExt: '+81' },
  { label: 'Jersey', value: 'JE', svgFlag: JE_flag, numExt: '+44' },
  { label: 'Jordan', value: 'JO', svgFlag: JO_flag, numExt: '+962' },
  { label: 'Kazakhstan', value: 'KZ', svgFlag: KZ_flag, numExt: '+7' },
  { label: 'Kenya', value: 'KE', svgFlag: KE_flag, numExt: '+254' },
  { label: 'Kiribati', value: 'KI', svgFlag: KI_flag, numExt: '+686' },
  {
    label: "Korea, Democratic People'S Republic of",
    value: 'KP',
    svgFlag: KP_flag,
    numExt: '+850'
  },
  { label: 'Korea, Republic of', value: 'KR', svgFlag: KR_flag, numExt: '+82' },
  { label: 'Kuwait', value: 'KW', svgFlag: KW_flag, numExt: '+965' },
  { label: 'Kyrgyzstan', value: 'KG', svgFlag: KG_flag, numExt: '+996' },
  { label: "Lao People'S Democratic Republic", value: 'LA', svgFlag: LA_flag, numExt: '+856' },
  { label: 'Latvia', value: 'LV', svgFlag: LV_flag, numExt: '+371' },
  { label: 'Lebanon', value: 'LB', svgFlag: LB_flag, numExt: '+961' },
  { label: 'Lesotho', value: 'LS', svgFlag: LS_flag, numExt: '+266' },
  { label: 'Liberia', value: 'LR', svgFlag: LR_flag, numExt: '+231' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY', svgFlag: LY_flag, numExt: '+218' },
  { label: 'Liechtenstein', value: 'LI', svgFlag: LI_flag, numExt: '+423' },
  { label: 'Lithuania', value: 'LT', svgFlag: LT_flag, numExt: '+370' },
  { label: 'Luxembourg', value: 'LU', svgFlag: LU_flag, numExt: '+352' },
  { label: 'Macao', value: 'MO', svgFlag: MO_flag, numExt: '+853' },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK',
    svgFlag: MK_flag,
    numExt: '+389'
  },
  { label: 'Madagascar', value: 'MG', svgFlag: MG_flag, numExt: '+261' },
  { label: 'Malawi', value: 'MW', svgFlag: MW_flag, numExt: '+265' },
  { label: 'Malaysia', value: 'MY', svgFlag: MY_flag, numExt: '+60' },
  { label: 'Maldives', value: 'MV', svgFlag: MV_flag, numExt: '+960' },
  { label: 'Mali', value: 'ML', svgFlag: ML_flag, numExt: '+223' },
  { label: 'Malta', value: 'MT', svgFlag: MT_flag, numExt: '+356' },
  { label: 'Marshall Islands', value: 'MH', svgFlag: MH_flag, numExt: '+692' },
  { label: 'Martinique', value: 'MQ', svgFlag: MQ_flag, numExt: '+596' },
  { label: 'Mauritania', value: 'MR', svgFlag: MR_flag, numExt: '+222' },
  { label: 'Mauritius', value: 'MU', svgFlag: MU_flag, numExt: '+230' },
  // { label: 'Mayotte', value: 'YT', svgFlag: YT_flag, numExt: "" },
  { label: 'Mexico', value: 'MX', svgFlag: MX_flag, numExt: '+52' },
  { label: 'Micronesia, Federated States of', value: 'FM', svgFlag: FM_flag, numExt: '+691' },
  { label: 'Moldova, Republic of', value: 'MD', svgFlag: MD_flag, numExt: '+373' },
  { label: 'Monaco', value: 'MC', svgFlag: MC_flag, numExt: '+377' },
  { label: 'Mongolia', value: 'MN', svgFlag: MN_flag, numExt: '+976' },
  { label: 'Montserrat', value: 'MS', svgFlag: MS_flag, numExt: '+1664' },
  { label: 'Morocco', value: 'MA', svgFlag: MA_flag, numExt: '+212' },
  { label: 'Mozambique', value: 'MZ', svgFlag: MZ_flag, numExt: '+258' },
  { label: 'Myanmar', value: 'MM', svgFlag: MM_flag, numExt: '+95' },
  { label: 'Namibia', value: 'NA', svgFlag: NA_flag, numExt: '+264' },
  { label: 'Nauru', value: 'NR', svgFlag: NR_flag, numExt: '+674' },
  { label: 'Nepal', value: 'NP', svgFlag: NP_flag, numExt: '+977' },
  { label: 'Netherlands', value: 'NL', svgFlag: NL_flag, numExt: '+31' },
  { label: 'Netherlands Antilles', value: 'AN', svgFlag: AN_flag, numExt: '+599' },
  // { label: 'New Caledonia', value: 'NC', svgFlag: NC_flag, numExt: "" },
  { label: 'New Zealand', value: 'NZ', svgFlag: NZ_flag, numExt: '+64' },
  { label: 'Nicaragua', value: 'NI', svgFlag: NI_flag, numExt: '+505' },
  { label: 'Niger', value: 'NE', svgFlag: NE_flag, numExt: '+227' },
  { label: 'Nigeria', value: 'NG', svgFlag: NG_flag, numExt: '+234' },
  { label: 'Niue', value: 'NU', svgFlag: NU_flag, numExt: '+683' },
  { label: 'Norfolk Island', value: 'NF', svgFlag: NF_flag, numExt: '+672' },
  { label: 'Northern Mariana Islands', value: 'MP', svgFlag: MP_flag, numExt: '+1670' },
  { label: 'Norway', value: 'NO', svgFlag: NO_flag, numExt: '+47' },
  { label: 'Oman', value: 'OM', svgFlag: OM_flag, numExt: '+968' },
  { label: 'Pakistan', value: 'PK', svgFlag: PK_flag, numExt: '+92' },
  { label: 'Palau', value: 'PW', svgFlag: PW_flag, numExt: '+680' },
  { label: 'Palestinian Territory, Occupied', value: 'PS', svgFlag: PS_flag, numExt: '+970' },
  { label: 'Panama', value: 'PA', svgFlag: PA_flag, numExt: '+507' },
  { label: 'Papua New Guinea', value: 'PG', svgFlag: PG_flag, numExt: '+675' },
  { label: 'Paraguay', value: 'PY', svgFlag: PY_flag, numExt: '+595' },
  { label: 'Peru', value: 'PE', svgFlag: PE_flag, numExt: '+51' },
  { label: 'Philippines', value: 'PH', svgFlag: PH_flag, numExt: '+63' },
  { label: 'Pitcairn', value: 'PN', svgFlag: PN_flag, numExt: '+872' },
  { label: 'Poland', value: 'PL', svgFlag: PL_flag, numExt: '+48' },
  { label: 'Portugal', value: 'PT', svgFlag: PT_flag, numExt: '+351' },
  { label: 'Puerto Rico', value: 'PR', svgFlag: PR_flag, numExt: '+1939' },
  { label: 'Qatar', value: 'QA', svgFlag: QA_flag, numExt: '+974' },
  // { label: 'Reunion', value: 'RE', svgFlag: RE_flag, numExt: "" },
  { label: 'Romania', value: 'RO', svgFlag: RO_flag, numExt: '+40' },
  { label: 'Russian Federation', value: 'RU', svgFlag: RU_flag, numExt: '+7' },
  { label: 'Rwanda', value: 'RW', svgFlag: RW_flag, numExt: '+250' },
  // { label: 'Saint Helena', value: 'SH', svgFlag: SH_flag, numExt: "" },
  { label: 'Saint Kitts and Nevis', value: 'KN', svgFlag: KN_flag, numExt: '+1869' },
  { label: 'Saint Lucia', value: 'LC', svgFlag: LC_flag, numExt: '+1758' },
  // { label: 'Saint Pierre and Miquelon', value: 'PM', svgFlag: PM_flag, numExt: "" },
  { label: 'Saint Vincent and the Grenadines', value: 'VC', svgFlag: VC_flag, numExt: '+1784' },
  { label: 'Samoa', value: 'WS', svgFlag: WS_flag, numExt: '+685' },
  { label: 'San Marino', value: 'SM', svgFlag: SM_flag, numExt: '+378' },
  { label: 'Sao Tome and Principe', value: 'ST', svgFlag: ST_flag, numExt: '+239' },
  { label: 'Saudi Arabia', value: 'SA', svgFlag: SA_flag, numExt: '+966' },
  { label: 'Senegal', value: 'SN', svgFlag: SN_flag, numExt: '+221' },
  { label: 'Serbia and Montenegro', value: 'CS', svgFlag: CS_flag, numExt: '+381' },
  { label: 'Seychelles', value: 'SC', svgFlag: SC_flag, numExt: '+248' },
  { label: 'Sierra Leone', value: 'SL', svgFlag: SL_flag, numExt: '+232' },
  { label: 'Singapore', value: 'SG', svgFlag: SG_flag, numExt: '+65' },
  { label: 'Slovakia', value: 'SK', svgFlag: SK_flag, numExt: '+421' },
  { label: 'Slovenia', value: 'SI', svgFlag: SI_flag, numExt: '+386' },
  { label: 'Solomon Islands', value: 'SB', svgFlag: SB_flag, numExt: '+677' },
  { label: 'Somalia', value: 'SO', svgFlag: SO_flag, numExt: '+252' },
  { label: 'South Africa', value: 'ZA', svgFlag: ZA_flag, numExt: '+27' },
  // { label: 'South Georgia and the South Sandwich Islands', value: 'GS', svgFlag: GS_flag, numExt: "" },
  { label: 'Spain', value: 'ES', svgFlag: ES_flag, numExt: '+34' },
  { label: 'Sri Lanka', value: 'LK', svgFlag: LK_flag, numExt: '+94' },
  { label: 'Sudan', value: 'SD', svgFlag: SD_flag, numExt: '+249' },
  { label: 'Suriname', value: 'SR', svgFlag: SR_flag, numExt: '+597' },
  // { label: 'Svalbard and Jan Mayen', value: 'SJ', svgFlag: SJ_flag, numExt: "" },
  { label: 'Swaziland', value: 'SZ', svgFlag: SZ_flag, numExt: '+268' },
  { label: 'Sweden', value: 'SE', svgFlag: SE_flag, numExt: '+46' },
  { label: 'Switzerland', value: 'CH', svgFlag: CH_flag, numExt: '+41' },
  { label: 'Syrian Arab Republic', value: 'SY', svgFlag: SY_flag, numExt: '+963' },
  { label: 'Taiwan, Province of China', value: 'TW', svgFlag: TW_flag, numExt: '+886' },
  { label: 'Tajikistan', value: 'TJ', svgFlag: TJ_flag, numExt: '+992' },
  { label: 'Tanzania, United Republic of', value: 'TZ', svgFlag: TZ_flag, numExt: '+255' },
  { label: 'Thailand', value: 'TH', svgFlag: TH_flag, numExt: '+66' },
  // { label: 'Timor-Leste', value: 'TL', svgFlag: TL_flag, numExt: "" },
  { label: 'Togo', value: 'TG', svgFlag: TG_flag, numExt: '+228' },
  { label: 'Tokelau', value: 'TK', svgFlag: TK_flag, numExt: '+690' },
  { label: 'Tonga', value: 'TO', svgFlag: TO_flag, numExt: '+676' },
  { label: 'Trinidad and Tobago', value: 'TT', svgFlag: TT_flag, numExt: '+1868' },
  { label: 'Tunisia', value: 'TN', svgFlag: TN_flag, numExt: '+216' },
  { label: 'Turkey', value: 'TR', svgFlag: TR_flag, numExt: '+90' },
  { label: 'Turkmenistan', value: 'TM', svgFlag: TM_flag, numExt: '+993' },
  { label: 'Turks and Caicos Islands', value: 'TC', svgFlag: TC_flag, numExt: '+1649' },
  { label: 'Tuvalu', value: 'TV', svgFlag: TV_flag, numExt: '+688' },
  { label: 'Uganda', value: 'UG', svgFlag: UG_flag, numExt: '+256' },
  { label: 'Ukraine', value: 'UA', svgFlag: UA_flag, numExt: '+380' },
  { label: 'United Arab Emirates', value: 'AE', svgFlag: AE_flag, numExt: '+971' },
  { label: 'United Kingdom', value: 'GB', svgFlag: GB_flag, numExt: '+44' },
  { label: 'United States', value: 'US', svgFlag: US_flag, numExt: '+1' },
  // { label: 'United States Minor Outlying Islands', value: 'UM', svgFlag: UM_flag, numExt: "" },
  { label: 'Uruguay', value: 'UY', svgFlag: UY_flag, numExt: '+598' },
  { label: 'Uzbekistan', value: 'UZ', svgFlag: UZ_flag, numExt: '+998' },
  { label: 'Vanuatu', value: 'VU', svgFlag: VU_flag, numExt: '+678' },
  { label: 'Venezuela', value: 'VE', svgFlag: VE_flag, numExt: '+58' },
  { label: 'Vietnam', value: 'VN', svgFlag: VN_flag, numExt: '+84' },
  // { label: 'Virgin Islands, British', value: 'VG', svgFlag: VG_flag, numExt: "" },
  { label: 'Virgin Islands, U.S.', value: 'VI', svgFlag: VI_flag, numExt: '+1284' },
  { label: 'Wallis and Futuna', value: 'WF', svgFlag: WF_flag, numExt: '+681' },
  { label: 'Western Sahara', value: 'EH', svgFlag: EH_flag, numExt: '+212' },
  { label: 'Yemen', value: 'YE', svgFlag: YE_flag, numExt: '+967' },
  { label: 'Zambia', value: 'ZM', svgFlag: ZM_flag, numExt: '+260' },
  { label: 'Zimbabwe', value: 'ZW', svgFlag: ZW_flag, numExt: '+263' }
]
