import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    CreateOutboundShipmentV2
} from "../../api/OutboundShipmentsAPI";
import {useAuth0} from "../../hooks/react-auth0-spa";
import {GetAllProducts} from "../../api/ProductAPI";
import {useAlert} from "react-alert";
import {Button, Card, Col, Form, Input, Row, Select, Table} from "antd";
import {estadosMexico} from "../quoter/steps/FillGuideInfoForm";
import {ArrowLeftOutlined} from "@ant-design/icons";
// import axios from "axios";

// const initialState = {
//     postalCode:'',
//     city: '',
//     state: '',
//     district: ''
// }

const CreateOutboundShipments = (props) => {
    const History = useHistory();
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const [destinationData, setDestinationData] = useState(initialState)
    // const [optionLocationData, setOptionLocationData] = useState([])

    const [form] = Form.useForm();

    const [selectedProducts, setSelectedProducts] = useState([]);

    const {getTokenSilently} = useAuth0();
    const alert = useAlert()

    const getUserProducts = async () => {
        setIsLoading(true)
        const token = await getTokenSilently();
        try {
            if (token) {
                const response = await GetAllProducts(token)
                    console.log('response ---->',response)
                if (response) {
                    const formattedProducts = response.map(product => ({
                        label: `${product.SKUCliente} - ${product.Nombre}`,
                        value: `${product.SKUCliente} - ${product.Nombre} - ${product._id}`,
                    }))
                    setProducts(formattedProducts)
                    // console.log('Response formattedProducts ------>', formattedProducts)
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log('Error at getUserProducts', error)
        }
    }

    const onSubmitProducts = (e) => {
        setSelectedProducts([
            ...selectedProducts,
            {
                SKU: e.Producto.split('-')[0].trim(),
                Producto: e.Producto.split('-')[1].trim(),
                ProductId: e.Producto.split('-')[2].trim(),
                Cantidad: e.Cantidad
            }
        ])
        form.resetFields()
    }

    const createOutboundShipment = async(e) => {
        delete e.Producto
        delete e.Cantidad

        const token = await getTokenSilently();

        if(selectedProducts.length === 0){
            return alert.error("Selecciona un producto antes de generar una orden.")
        }
        const request = {
            destination: e,
            products: selectedProducts,
        }
        const res = await CreateOutboundShipmentV2(token, request)
        if(res.status !== 200 || !res.status){
            return alert.error("Error al generar la orden")
        }
        alert.success("Tu envio al almacén fue registrado con éxito");
        await getUserProducts()
        History.push("/outboundshipments");
    }

    // const getLocationData = async(pc) => {
    //     try {
    //         const response = await axios.get(`https://geocodes.envia.com/zipcode/mx/${pc}`)
    //         if(response.data.length === 0){
    //             return
    //         }
    //         const { zip_code, state, locality, suburbs, regions } = response.data[0]
    //         const options = []
    //
    //         if(suburbs.length === 0){
    //             options.push({
    //                 label: `${zip_code} ~ ${regions.region_2} (${regions.region_1})`,
    //                 value: {
    //                     zip_code: zip_code,
    //                     district: regions.region_2,
    //                     city:
    //                 },
    //             })
    //         } else {
    //             suburbs.forEach((suburb) => {
    //                 options.push({
    //                     label: `${zip_code} ~ ${suburb} ~ ${regions.region_2} (${regions.region_1})`,
    //                     value: `${zip_code} ~ ${suburb} ~ ${regions.region_2} (${regions.region_1})`,
    //                 })
    //             })
    //         }
    //
    //         console.log('---------------------- OPCIONES ------------------>',options)
    //         setOptionLocationData(options)
    //
    //         console.log('response ==========>',response.data[0])
    //     } catch (error) {
    //         console.error(error)
    //         return error.response.data
    //     }
    // }

    // useEffect(() => {
    //
    //     if(destinationData.postalCode.length === 5){
    //         getLocationData(destinationData.postalCode)
    //     }
    //
    // }, [destinationData.postalCode]);

    useEffect(() => {
        getUserProducts()
        form.setFieldsValue({
            country: 'MX'
        })
        //eslint-disable-next-line
    }, []);


    return (
        <>
        <Button type="primary" icon={<ArrowLeftOutlined /> } style={{ margin:'10px 0' }} onClick={() => History.push('/outboundshipments')}>Ver ordenes de envío</Button>
        <Row gutter={[10, 10]}>

            <Col span={12}>
                <Card>
                    <h3>Productos a enviar</h3>

                    <Form onFinish={onSubmitProducts} form={form}>
                    <Row>
                            <Col span={12}>
                                <Form.Item name="Producto">
                                    <Select
                                        placeholder="Selecciona el producto"
                                        showSearch
                                        optionFilterProp="label"
                                        options={products ?? []}
                                        style={{width: '100%'}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="Cantidad">
                                    <Input type="number" placeholder="Cantidad"/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Button style={{width: '100%'}} htmlType="submit">Agregar</Button>
                            </Col>
                            <Col span={24}>
                                <Table
                                    dataSource={selectedProducts ?? []}
                                    columns={[
                                        {
                                            title: "Producto",
                                            dataIndex: 'Producto',
                                            key: "Producto"
                                        },
                                        {
                                            title: "SKU",
                                            dataIndex: 'SKU',
                                            key: "SKU"
                                        },
                                        {
                                            title: "Cantidad",
                                            dataIndex: 'Cantidad',
                                            key: "Cantidad"
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>

            <Col span={12}>
                <Card>
                    <Form onFinish={createOutboundShipment}>
                        <h3 style={{textAlign: 'center'}}>Dirección del destino</h3>
                        <p>Contacto</p>
                        <Row gutter={[10, 10]}>
                            <Col span={12}>
                                <Form.Item rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 4,
                                    message: "Minimo 4 caracteres"
                                }, {max: 30, message: "Maximo 30 caracteres"}]} name='name'>
                                    <Input minLength={4} maxLength={30} showCount placeholder='Nombre'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 4,
                                    message: "Minimo 4 caracteres"
                                }, {max: 32, message: "Maximo 32 caracteres"}]} name='email'>
                                    <Input minLength={4} maxLength={32} showCount placeholder='Correo'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='phone' rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 10,
                                    message: "Minimo 10 caracteres"
                                }, {max: 10, message: "Maximo 10 caracteres"}]}>
                                    <Input minLength={10} maxLength={10} showCount placeholder='Teléfono'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='company' rules={[{min: 3, message: "Minimo 4 caracteres"}, {
                                    max: 30,
                                    message: "Maximo 30 caracteres"
                                }]}>
                                    <Input minLength={4} maxLength={30} showCount placeholder='Empresa ( opcional )'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <p>Dirección</p>
                        <Row gutter={[10, 10]}>
                            {/*<Col span={24}>*/}
                            {/*    <Form.Item name='postal_code' rules={[{required: true, message: "Este campo requerido"}]}>*/}
                            {/*        /!*<Select showCount showSearch onChange={(e) => setDestinationData(...destinationData, )} options={optionLocationData ?? []} onSearch={(e) => setDestinationData({ ...destinationData, postalCode: e })} placeholder='Código Postal' minLength={5} maxLength={5}/>*!/*/}
                            {/*        <Input showCount placeholder='Código Postal' minLength={5} maxLength={5}/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col span={12}>
                                <Form.Item name='street' rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 4,
                                    message: "Minimo 4 caracteres"
                                }, {max: 32, message: "Maximo 32 caracteres"}]}>
                                    <Input minLength={4} maxLength={32} showCount placeholder='Calle'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='number' rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 1,
                                    message: "Minimo 1 caracter"
                                }, {max: 14, message: "Maximo 14 caracteres"}]}>
                                    <Input minLength={1} maxLength={14} showCount placeholder='Número'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='int_number' rules={[{min: 2, message: "Minimo 2 caracteres"}, {
                                    max: 14,
                                    message: "Maximo 14 caracteres"
                                }]}>
                                    <Input minLength={2} maxLength={14} showCount
                                           placeholder='Número interior ( opcional )'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='district' rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 4,
                                    message: "Minimo 4 caracteres"
                                }, {max: 32, message: "Maximo 32 caracteres"}]}>
                                    <Input minLength={2} maxLength={32} showCount placeholder='Colonia'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='city' rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 4,
                                    message: "Minimo 4 caracteres"
                                }, {max: 50, message: "Maximo 50 caracteres"}]}>
                                    <Input minLength={4} maxLength={50} showCount placeholder='Delegación / Ciudad'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item defaultValue={"MX"} initialValue={"MX"} name='country' rules={[{required: true, message: "Este campo requerido"}]}>
                                    <Input disabled showCount placeholder='País'/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='postal_code' rules={[{required: true, message: "Este campo requerido"}]}>
                                    {/*<Select showCount showSearch onChange={(e) => setDestinationData(...destinationData, )} options={optionLocationData ?? []} onSearch={(e) => setDestinationData({ ...destinationData, postalCode: e })} placeholder='Código Postal' minLength={5} maxLength={5}/>*/}
                                    <Input showCount placeholder='Código Postal' minLength={5} maxLength={5}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='state' rules={[{required: true, message: "Este campo requerido"}]}>
                                    <Select options={estadosMexico} placeholder='Selecciona un estado'
                                            style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='reference' rules={[{required: true, message: "Este campo requerido"}, {
                                    min: 4,
                                    message: "Minimo 4 caracteres"
                                }, {max: 30, message: "Maximo 30 caracteres"}]}>
                                    <Input minLength={4} maxLength={30} showCount placeholder='Referencia'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button disabled={isLoading} type='primary' style={{width: '100%', margin: '10px 0'}} htmlType='submit' loading={isLoading}>CREAR ORDEN DE ENVÍO</Button>
                    </Form>
                </Card>
            </Col>

        </Row>
        </>
    );
};

export default CreateOutboundShipments;
