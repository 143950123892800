import { Col, Row } from "antd"
import CardContainer from "../quoter/containers/CardContainer"
import { BarChartOutlined } from "@ant-design/icons"

const TopCards = ({ data, isLoading }) => {
	const showSubtitle = (isLoading, text) =>
		isLoading ? 'Cargando..' : `${text}`

	return (
		<Row gutter={[20, 10]}>
			{
				data.map(item => (
					<Col lg={4} sm={12} xs={24}>
						<CardContainer
							cardStyle={{minHeight: '165px'}}
							title={item.title}
							subtitle={showSubtitle(isLoading, item.data)}
							itemsSpacing={[0, 10]}
							iconOrImg={<BarChartOutlined />}
							contrastColor="#6d71f6"
						/>
					</Col>
				))
			}
		</Row>
	)
}

export default TopCards