import React, { Fragment, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth0 } from "../../hooks/react-auth0-spa"
import {
  Grid,
  Box,
  Card,
  CardContent,
  TextField,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core"
import { GetAllProductsByAuthId } from "../../api/ProductAPI"
import {  AutoComplete  } from 'antd';

const initialState = {
  Codigo: "",
  Guia: {
    Proveedor: "",
    Numero: "",
  },
  InfoEnvioProductos: [],
}
const initialStateProduct = {
  SKUFulfillment: "",
  Cantidad: "",
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
  },
  textFieldSmall: {
    marginLeft: theme.spacing(2),
  },
}))

const SelectedProducts = ({ userId, handleShipment, handleStep, saveData }) => {
  const classes = useStyles()
  const [searchProduct, setSearchProduct] = useState('')
  const [shipment, setShipment] = useState(initialState)
  const [product, setProduct] = useState(initialStateProduct)
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { getTokenSilently } = useAuth0()

  const getUserProducts = async() => {
    setIsLoading(true)
    const token = await getTokenSilently()
    try {
      if(token){
        const response = await GetAllProductsByAuthId(token, userId, { active: true })
        if(response) setProducts(response.res)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('Error at getUserProducts', error)
    }
  }

  const onChangeInputProduct = (e) => {
    const { name, value } = e.target
    let currentProduct

    let updatedProduct = {
      ...product,
      [name]: value,
    }

    if (name === "SKUFulfillment") {
      currentProduct = products.find((product) => product.SKUFulfillment === value)
      if (currentProduct) {
        updatedProduct = {
          ...updatedProduct,
          Nombre: currentProduct.Nombre,
        }
      }
    }

    setProduct(updatedProduct)
  }

  const onSubmitProduct = async (e) => {
    e.preventDefault()

    const productToSave = {
      Producto: product.SKUFulfillment,
      CantidadEnviada: product.Cantidad,
      Nombre: product.Nombre,
    }

    setShipment({
      ...shipment,
      InfoEnvioProductos: [...shipment.InfoEnvioProductos, productToSave],
    })

    setProduct(initialStateProduct)
    setSearchProduct('')
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    handleShipment(shipment)
    handleStep()
  }

  useEffect(() => {
    if(saveData.InfoEnvioProductos){
      const saveProducts = saveData.InfoEnvioProductos.map((product) => ({
        Producto: product.Producto,
        CantidadEnviada: product.CantidadEnviada,
        Nombre: saveData?.productsData?.find(item => item._id === product.Producto)?.Nombre ?? "",
      }))

      setShipment({
        ...shipment,
        InfoEnvioProductos: saveProducts,
      })
    }
    getUserProducts()
    //eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Grid container>
      </Grid>
      <Box>
        <Card>
          <CardContent>
            <form autoComplete="off" onSubmit={onSubmitProduct}>
              <Grid item xs={12}>
                <InputLabel>Productos</InputLabel>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={5}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                  >
                    <AutoComplete
                      name="SKUFulfillment"
                      value={searchProduct}
                      allowClear={true}
                      placeholder="Producto *"
                      style={{ height: '56px'}}
                      options={isLoading
                        ? []
                        : products.length == 0
                            ? [{ label: "No hay productos", value: "No hay productos" }]
                            : products.map((item) => ({
                              label: `${item.SKUCliente} - ${item.Nombre}`,
                              value: item.SKUFulfillment
                            }))
                      }
                      filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onSearch={(value)=> setSearchProduct(value)}
                      onClear={()=> {
                        setSearchProduct('')
                        setProduct({ SKUFulfillment: '', Cantidad: product.Cantidad })
                      }}
                      onSelect={(value)=> {
                        if (value !== 'No hay productos'){
                          const chosenProduct = products.find((product) => product?.SKUFulfillment === value)
                          setSearchProduct(`${chosenProduct?.SKUCliente} - ${chosenProduct?.Nombre}`)
                          onChangeInputProduct({ target: { name: 'SKUFulfillment', value }})
                        } else {
                          setSearchProduct(value)
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    type="number"
                    name="Cantidad"
                    label="Cantidad"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    required={true}
                    value={product.Cantidad}
                    onChange={onChangeInputProduct}
                    inputProps={{ min: 0 }}
                    disabled={isLoading
                      ? isLoading
                      : products.length == 0
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Button
                    disabled={
                      (product?.SKUFulfillment === '') ||
                      (product?.Cantidad === '') ||
                      (!product?.Nombre) ||
                      (!products.find((currentProduct) =>
                        `${currentProduct.SKUCliente} - ${currentProduct.Nombre}` === searchProduct &&
                        currentProduct?.SKUFulfillment === product?.SKUFulfillment
                      ))
                    }
                    type="submit"
                    className={classes.textField}
                    variant="outlined"
                    color="primary"
                  >
                    Agregar producto
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={3} width={1}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Producto</TableCell>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Cantidad</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shipment.InfoEnvioProductos.map((item, index) => (
                          <TableRow key={index} id={index}>
                            <TableCell>{item.Producto}</TableCell>
                            <TableCell>{item.Nombre}</TableCell>
                            <TableCell>{item.CantidadEnviada}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </form>
            <form autoComplete="off" onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12} container justify="flex-end">
                  <Button
                    disabled={shipment.InfoEnvioProductos.length === 0}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.textField}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  )
}

export default SelectedProducts
