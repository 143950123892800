import React from "react";
import Instrucciones from "../19168737.jpg";
import {Card} from "antd";

const Index = () => {
  return (
    <Card>
        <img
          alt="Instructions"
          src={Instrucciones}
          style={{ maxWidth: "100%", maxHeight:'100%', borderRadius:'10px' }}
        ></img>
    </Card>
  );
};

export default Index;
