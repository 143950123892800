import axios from 'axios'
import anime from 'animejs'
import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Form, Button, Alert, Tag, Popconfirm } from 'antd'
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SaveOutlined
} from '@ant-design/icons'

import { useAuth0 } from "../../../hooks/react-auth0-spa";
import { QuoteDataContext } from '../../../context/QuoteDataContext'
import { CountriesContext } from '../../../context/CountriesContext'

import LottieContainer from '../containers/LottieContainer'
import CardContainer from '../containers/CardContainer'
import InputContainer from '../containers/InputContainer'
import ModalContainer, { modalHeight, modalSizes } from '../containers/ModalContainer'

import LockLottie from '../lotties/lock.json'
import { generateUUID } from '../utils/quote'
// import { useGet } from '../../../hooks/useGet'
// import { getUserPackages } from '../../../services/packages_s'

// import { TPackagesResponse } from '../../../interfaces/packages.interfaces'

export const FillMultipackageForm = ({
  modalIsOpen,
  setModalIsOpen,
  multipaq,
  setMultipaq,
  token
}) => {
  const t = (text) => text
  const { getTokenSilently } = useAuth0();
  const { shipmentTypeSelected, setShipmentTypeSelected, quoteData, setQuoteData } =
    useContext(QuoteDataContext)
  const [form] = Form.useForm()

  const SwitchShipmentType = () => {
    if (
      form.getFieldValue('OriginPCSelectedCountry') &&
      form.getFieldValue('DestinyPCSelectedCountry')
    ) {
      if (
        form.getFieldValue('OriginPCSelectedCountry') ===
        form.getFieldValue('DestinyPCSelectedCountry')
      )
        TransitionShipmentType('National')
      if (
        form.getFieldValue('OriginPCSelectedCountry') !==
        form.getFieldValue('DestinyPCSelectedCountry')
      )
        TransitionShipmentType('International')
    }
  }

  const TransitionShipmentType = (type) => {
    if (type === shipmentTypeSelected) {
    } else {
      setTimeout(() => {
        setShipmentTypeSelected(type)
      }, 666)
      anime({
        targets: '#leftPostalQuoteContainer',
        opacity: [1, 0, 1],
        easing: 'linear',
        duration: 1000
      })
    }
  }

  const savePackages = () => {
    setQuoteData({
      ...quoteData,
      PackageArray: multipaq.filter((el) => el.Active === true && el.Saved === true)
    })
    setModalIsOpen(!modalIsOpen)
  }

  useEffect(() => {
    if (modalIsOpen) {
      setMultipaq([
        {
          id: generateUUID(),
          Active: true,
          Saved: false,
          Height: undefined,
          Length: undefined,
          Width: undefined,
          Weight: undefined
        }
      ])
    } else {
      setMultipaq([])
    }
    //eslint-disable-next-line
  }, [modalIsOpen])

  return (
    <ModalContainer
      title={'Envíe multiples paquetes a la misma dirección'}
      size={modalSizes.LARGE}
      height={modalHeight.BIG}
      visible={modalIsOpen}
      okButtonProps={{ type: 'primary' }}
      onCancel={() => setModalIsOpen(!modalIsOpen)}
      onOk={() => setModalIsOpen(!modalIsOpen)}
      footer={[
        <Row justify="end">
          <Button onClick={() => setModalIsOpen(!modalIsOpen)}>{'Cancelar'}</Button>
          <Popconfirm
            placement="topLeft"
            cancelButtonProps={{ type: 'primary', ghost: true }}
            okButtonProps={{ danger: true }}
            title={'Los paquetes sin confirmar no serán tomados en cuenta, ¿desea continuar?'}
            onConfirm={savePackages}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            okText={'Si'}
            cancelText={'No'}
            disabled={
              multipaq.filter((el) => el.Saved === true).length < 2 ? true : false
            }
          >
            <Button
              icon={<SaveOutlined />}
              style={{ display: 'flex', alignItems: 'center' }}
              type="primary"
              disabled={
                multipaq.filter((el) => el.Saved === true).length < 2 ? true : false
              }
            >
              {'Continuar'}
            </Button>
          </Popconfirm>
        </Row>
      ]}
    >
      <Alert
        showIcon
        message={'Opción solo disponible para envios con FedEx y limitado a 20 paquetes. Confirme sus paquetes después de introducir la información.'}
        type="info"
        style={{ marginBottom: '1rem' }}
      />
      <Col span={24}>
        {multipaq.map((pack, index) => (
          <>
            {pack.Active ? (
              <MultipackageForm
                pack={pack}
                index={index}
                multipaq={multipaq}
                setMultipaq={setMultipaq}
                TransitionShipmentType={TransitionShipmentType}
                SwitchShipmentType={SwitchShipmentType}
                token={token}
              />
            ) : null}
          </>
        ))}
      </Col>
    </ModalContainer>
  )
}

const MultipackageForm = ({
  pack,
  multipaq,
  setMultipaq,
  TransitionShipmentType,
  SwitchShipmentType,
  token,
}) => {
  const t = (text) => text
  const [selectedContainer, setSelectedContainer] = useState('box') //eslint-disable-line
  const [insuranceActive, setInsuranceActive] = useState(false)
  const [savedPackageSelected, setSavedPackageSelected] = useState(false)
  const { currentCountrySelectedData, currentSelectedCountry } = useContext(CountriesContext)
  const [packagesSaved, setPackagesSaved] = useState([])
  const [savepack, setSavePack] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    const getUserPackages = async ()=> {
      setLoading(true)
      const { data } = await axios.get(
          `${process.env.REACT_APP_NOT_DRENVIO_BACKEND_URL}/users/packages/all`,
          { headers: { Authorization: `Bearer ${token}` }}
      )
      setPackagesSaved(data)
      setLoading(false)
    }

    getUserPackages()
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      OriginPCSelectedCountry: currentSelectedCountry
      // DestinyPCSelectedCountry: currentSelectedCountry,
    })
  }, [currentSelectedCountry]) //eslint-disable-line

  const onFinish = (data) => {
    const newPackages = multipaq.map((el) => {
      if (el.id === pack.id) {
        return {
          ...data,
          id: el.id,
          Container: selectedContainer,
          Active: true,
          Saved: true
        }
      }
      return el
    })
    setMultipaq(newPackages)
    setSavePack(true)
  }

  const onFinishFailed = () => {}

  return (
    <>
      <Form
        form={form}
        name="QuoteForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          Container: 0
        }}
      >
        <CardContainer
          title={'Paquete'}
          itemsSpacing={[0, 10]}
          cardStyle={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '16px'
          }}
        >
          <Row>
            {/* <Col sm={24} md={24} lg={12}>
              <InputContainer
                type="multipleRadio"
                valueContainerName="Container"
                required
                optionsList={['Caja', 'Sobre']}
                onChange={(e) => {
                  if (e.target.value === 1) {
                    SwitchShipmentType()
                    setSelectedContainer('envelope')
                    form.setFieldsValue({
                      Height: 10,
                      Length: 10,
                      Width: 10,
                      Weight: 1
                    })
                  } else {
                    setSelectedContainer('box')
                    setSavedPackageSelected(false)
                    form.setFieldsValue({
                      Height: undefined,
                      Length: undefined,
                      Width: undefined,
                      Weight: undefined
                    })
                  }
                }}
              />
            </Col> */}
            <Col sm={24} md={24} lg={6}>
              <Row gutter={[10, 0]}>
                <Col span={8}>
                  <InputContainer
                    onClick={() => {
                      setInsuranceActive(!insuranceActive)
                    }}
                    title="Asegurar Envío"
                    type="iconTitleHoverButton"
                    shape={'circle'}
                    iconOrReactElement={
                      <LottieContainer
                        lottieStyle={{
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%'
                        }}
                        lottieOptions={{
                          loop: false,
                          autoplay: false
                        }}
                        lottieSelected={LockLottie}
                        lottieSpeed={insuranceActive ? 2 : 1}
                        lottieState={['playSegment', insuranceActive ? [25, 150] : [0, 25]]}
                        lottieTrigger={insuranceActive}
                      />
                    }
                  />
                </Col>
                <Col span={16}>
                  <InputContainer
                    type="number"
                    valueContainerName="Insurance"
                    placeholder="Cantidad"
                    suffix={`$ ${currentCountrySelectedData?.Currency}`}
                    style={{
                      transition: 'width 500ms, opacity 500ms',
                      overflow: 'hidden',
                      opacity: insuranceActive ? 1 : 0,
                      width: insuranceActive ? '145px' : '0px'
                    }}
                    required={insuranceActive ? true : false}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={24} md={24} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {multipaq.filter((el) => el.Active === true).length > 1 && (
                <Button
                  style={{ display: 'flex', alignItems: 'center' }}
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => {
                    setMultipaq(
                      multipaq.map((el) => {
                        if (el.id === pack.id) {
                          return {
                            ...el,
                            Active: false,
                            Saved: false
                          }
                        }
                        return el
                      })
                    )
                  }}
                >
                  {'Eliminar'}
                </Button>
              )}
            </Col>
          </Row>

          <Row
            style={{
              transition: 'height 500ms',
              overflow: 'hidden',
              height: selectedContainer === 'box' ? '50px' : '0px'
            }}
          >
            <Col span={24}>
              <div className="savedPackages">
                <InputContainer
                    isButtonLoading={loading}
                    type="select"
                    placeholder={'Mis paquetes guardados'}
                    valueContainerName="selectedPackage"
                    onClear={() => {
                      setSavedPackageSelected(false)
                      form.resetFields(['Height', 'Length', 'Width', 'Weight'])
                    }}
                    onChange={(e) => {
                      const packageName = e.split('[')[0].trim()
                      const packageContent = e.split('[')[1].replace(/\]/g, '').trim()
                      packagesSaved?.forEach((packageSaved) => {
                        if (
                            packageSaved.name === packageName &&
                            packageSaved.content === packageContent
                        ) {
                          setSavedPackageSelected(true)
                          form.resetFields(['Height'])
                          form.setFieldsValue({
                            Height: packageSaved.height,
                            Length: packageSaved.length,
                            Width: packageSaved.width,
                            Weight: packageSaved.weight
                          })
                        }
                      })
                    }}
                    optionsList={
                      packagesSaved
                          ? packagesSaved?.map((packageSaved) => {
                            return `${packageSaved.name} [ ${packageSaved.content} ]`
                          })
                          : []
                    }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <InputContainer
                type="number"
                suffix="cm"
                required
                disabled={
                  savepack
                    ? true
                    : selectedContainer === 'envelope' || savedPackageSelected
                    ? true
                    : false
                }
                valueContainerName="Height"
                placeholder={`${'Alto'} `}
              />
            </Col>
            <Col span={12}>
              <InputContainer
                type="number"
                suffix="cm"
                required
                disabled={
                  savepack
                    ? true
                    : selectedContainer === 'envelope' || savedPackageSelected
                    ? true
                    : false
                }
                valueContainerName="Length"
              placeholder={`${'Largo'} `}
              />
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <InputContainer
                type="number"
                suffix="cm"
                required
                disabled={
                  savepack
                    ? true
                    : selectedContainer === 'envelope' || savedPackageSelected
                    ? true
                    : false
                }
                valueContainerName="Width"
                placeholder={`${'Ancho'} `}
              />
            </Col>
            <Col span={12}>
              <InputContainer
                type="number"
                suffix="kg"
                required
                disabled={
                  savepack
                    ? true
                    : selectedContainer === 'envelope' || savedPackageSelected
                    ? true
                    : false
                }
                valueContainerName="Weight"
                placeholder={`${'Peso'} `}
                onChange={(e) => {
                  if (e >= 69) TransitionShipmentType('Pallet')
                  SwitchShipmentType()
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gridGap: '1rem',
                marginTop: '10px'
              }}
            >
              {savepack ? (
                <>
                  <Tag color="cyan" style={{ display: 'flex', alignItems: 'center' }}>
                    {'Paquete Confirmado'}
                  </Tag>
                  <Button
                    style={{ display: 'flex', alignItems: 'center' }}
                    icon={<EditOutlined />}
                    type="primary"
                    ghost
                    onClick={() => {
                      const newMultipack = multipaq.map((el) => {
                        if (el.id === pack.id) {
                          return {
                            ...el,
                            Saved: false
                          }
                        }
                        return el
                      })
                      setMultipaq(newMultipack)
                      setSavePack(false)
                    }}
                  >
                    {'Editar paquete'}
                  </Button>
                </>
              ) : (
                <InputContainer
                  type="submitButton"
                  title={'Confirmar Paquete'}
                  style={{}}
                />
              )}
            </Col>
          </Row>
        </CardContainer>
        {multipaq.filter((el) => el.Active === true).length < 20 &&
          multipaq.filter((el) => el.Active === true)[
            multipaq.filter((el) => el.Active === true).length - 1
          ].id === pack.id && (
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 0,
                marginTop: 0
              }}
            >
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2rem',
                  justifyContent: 'center',
                  zIndex: 20,
                  marginBottom: '12px',
                  width: '100%'
                }}
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setMultipaq([
                    ...multipaq,
                    {
                      id: generateUUID(),
                      Active: true,
                      Saved: false,
                      Height: undefined,
                      Length: undefined,
                      Width: undefined,
                      Weight: undefined
                    }
                  ])
                }}
              >
                {'Agregar nuevo paquete'}
              </Button>
            </Col>
          )}
      </Form>
    </>
  )
}
