import anime from 'animejs'
import React, { useState, createContext } from "react"

export const initialQuoteState = {
    PackageData: {},
    PackageArray: [],
    AddressData: {
        OriginData: {},
        DestinyData: {}
    },
    FetchedParcelsArray: []
}

export const QuoteDataContext = createContext({
    quoteData: initialQuoteState,
})

export const QuoteDataContextProvider = (props) => {
    const [quoteData, setQuoteData] = useState(initialQuoteState)
    const [shipmentTypeSelected, setShipmentTypeSelected] = useState('awaiting...')
    const [commercialInvoice, setCommercialInvoice] = useState(null)

    const [quoteStep, setQuoteStep] = useState('1) Quote based on Postal Codes')
    const [label, setLabel] = useState(null)
    const [waitingGuide, setQWaitingGuide] = useState(true)

    const [previousQuoteStep, setPreviousQuoteStep] = useState(
        '1) Quote based on Postal Codes'
    )

    const setQuoteStepTransition = (stepSelected) => {
        setPreviousQuoteStep(quoteStep)
        setTimeout(() => {
            setQuoteStep(stepSelected)
        }, 650)
        const tl = anime.timeline({
            targets: '.dashboardSection',
            duration: 500,
            easing: 'linear'
        })

        tl.add({
            opacity: 0
        })
        tl.add(
        {
            opacity: 1
        },
        '+=400'
        )
    }

    const resetQuoteStepTransition = (stepSelected) => {
        setPreviousQuoteStep(quoteStep)
        setQuoteStep(stepSelected)
    }


    return (
        <QuoteDataContext.Provider
            value={{
                // Quote Data (Origin and Destiny quote info) value and setState function
                quoteData,
                previousQuoteStep,
                setQuoteData,
                // shipment Type Selected (national, international and Pallet) value and setState function
                shipmentTypeSelected,
                setShipmentTypeSelected,
                // quote Step Selected (1,2,3,4,5) value and setState function
                quoteStep,
                setQuoteStepTransition,
                resetQuoteStepTransition,
                label,
                setLabel,
                setQWaitingGuide,
                waitingGuide,
                commercialInvoice,
                setCommercialInvoice
            }}
        >
            {props.children}
        </QuoteDataContext.Provider>
    )
}
