import { Typography } from 'antd';
import {NavbarRoutes} from "./NavbarRoutes";
import SetRoutes from "../../utils/SetRoutes";

const Header = () => {

    const [ title, description ] = SetRoutes(NavbarRoutes);
    const { Title } = Typography;

    return (
        <div>
            <Title level={1} style={{ margin: "-120px 0 -15px 0", color: "#fff" }}>
                { title }
            </Title>
            <Title level={4} style={{ color: "#fff" }}>
                { description }
            </Title>
        </div>
    )
}

export default Header